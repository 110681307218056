import { Box, Button, Container, Grid, Typography } from '@vaporfi/uikit';
import React, { memo } from 'react';
import { PoolsRowProps, RowStatsItemProps } from './types';

const RowStatsItem = memo(({ label, value }: RowStatsItemProps) => {
  return (
    <Container stack="column" itemsCenter className="text-left">
      <Typography className="self-start" size="xs" color="neutral">
        {label}
      </Typography>
      <Typography
        data-testid="list-view-pair-tvl"
        className="self-start"
        size={'sm'}
        color="light"
      >
        {value}
      </Typography>
    </Container>
  );
});
export const PoolsRow = memo(
  ({
    apr,
    feeTier,
    isV1Pool,
    logo,
    onClick,
    statsItems,
    subtitle,
    title,
  }: PoolsRowProps) => {
    return (
      <Grid
        data-testid="list-view-container"
        isContainer
        className="max-h-17 w-full auto-cols-[170px] grid-flow-col border-b-[1px] border-solid border-b-neutral-800 bg-[black] xl:grid-cols-8"
      >
        <Grid
          className="sticky left-0 z-[2] col-span-2 w-[185px] bg-[black] px-4 pt-4"
          data-testid="list-view-pair"
        >
          <Container stack="row" itemsCenter fullWidth>
            <Box>{logo()}</Box>
            <Box>
              <Typography
                data-testid="list-view-pair-symbols"
                size="sm"
                weight="semibold"
                color="light"
              >
                {title}
              </Typography>
              <Typography size="xs" color="light" className="hidden lg:block">
                {subtitle || 'Pair'}
              </Typography>
            </Box>
          </Container>
        </Grid>
        <Grid className="h-[68px] bg-black pt-4">
          <Container>
            <Box className="flex items-center gap-1">
              <Typography
                data-testid="list-view-pair-apr"
                size={'sm'}
                weight="semibold"
                color="turquoise"
              >
                {apr}%
              </Typography>
              <Typography color="light" size="xs">
                APR
              </Typography>
            </Box>
            <Box className="flex items-center gap-1">
              <Typography size={'sm'} weight="semibold" color="turquoise">
                {feeTier}%
              </Typography>
              <Typography color="light" size="xs">
                FEE
              </Typography>
            </Box>
          </Container>
        </Grid>

        <Grid className="h-[68px] bg-black pt-4">
          <Container
            className={`h-fit w-fit text-center ${
              isV1Pool ? 'bg-blue-500' : 'bg-green'
            }`}
            style={{
              padding: '4px 8px',
            }}
          >
            <Typography size="sm" color="light">
              {isV1Pool ? 'Standard' : 'Concentrated'}
            </Typography>
          </Container>
        </Grid>
        {statsItems.map((props, index) => (
          <Grid
            className="h-[68px] bg-black pt-4"
            key={`pool-list-layout-${index}`}
          >
            <RowStatsItem {...props} />
          </Grid>
        ))}

        <Grid className="h-[68px] bg-black pt-4">
          <Button
            data-testid="list-view-pair-go-btn"
            size="sm"
            color="light"
            variant="outline"
            onClick={onClick}
          >
            <Typography>Go</Typography>
          </Button>
        </Grid>
      </Grid>
    );
  },
);

// eslint-disable-next-line unicorn/prefer-export-from
export { type PoolsRowProps, type RowStatsItemProps };
