export interface SwapComponentProps {
  activeField: SwapField;
  otherField: SwapField;
  amounts: Record<SwapField, string>;
  tokens: Record<SwapField, any>;
  onUserInput: (amount: string, field: SwapField) => void;
  clearAll: () => void;
  onBuy?: any;
  error?: string;
  signer: any;
  balances: string;
}

export type SwapTab = 'buy' | 'sell';

export enum SwapField {
  TOKEN = 'token',
  NATIVE = 'native',
}
