'use client';
import { Box, Button, Container, Typography } from '@vaporfi/uikit';
import React, { memo } from 'react';
import { KPIDetails, PointsTypography } from './shared';
import { useCommify } from '@vaporfi/hooks';

const ClaimPoints = ({
  claimablePoints,
  onClaimPointsClick,
}: {
  onClaimPointsClick: () => void;
  claimablePoints: number;
}) => {
  const commify = useCommify();
  return (
    <Container className="border-gradient-stratosphere flex items-center justify-between rounded-lg bg-black p-4 lg:p-[30px]">
      <Box className="flex flex-col gap-2">
        <Box className="flex items-center">
          <Typography weight="semibold" className="mr-2">
            Claimable Points
          </Typography>
          <KPIDetails content="Points available to claim." />
        </Box>
        <PointsTypography>
          {commify(claimablePoints?.toFixed(3))}
        </PointsTypography>
      </Box>
      <Button
        size="base"
        color="gradient"
        variant="outline"
        onClick={onClaimPointsClick}
      >
        <Typography>Claim Points</Typography>
      </Button>
    </Container>
  );
};

export default memo(ClaimPoints);
