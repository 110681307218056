import { Box } from '../../components/Box';
import Flex from '../../components/Box/Flex';
import { IconButton } from '../../components/Button';
import { ArrowBackIcon, CloseIcon, XmarkIcon } from '../../components/Svg';
import { ModalProps } from './types';

import styled from 'styled-components';

export const ModalHeader = styled.div<{ background?: string }>`
  display: flex;
  align-items: center;
  background: ${({ background }) => background || 'transparent'};
  justify-content: space-between;
  margin: 30px;
`;

export const ModalTitle = styled(Flex)`
  align-items: center;
  flex: 1;
`;

export const WalletHeading = styled(Flex)`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;

  display: flex;
  align-items: center;

  color: #d6dada;

  flex: none;
  order: 0;
  flex-grow: 0;
`;

export const ModalBody = styled(Flex)`
  flex-direction: column;
  max-height: 90vh;
  max-height: calc(var(--vh, 1vh) * 90);
`;

export const ModalCloseButton: React.FC<
  React.PropsWithChildren<{
    onDismiss: ModalProps['onDismiss'];
  }>
> = ({ onDismiss }) => {
  return (
    <IconButton
      variant="text"
      onClick={onDismiss}
      aria-label="Close the dialog"
      marginRight={-3}
      marginBottom={-2}
      data-testid="modal-close-btn"
    >
      <CloseIcon color="#ffffff" />
    </IconButton>
  );
};
export const ModalXmarkButton: React.FC<
  React.PropsWithChildren<{
    onDismiss: ModalProps['onDismiss'];
  }>
> = ({ onDismiss }) => {
  return (
    <IconButton
      variant="text"
      onClick={onDismiss}
      aria-label="Close the dialog"
      data-testid="modal-x-btn"
    >
      <XmarkIcon color="#ffffff" padding="6px 0px 0px 0px" marginLeft="4px" />
    </IconButton>
  );
};

export const ModalBackButton: React.FC<
  React.PropsWithChildren<{ onBack: ModalProps['onBack'] }>
> = ({ onBack }) => {
  return (
    <IconButton
      data-testid="modal-back-btn"
      variant="text"
      onClick={onBack}
      area-label="go back"
      mr="8px"
    >
      <ArrowBackIcon color="primary" />
    </IconButton>
  );
};

export const ModalContainer = styled(Box)`
  overflow: auto;
  background: #000000;
  box-shadow:
    0px 20px 36px -8px rgba(14, 14, 44, 0.1),
    0px 1px 1px rgba(0, 0, 0, 0.05);
  border: 1px solid ${({ theme }) => theme.colors.cardBorder};
  border-radius: 10px;
  width: 100%;
  max-height: 100vh;
  max-height: calc(var(--vh, 1vh) * 100);
  z-index: ${({ theme }) => theme.zIndices.modal};

  ${({ theme }) => theme.mediaQueries.xs} {
    width: auto;
    max-width: 100%;
  }
`;
