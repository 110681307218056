import { Svg, SvgProps } from './Svg';

export const Arrow = ({
  height = '14',
  version = '1.1',
  viewBox = '0 0 15 14',
  width = '15',
  xmlns = 'http://www.w3.org/2000/svg',
  ...rest
}: SvgProps) => {
  return (
    <Svg {...rest} {...{ height, version, viewBox, width, xmlns }}>
      <path
        d="M4.00181 14.0001C3.74593 14.0001 3.48993 13.9025 3.29492 13.7072C2.9043 13.3165 2.9043 12.6837 3.29492 12.2931L8.58936 7.00004L3.29492 1.70717C2.9043 1.31654 2.9043 0.683724 3.29492 0.293094C3.68555 -0.0975353 4.31837 -0.0975353 4.709 0.293094L10.7091 6.29316C11.0997 6.68379 11.0997 7.31661 10.7091 7.70724L4.709 13.7073C4.51431 13.9032 4.25806 14.0001 4.00181 14.0001Z"
        fill="var(--colors-white)"
      />
    </Svg>
  );
};
