import { BigintIsh } from '@vapordex/sdk';
import JSBI from 'jsbi';

/**
 * @param {BigintIsh} bigintIsh - The bigint value to be converted to hex format.
 * @returns {string} The hex encoded string.
 */
export function toHex(bigintIsh: BigintIsh) {
  const bigInt = JSBI.BigInt(bigintIsh);
  let hex = bigInt.toString(16);
  if (hex.length % 2 !== 0) {
    hex = `0${hex}`;
  }
  return `0x${hex}`;
}

/**
 * The `MethodParameters` interface is used to define the shape of the returned object, with `calldata` and `value` being the two properties.
 */
export interface MethodParameters {
  /**
   * The hex encoded calldata to perform the given operation
   */
  calldata: string;
  /**
   * The amount of ether (wei) to send in hex.
   */
  value: string;
}
