import { Currency, CurrencyAmount } from '@vapordex/sdk';

import { JSBI } from '@vapordex/sdk';
import { parseUnits } from 'viem';

// try to parse a user entered amount for a given token
export const tryParseAmount = (
  value?: string,
  currency?: Currency,
): CurrencyAmount<Currency> | undefined => {
  if (!value || !currency) {
    return undefined;
  }
  try {
    const typedValueParsed = parseUnits(value, currency.decimals).toString();

    if (typedValueParsed !== '0') {
      return CurrencyAmount.fromRawAmount(
        currency,
        JSBI.BigInt(typedValueParsed),
      );
    }
  } catch (error) {
    // should fail if the user specifies too many decimal places of precision (or maybe exceed max uint?)
    console.debug(`Failed to parse input amount: "${value}"`, error);
  }
  // necessary for all paths to return a value
  return undefined;
};
