import { Address } from 'viem';

export type Tab = 'trades' | 'threads';
export const DEFAULT_TAB = 'trades';
export const tabKey = 'tab';
export interface Transaction {
  account: Address;
  hash: string;
  nativeAmount: string;
  tokenAmount: string;
  type: 'buy' | 'sell';
  date: string;
}
