import { AppState, useAppDispatch } from '../../index';
import { FIXED_GAS_PRICE, GAS_PRICE_GWEI } from '../../types';
import {
  addSerializedPair,
  addSerializedToken,
  FarmStakedOnly,
  muteAudio,
  removeSerializedToken,
  SerializedPair,
  unmuteAudio,
  updateUserDeadline,
  updateUserExpertMode,
  updateUserFarmStakedOnly,
  updateUserSingleHopOnly,
  updateUserSlippageTolerance,
  updateGasPrice,
  addWatchlistToken,
  addWatchlistPool,
  updateUserPoolStakedOnly,
  updateUserPoolsViewMode,
  ViewMode,
  updateUserFarmsViewMode,
  updateUserPredictionChartDisclaimerShow,
  updateUserPredictionChainlinkChartDisclaimerShow,
  updateUserPredictionAcceptedRisk,
  updateUserUsernameVisibility,
  updateUserExpertModeAcknowledgementShow,
  hidePhishingWarningBanner,
  setIsExchangeChartDisplayed,
  ChartViewMode,
  setChartViewMode,
  setSubgraphHealthIndicatorDisplayed,
  updateUserLimitOrderAcceptedWarning,
  setZapDisabled,
  updareUserHideClosedPositions,
} from '../actions';
import { deserializeToken, serializeToken } from './helpers';
import { BASES_TO_TRACK_LIQUIDITY_FOR, PINNED_PAIRS } from 'config/constants';
import differenceInDays from 'date-fns/differenceInDays';
import { useOfficialsAndUserAddedTokens } from 'hooks/Tokens';
import useActiveWagmi from 'hooks/useActiveWagmi';
import flatMap from 'lodash/flatMap';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Token } from '@vapordex/sdk';
import { Pair } from '@vapordex/sdk';
import { PoolCache } from '@hooks/useV2Pools';
import { getV2FactoryAddress } from '@utils/addressHelpers';
import { FeeAmount } from '@vapordex/v2-sdk';
import { MAINNET_CHAIN_IDS } from '@/config/constants/chains';
import { useFeeData } from 'wagmi';

export function useAudioModeManager(): [boolean, () => void] {
  const dispatch = useAppDispatch();
  const audioPlay = useSelector<AppState, AppState['user']['audioPlay']>(
    (state) => state.user.audioPlay,
  );

  const toggleSetAudioMode = useCallback(() => {
    if (audioPlay) {
      dispatch(muteAudio());
    } else {
      dispatch(unmuteAudio());
    }
  }, [audioPlay, dispatch]);

  useEffect(() => {
    dispatch(muteAudio());
  }, [dispatch]);

  return [audioPlay, toggleSetAudioMode];
}

export function usePhishingBannerManager(): [boolean, () => void] {
  const dispatch = useAppDispatch();
  const hideTimestampPhishingWarningBanner = useSelector<
    AppState,
    AppState['user']['hideTimestampPhishingWarningBanner']
  >((state) => state.user.hideTimestampPhishingWarningBanner);
  const now = Date.now();
  const showPhishingWarningBanner = hideTimestampPhishingWarningBanner
    ? differenceInDays(now, hideTimestampPhishingWarningBanner) >= 1
    : true;
  const hideBanner = useCallback(() => {
    dispatch(hidePhishingWarningBanner());
  }, [dispatch]);

  return [showPhishingWarningBanner, hideBanner];
}

// Get user preference for exchange price chart
// For mobile layout chart is hidden by default
export function useExchangeChartManager(
  isMobile: boolean,
): [boolean, (isDisplayed: boolean) => void] {
  const dispatch = useAppDispatch();
  const isChartDisplayed = useSelector<
    AppState,
    AppState['user']['isExchangeChartDisplayed']
  >((state) => state.user.isExchangeChartDisplayed);

  const setUserChartPreference = useCallback(
    (isDisplayed: boolean) => {
      dispatch(setIsExchangeChartDisplayed(isDisplayed));
    },
    [dispatch],
  );

  return [isMobile ? false : isChartDisplayed, setUserChartPreference];
}

export function useExchangeChartViewManager() {
  const dispatch = useAppDispatch();
  const chartViewMode = useSelector<
    AppState,
    AppState['user']['userChartViewMode']
  >((state) => state.user.userChartViewMode);

  const setUserChartViewPreference = useCallback(
    (view: ChartViewMode) => {
      dispatch(setChartViewMode(view));
    },
    [dispatch],
  );

  return [chartViewMode, setUserChartViewPreference] as const;
}

export function useZapModeManager() {
  const dispatch = useAppDispatch();
  const zapEnabled = useSelector<AppState, AppState['user']['userZapDisabled']>(
    (state) => !state.user.userZapDisabled,
  );

  const setZapEnable = useCallback(
    (enable: boolean) => {
      dispatch(setZapDisabled(!enable));
    },
    [dispatch],
  );

  return [zapEnabled, setZapEnable] as const;
}

export function useSubgraphHealthIndicatorManager() {
  const dispatch = useAppDispatch();
  const isSubgraphHealthIndicatorDisplayed = useSelector<
    AppState,
    AppState['user']['isSubgraphHealthIndicatorDisplayed']
  >((state) => state.user.isSubgraphHealthIndicatorDisplayed);

  const setSubgraphHealthIndicatorDisplayedPreference = useCallback(
    (newIsDisplayed: boolean) => {
      dispatch(setSubgraphHealthIndicatorDisplayed(newIsDisplayed));
    },
    [dispatch],
  );

  return [
    isSubgraphHealthIndicatorDisplayed,
    setSubgraphHealthIndicatorDisplayedPreference,
  ] as const;
}

export function useIsExpertMode(): boolean {
  return useSelector<AppState, AppState['user']['userExpertMode']>(
    (state) => state.user.userExpertMode,
  );
}

export function useUserIsStratosphereMember(): boolean {
  return useSelector<AppState, AppState['user']['userIsStratosphereMember']>(
    (state) => state.user.userIsStratosphereMember,
  );
}
export function useExpertModeManager(): [boolean, () => void] {
  const dispatch = useAppDispatch();
  const expertMode = useIsExpertMode();

  const toggleSetExpertMode = useCallback(() => {
    dispatch(updateUserExpertMode({ userExpertMode: !expertMode }));
  }, [expertMode, dispatch]);

  return [expertMode, toggleSetExpertMode];
}

export function useUserSingleHopOnly(): [
  boolean,
  (newSingleHopOnly: boolean) => void,
] {
  const dispatch = useAppDispatch();

  const singleHopOnly = useSelector<
    AppState,
    AppState['user']['userSingleHopOnly']
  >((state) => state.user.userSingleHopOnly);

  const setSingleHopOnly = useCallback(
    (newSingleHopOnly: boolean) => {
      dispatch(
        updateUserSingleHopOnly({ userSingleHopOnly: newSingleHopOnly }),
      );
    },
    [dispatch],
  );

  return [singleHopOnly, setSingleHopOnly];
}

export function useUserSlippageTolerance(): [
  number,
  (slippage: number) => void,
] {
  const dispatch = useAppDispatch();
  const userSlippageTolerance = useSelector<
    AppState,
    AppState['user']['userSlippageTolerance']
  >((state) => {
    return state.user.userSlippageTolerance;
  });

  const setUserSlippageTolerance = useCallback(
    (slippage: number) => {
      dispatch(
        updateUserSlippageTolerance({ userSlippageTolerance: slippage }),
      );
    },
    [dispatch],
  );

  return [userSlippageTolerance, setUserSlippageTolerance];
}

export function useUserHideClosedPositions(): [
  boolean,
  (newHideClosedPositions: boolean) => void,
] {
  const dispatch = useAppDispatch();

  const hideClosedPositions: boolean = useSelector<AppState, boolean>(
    (state) => state.user.userHideClosedPositions,
  );

  const setHideClosedPositions = useCallback(
    (newHideClosedPositions: boolean) => {
      dispatch(
        updareUserHideClosedPositions({
          userHideClosedPositions: newHideClosedPositions,
        }),
      );
    },
    [dispatch],
  );

  return [hideClosedPositions, setHideClosedPositions];
}
export function useUserFarmStakedOnly(
  isActive: boolean,
): [boolean, (stakedOnly: boolean) => void] {
  const dispatch = useAppDispatch();
  const userFarmStakedOnly = useSelector<
    AppState,
    AppState['user']['userFarmStakedOnly']
  >((state) => {
    return state.user.userFarmStakedOnly;
  });

  const setUserFarmStakedOnly = useCallback(
    (stakedOnly: boolean) => {
      const farmStakedOnly = stakedOnly
        ? FarmStakedOnly.TRUE
        : FarmStakedOnly.FALSE;
      dispatch(
        updateUserFarmStakedOnly({ userFarmStakedOnly: farmStakedOnly }),
      );
    },
    [dispatch],
  );

  return [
    userFarmStakedOnly === FarmStakedOnly.ON_FINISHED
      ? !isActive
      : userFarmStakedOnly === FarmStakedOnly.TRUE,
    setUserFarmStakedOnly,
  ];
}

export function useUserPoolStakedOnly(): [
  boolean,
  (stakedOnly: boolean) => void,
] {
  const dispatch = useAppDispatch();
  const userPoolStakedOnly = useSelector<
    AppState,
    AppState['user']['userPoolStakedOnly']
  >((state) => {
    return state.user.userPoolStakedOnly;
  });

  const setUserPoolStakedOnly = useCallback(
    (stakedOnly: boolean) => {
      dispatch(updateUserPoolStakedOnly({ userPoolStakedOnly: stakedOnly }));
    },
    [dispatch],
  );

  return [userPoolStakedOnly, setUserPoolStakedOnly];
}

export function useUserPoolsViewMode(): [
  ViewMode,
  (viewMode: ViewMode) => void,
] {
  const dispatch = useAppDispatch();
  const userPoolsViewMode = useSelector<
    AppState,
    AppState['user']['userPoolsViewMode']
  >((state) => {
    return state.user.userPoolsViewMode;
  });

  const setUserPoolsViewMode = useCallback(
    (viewMode: ViewMode) => {
      dispatch(updateUserPoolsViewMode({ userPoolsViewMode: viewMode }));
    },
    [dispatch],
  );

  return [userPoolsViewMode, setUserPoolsViewMode];
}

export function useUserFarmsViewMode(): [
  ViewMode,
  (viewMode: ViewMode) => void,
] {
  const dispatch = useAppDispatch();
  const userFarmsViewMode = useSelector<
    AppState,
    AppState['user']['userFarmsViewMode']
  >((state) => {
    return state.user.userFarmsViewMode;
  });

  const setUserFarmsViewMode = useCallback(
    (viewMode: ViewMode) => {
      dispatch(updateUserFarmsViewMode({ userFarmsViewMode: viewMode }));
    },
    [dispatch],
  );

  return [userFarmsViewMode, setUserFarmsViewMode];
}

export function useUserPredictionAcceptedRisk(): [
  boolean,
  (acceptedRisk: boolean) => void,
] {
  const dispatch = useAppDispatch();
  const userPredictionAcceptedRisk = useSelector<
    AppState,
    AppState['user']['userPredictionAcceptedRisk']
  >((state) => {
    return state.user.userPredictionAcceptedRisk;
  });

  const setUserPredictionAcceptedRisk = useCallback(
    (acceptedRisk: boolean) => {
      dispatch(
        updateUserPredictionAcceptedRisk({ userAcceptedRisk: acceptedRisk }),
      );
    },
    [dispatch],
  );

  return [userPredictionAcceptedRisk, setUserPredictionAcceptedRisk];
}

export function useUserLimitOrderAcceptedWarning(): [
  boolean,
  (acceptedRisk: boolean) => void,
] {
  const dispatch = useAppDispatch();
  const userLimitOrderAcceptedWarning = useSelector<
    AppState,
    AppState['user']['userLimitOrderAcceptedWarning']
  >((state) => {
    return state.user.userLimitOrderAcceptedWarning;
  });

  const setUserLimitOrderAcceptedWarning = useCallback(
    (acceptedRisk: boolean) => {
      dispatch(
        updateUserLimitOrderAcceptedWarning({ userAcceptedRisk: acceptedRisk }),
      );
    },
    [dispatch],
  );

  return [userLimitOrderAcceptedWarning, setUserLimitOrderAcceptedWarning];
}

export function useUserPredictionChartDisclaimerShow(): [
  boolean,
  (showDisclaimer: boolean) => void,
] {
  const dispatch = useAppDispatch();
  const userPredictionChartDisclaimerShow = useSelector<
    AppState,
    AppState['user']['userPredictionChartDisclaimerShow']
  >((state) => {
    return state.user.userPredictionChartDisclaimerShow;
  });

  const setPredictionUserChartDisclaimerShow = useCallback(
    (showDisclaimer: boolean) => {
      dispatch(
        updateUserPredictionChartDisclaimerShow({
          userShowDisclaimer: showDisclaimer,
        }),
      );
    },
    [dispatch],
  );

  return [
    userPredictionChartDisclaimerShow,
    setPredictionUserChartDisclaimerShow,
  ];
}

export function useUserPredictionChainlinkChartDisclaimerShow(): [
  boolean,
  (showDisclaimer: boolean) => void,
] {
  const dispatch = useAppDispatch();
  const userPredictionChainlinkChartDisclaimerShow = useSelector<
    AppState,
    AppState['user']['userPredictionChainlinkChartDisclaimerShow']
  >((state) => {
    return state.user.userPredictionChainlinkChartDisclaimerShow;
  });

  const setPredictionUserChainlinkChartDisclaimerShow = useCallback(
    (showDisclaimer: boolean) => {
      dispatch(
        updateUserPredictionChainlinkChartDisclaimerShow({
          userShowDisclaimer: showDisclaimer,
        }),
      );
    },
    [dispatch],
  );

  return [
    userPredictionChainlinkChartDisclaimerShow,
    setPredictionUserChainlinkChartDisclaimerShow,
  ];
}

export function useUserExpertModeAcknowledgementShow(): [
  boolean,
  (showAcknowledgement: boolean) => void,
] {
  const dispatch = useAppDispatch();
  const userExpertModeAcknowledgementShow = useSelector<
    AppState,
    AppState['user']['userExpertModeAcknowledgementShow']
  >((state) => {
    return state.user.userExpertModeAcknowledgementShow;
  });

  const setUserExpertModeAcknowledgementShow = useCallback(
    (showAcknowledgement: boolean) => {
      dispatch(
        updateUserExpertModeAcknowledgementShow({
          userExpertModeAcknowledgementShow: showAcknowledgement,
        }),
      );
    },
    [dispatch],
  );

  return [
    userExpertModeAcknowledgementShow,
    setUserExpertModeAcknowledgementShow,
  ];
}

export function useUserUsernameVisibility(): [
  boolean,
  (usernameVisibility: boolean) => void,
] {
  const dispatch = useAppDispatch();
  const userUsernameVisibility = useSelector<
    AppState,
    AppState['user']['userUsernameVisibility']
  >((state) => {
    return state.user.userUsernameVisibility;
  });

  const setUserUsernameVisibility = useCallback(
    (usernameVisibility: boolean) => {
      dispatch(
        updateUserUsernameVisibility({
          userUsernameVisibility: usernameVisibility,
        }),
      );
    },
    [dispatch],
  );

  return [userUsernameVisibility, setUserUsernameVisibility];
}

export function useUserTransactionTTL(): [number, (slippage: number) => void] {
  const dispatch = useAppDispatch();
  const userDeadline = useSelector<AppState, AppState['user']['userDeadline']>(
    (state) => {
      return state.user.userDeadline;
    },
  );

  const setUserDeadline = useCallback(
    (deadline: number) => {
      dispatch(updateUserDeadline({ userDeadline: deadline }));
    },
    [dispatch],
  );

  return [userDeadline, setUserDeadline];
}

export function useAddUserToken(): (token: Token) => void {
  const dispatch = useAppDispatch();
  return useCallback(
    (token: Token) => {
      dispatch(addSerializedToken({ serializedToken: serializeToken(token) }));
    },
    [dispatch],
  );
}

export function useRemoveUserAddedToken(): (
  chainId: number,
  address: string,
) => void {
  const dispatch = useAppDispatch();
  return useCallback(
    (chainId: number, address: string) => {
      dispatch(removeSerializedToken({ address, chainId }));
    },
    [dispatch],
  );
}

export function useGasPrice(): string {
  const { chainId } = useActiveWagmi();
  const { data } = useFeeData({
    formatUnits: 'gwei',
  });
  const userGas = useSelector<AppState, AppState['user']['gasPrice']>(
    (state) => state.user.gasPrice,
  );

  const fixedGasPrice = FIXED_GAS_PRICE?.[chainId];
  if (fixedGasPrice) return fixedGasPrice;
  if (data) return data.gasPrice.toString();
  if (MAINNET_CHAIN_IDS?.includes(chainId)) return userGas;
  return GAS_PRICE_GWEI.testnet;
}

export function useGasPriceManager(): [string, (newGasPrice: string) => void] {
  const dispatch = useAppDispatch();
  const initialGasPrice = useSelector(
    (state: { user: { gasPrice: string } }) => state.user.gasPrice,
  );
  const [gasPrice, setGasPrice] = useState<string>(initialGasPrice);

  useEffect(() => {
    setGasPrice(initialGasPrice);
  }, [initialGasPrice]);

  const updateGasPriceAction = useCallback(
    (newGasPrice: string) => {
      setGasPrice(newGasPrice);
      dispatch(updateGasPrice({ gasPrice: newGasPrice }));
    },
    [dispatch],
  );

  return [gasPrice, updateGasPriceAction];
}

function serializePair(pair: Pair): SerializedPair {
  return {
    token0: serializeToken(pair.token0),
    token1: serializeToken(pair.token1),
  };
}

export function usePairAdder(): (pair: Pair) => void {
  const dispatch = useAppDispatch();

  return useCallback(
    (pair: Pair) => {
      dispatch(addSerializedPair({ serializedPair: serializePair(pair) }));
    },
    [dispatch],
  );
}

/**
 * Given two tokens return the v1 liquidity token that represents its liquidity shares
 * @param tokenA one of the two tokens
 * @param tokenB the other token
 */
export function toV1LiquidityToken([tokenA, tokenB]: [Token, Token]): Token {
  return new Token(
    tokenA.chainId,
    Pair.getAddress(tokenA, tokenB),
    18,
    'VLP',
    'VaporDEX LP Token ',
  );
}

/**
 * Given two tokens return the v1 liquidity token that represents its liquidity shares
 * @param tokenA one of the two tokens
 * @param tokenB the other token
 * @param feeAmount fee tier of the pool
 */
export function toV2LiquidityToken([tokenA, tokenB, feeAmount]: [
  Token,
  Token,
  FeeAmount,
  number,
]): Token {
  return new Token(
    tokenA.chainId,
    PoolCache.getPoolAddress(getV2FactoryAddress(), tokenA, tokenB, feeAmount),
    18,
  );
}
/**
 * Returns all the pairs of tokens that are tracked by the user for the current chain ID.
 */
export function useTrackedTokenPairs(): [Token, Token][] {
  const { chainId } = useActiveWagmi();
  const tokens = useOfficialsAndUserAddedTokens(chainId);

  // pinned pairs
  const pinnedPairs = useMemo(
    () => (chainId ? PINNED_PAIRS[chainId] ?? [] : []),
    [chainId],
  );

  // pairs for every token against every base
  const generatedPairs: [Token, Token][] = useMemo(
    () =>
      chainId
        ? flatMap(Object.keys(tokens), (tokenAddress) => {
            const token = tokens[tokenAddress];
            // for each token on the current chain,
            return (
              // loop through all bases on the current chain
              (BASES_TO_TRACK_LIQUIDITY_FOR[chainId] ?? [])
                // to construct pairs of the given token with each base
                .map((base) => {
                  if (base.address === token.address) {
                    return null;
                  }
                  return [base, token];
                })
                .filter((p): p is [Token, Token] => p !== null)
            );
          })
        : [],
    [tokens, chainId],
  );

  // pairs saved by users
  const savedSerializedPairs = useSelector<AppState, AppState['user']['pairs']>(
    ({ user: { pairs } }) => pairs,
  );

  const userPairs: [Token, Token][] = useMemo(() => {
    if (!chainId || !savedSerializedPairs) return [];
    const forChain = savedSerializedPairs[chainId];
    if (!forChain) return [];

    return Object.keys(forChain).map((pairId) => {
      return [
        deserializeToken(forChain[pairId].token0),
        deserializeToken(forChain[pairId].token1),
      ];
    });
  }, [savedSerializedPairs, chainId]);

  const combinedList = useMemo(
    () => [...userPairs, ...generatedPairs, ...pinnedPairs],
    [generatedPairs, pinnedPairs, userPairs],
  );

  return useMemo(() => {
    // dedupes pairs of tokens in the combined list
    const keyed = combinedList.reduce<{ [key: string]: [Token, Token] }>(
      (memo, [tokenA, tokenB]) => {
        const sorted = tokenA.sortsBefore(tokenB);
        const key = sorted
          ? `${tokenA.address}:${tokenB.address}`
          : `${tokenB.address}:${tokenA.address}`;
        if (memo[key]) return memo;
        memo[key] = sorted ? [tokenA, tokenB] : [tokenB, tokenA];
        return memo;
      },
      {},
    );

    return Object.keys(keyed).map((key) => keyed[key]);
  }, [combinedList]);
}

export const useWatchlistTokens = (): [string[], (address: string) => void] => {
  const dispatch = useAppDispatch();
  const savedTokens =
    useSelector((state: AppState) => state.user.watchlistTokens) ?? [];
  const updatedSavedTokens = useCallback(
    (address: string) => {
      dispatch(addWatchlistToken({ address }));
    },
    [dispatch],
  );
  return [savedTokens, updatedSavedTokens];
};

export const useWatchlistPools = (): [string[], (address: string) => void] => {
  const dispatch = useAppDispatch();
  const savedPools =
    useSelector((state: AppState) => state.user.watchlistPools) ?? [];
  const updateSavedPools = useCallback(
    (address: string) => {
      dispatch(addWatchlistPool({ address }));
    },
    [dispatch],
  );
  return [savedPools, updateSavedPools];
};
