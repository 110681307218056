import { Box, Container, Typography } from '@vaporfi/uikit';
import { RowItem, RowItems } from './types';
import { memo } from 'react';

export const SingleRowStats = memo(({ items }: { items?: RowItems }) => {
  return (
    <Container
      fullWidth
      stack="row"
      className="justify-between gap-4 rounded-[5px] border-[1px] border-neutral-700 p-4 lg:p-[30px]"
    >
      {items?.map((item, index) => (
        <Item {...item} key={`item-${item?.label}-${index}`} />
      ))}
    </Container>
  );
});

const Item = memo(({ label, value }: RowItem) => {
  return (
    <Box className="flex flex-col gap-0.5">
      <Typography weight="semibold" className="text-xs md:text-sm lg:text-base">
        {label}
      </Typography>
      <Typography weight="semibold" className="text-xs md:text-sm lg:text-base">
        {value}
      </Typography>
    </Box>
  );
});
