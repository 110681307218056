import React from 'react';
import {
  AlertCard,
  Typography,
  Button,
  LinkExternal,
  OpenNewIcon,
} from '@vaporfi/uikit';

interface WalletInfoViewProps {
  hasLowAVAXBalance: boolean;
  balance: string;
  isFetched: boolean;
  onViewExplorer: () => void;
  onLogout: () => void;
}

export const WalletInfoView: React.FC<WalletInfoViewProps> = ({
  balance,
  hasLowAVAXBalance,
  isFetched,
  onLogout,
  onViewExplorer,
}) => {
  return (
    <>
      {hasLowAVAXBalance && (
        <AlertCard variant="warning" className="my-6 mt-2">
          <div>
            <Typography weight="semibold">AVAX Balance Low</Typography>
            <Typography>You need AVAX for transaction fees.</Typography>
          </div>
        </AlertCard>
      )}
      <div className="flex items-center justify-between">
        <Typography>AVAX Balance</Typography>
        {isFetched ? (
          <Typography>{balance}</Typography>
        ) : (
          <div className="w-15 h-5 bg-gray-300"></div>
        )}
      </div>
      <div className="mb-6 mt-6 flex items-center justify-start">
        <LinkExternal
          data-testid="wallet-info-view-on-avaxscan"
          onClick={onViewExplorer}
          className="flex items-center"
        >
          <Typography
            color="turquoise"
            size="sm"
            className="flex items-center hover:underline"
          >
            View on Avascan
            <OpenNewIcon className="ml-1" />
          </Typography>
        </LinkExternal>
      </div>

      <div className="justify-start">
        <Button
          color="light"
          size="sm"
          variant="outline"
          data-testid="wallet-info-disconnect-wallet"
          onClick={onLogout}
        >
          <Typography color="light" weight="semibold">
            Disconnect Wallet
          </Typography>
        </Button>
      </div>
    </>
  );
};
