import {
  Box,
  Button,
  Container,
  ContainerProps,
  CurrencySwitcherIcon,
  TabItem,
  TabsContainer,
} from '@vaporfi/uikit';
import { cn } from '@vaporfi/utils';
import { ReactNode, forwardRef } from 'react';

export const FormContainer = forwardRef<HTMLDivElement, ContainerProps>(
  ({ className, ...rest }, ref) => (
    <Container
      {...rest}
      ref={ref}
      bg="black"
      className={cn('gap-2 p-[30px]', className)}
    />
  ),
);
export interface CurrencyFormProps {
  /**
   * either direct title version or tabs version
   */
  title?: string;
  tabs?: () => void;
  /**
   * Created DEV-555 to migrate this
   */
  globalSettings: () => ReactNode;
  /**
   * first one is compulsory
   * second is optional
   */
  currencyInputPanel0: () => ReactNode;
  currencyInputPanel1?: () => ReactNode;
  isSwitchable: boolean;
  onCurrencySwitch?: () => void;
  buttons: () => ReactNode;
  footer?: () => ReactNode;
  preButtonFooter?: () => ReactNode;
}
export const CurrencyForm = ({
  buttons,
  currencyInputPanel0,
  currencyInputPanel1,
  footer,
  globalSettings,
  isSwitchable,
  onCurrencySwitch,
  preButtonFooter,
  title,
}: CurrencyFormProps) => {
  return (
    <FormContainer
      itemsCenter
      stack="column"
      className="h-fit min-w-[320px] rounded-[10px]"
    >
      <Box className="flex justify-between" fullWidth>
        <TabsContainer fullWidth>
          <TabItem isActive className="h-[28px]">
            {title}
          </TabItem>
        </TabsContainer>

        {globalSettings?.()}
      </Box>
      <Container
        fullWidth
        itemsCenter
        stack="column"
        className="mt-5 justify-center gap-2"
      >
        {currencyInputPanel0?.()}
        {isSwitchable && onCurrencySwitch && (
          <Button
            data-testid="swap-currency-switch-button"
            variant="round"
            name="swap currency button"
            style={{
              border: '2px solid rgba(238, 242, 242, 1)',
              padding: '2px',
            }}
            onClick={onCurrencySwitch}
          >
            <CurrencySwitcherIcon />
          </Button>
        )}

        {currencyInputPanel1 && currencyInputPanel1()}
        <Box fullWidth className="py-2">
          {preButtonFooter && preButtonFooter()}
        </Box>
        {buttons && buttons()}

        {footer && footer()}
      </Container>
    </FormContainer>
  );
};
