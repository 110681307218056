import WalletModal, { WalletView } from './WalletModal';
import WalletUserMenuItem from './WalletUserMenuItem';
import ConnectWalletButton from '@components/ConnectWalletButton';
import {
  Flex,
  LogoutIcon,
  RefreshIcon,
  UserMenu as UIKitUserMenu,
  UserMenuDivider,
  UserMenuItem,
  UserMenuVariant,
  Box,
} from '@vapordex/uikit';
import { useModal } from '@vaporfi/hooks';
import useActiveWagmi from 'hooks/useActiveWagmi';
import useAuth from 'hooks/useAuth';
import { useEffect, useState } from 'react';
import { usePendingTransactions } from 'state/transactions/hooks';
import { useUnstoppableDomain } from '@hooks/useUnstoppableDomain';
import { useEnsName } from 'wagmi';

const UserMenu = () => {
  const { account, chain, chainId, isConnected } = useActiveWagmi();
  const avvyName = useEnsName({ address: account, chainId });
  const unstoppableName = useUnstoppableDomain(account, isConnected);
  const UDID = avvyName?.data ?? unstoppableName ?? '';
  const { logout } = useAuth();
  const { hasPendingTransactions, pendingNumber } = usePendingTransactions();
  const [onPresentWalletModal] = useModal(
    <WalletModal
      initialView={WalletView.WALLET_INFO}
      UDID={UDID}
      onDismiss={null}
    />,
  );
  const [onPresentTransactionModal] = useModal(
    <WalletModal initialView={WalletView.TRANSACTIONS} onDismiss={null} />,
  );
  const [onPresentWrongNetworkModal] = useModal(
    <WalletModal initialView={WalletView.WRONG_NETWORK} onDismiss={null} />,
  );
  const [userMenuText, setUserMenuText] = useState<string>('');
  const [userMenuVariable, setUserMenuVariable] =
    useState<UserMenuVariant>('default');
  const isWrongNetwork: boolean = chain?.unsupported ?? false;

  useEffect(() => {
    if (hasPendingTransactions) {
      setUserMenuText(`${pendingNumber} Pending`);
      setUserMenuVariable('pending');
    } else {
      setUserMenuText('');
      setUserMenuVariable('default');
    }
  }, [hasPendingTransactions, pendingNumber]);

  const onClickWalletMenu = (): void => {
    if (isWrongNetwork) {
      onPresentWrongNetworkModal();
    } else {
      onPresentWalletModal();
    }
  };

  const UserMenuItems = () => {
    return (
      <>
        <WalletUserMenuItem
          data-testid="user-menu-wallet"
          isWrongNetwork={isWrongNetwork}
          onPresentWalletModal={onClickWalletMenu}
        />
        <UserMenuItem
          data-testid="user-menu-recent-transactions"
          as="button"
          disabled={isWrongNetwork}
          onClick={onPresentTransactionModal}
        >
          {'Recent Transactions'}
          {hasPendingTransactions && <RefreshIcon spin />}
        </UserMenuItem>
        <UserMenuDivider />
        <UserMenuItem
          as="button"
          onClick={logout}
          data-testid="user-menu-disconnect-btn"
        >
          <Flex alignItems="center" justifyContent="space-between" width="100%">
            {'Disconnect'}
            <LogoutIcon />
          </Flex>
        </UserMenuItem>
      </>
    );
  };

  if (isWrongNetwork) {
    return (
      <UIKitUserMenu text={'Network'} variant="danger">
        {/* @ts-expect-error: TODO */}
        {({ isOpen }) => (isOpen ? <UserMenuItems /> : null)}
      </UIKitUserMenu>
    );
  }

  if (account) {
    return (
      <UIKitUserMenu
        data-testid="user-menu"
        account={UDID || account}
        text={userMenuText}
        variant={userMenuVariable}
        UDID={UDID}
      >
        {/* @ts-expect-error: TODO */}
        {({ isOpen }) => (isOpen ? <UserMenuItems /> : null)}
      </UIKitUserMenu>
    );
  }

  return (
    <ConnectWalletButton>
      <Box as="span" display={['none', , , 'block']}>
        Connect Wallet
      </Box>
      <Box as="span" display={['block', , , 'none']}>
        Connect
      </Box>
    </ConnectWalletButton>
  );
};

export default UserMenu;
