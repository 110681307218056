// ABIs
import { MulticallABI } from '@config/abi/Multicall';
import aggregatorRouterAbi from '@config/abi/Router';
import { ERC20ABI } from '@config/abi/erc20ABI';
import { ERC721ABI } from '@config/abi/erc721';
import { ERC721CollectionABI } from 'config/abi/erc721collection';
import { LPTokenABI } from 'config/abi/lpToken';
import { ZAP_ABI } from 'config/abi/zap';
import LiquidMiningABI from '@config/abi/LiquidMining';
// Addresses
import {
  getAggregatorRouterAddress,
  getLiquidMiningAddresss,
  getMulticallAddress,
  getZapAddress,
} from 'utils/addressHelpers';
import { getContract } from '@utils';
import { Address } from 'viem';
import { WalletClient } from 'wagmi';

export const getBep20Contract = (address: Address, signer?: WalletClient) => {
  return getContract<typeof ERC20ABI>(address, ERC20ABI, signer);
};
export const getErc721Contract = (address: Address, signer?: WalletClient) => {
  return getContract<typeof ERC721ABI>(address, ERC721ABI, signer);
};
export const getLpContract = (address: Address, signer?: WalletClient) => {
  return getContract<typeof LPTokenABI>(address, LPTokenABI, signer);
};

export const getMulticallContract = () => {
  return getContract<typeof MulticallABI>(getMulticallAddress(), MulticallABI);
};

export const getErc721CollectionContract = (
  signer?: WalletClient,
  address?: Address,
) => {
  return getContract<typeof ERC721CollectionABI>(
    address,
    ERC721CollectionABI,
    signer,
  );
};

export const getZapContract = (signer?: WalletClient) => {
  return getContract<typeof ZAP_ABI>(getZapAddress(), ZAP_ABI, signer);
};

export const getAggregatorRouterContract = (signer?: WalletClient) => {
  return getContract<typeof aggregatorRouterAbi>(
    getAggregatorRouterAddress(),
    aggregatorRouterAbi,
    signer,
  );
};

export const getLiquidMiningContract = (signer?: WalletClient) => {
  return getContract<typeof LiquidMiningABI>(
    getLiquidMiningAddresss(),
    LiquidMiningABI,
    signer,
  );
};
