'use client';

import { useMemo } from 'react';
import { SwapComponentProps, SwapField } from './types';

export const useFieldValues = ({
  activeField,
  amounts,
  otherField,
  tokens,
}: Pick<
  SwapComponentProps,
  'tokens' | 'amounts' | 'activeField' | 'otherField'
>) => {
  const [independentToken, dependentToken] = useMemo(() => {
    if (tokens) {
      return [tokens?.[activeField], tokens?.[otherField]];
    }
    return [{}, {}];
  }, [activeField, otherField, tokens]);

  const [independentAmount, dependentAmount] = useMemo(() => {
    if (amounts) {
      return [amounts?.[activeField], amounts?.[otherField]];
    }
    return ['', ''];
  }, [activeField, amounts, otherField]);

  const candyToken = useMemo(() => tokens?.[SwapField.TOKEN], [tokens]);

  return useMemo(
    () => ({
      candyToken,
      dependentAmount,
      dependentToken,
      independentAmount,
      independentToken,
    }),
    [
      candyToken,
      dependentAmount,
      dependentToken,
      independentAmount,
      independentToken,
    ],
  );
};
