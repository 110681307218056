import { AlertCard, Box, Button, Typography } from '@vaporfi/uikit';
import { memo } from 'react';

export interface RebalanceAlertProps {
  handleNoConvert: () => void;
  swapTokenSymbol: string;
  otherTokenSymbol: string;
  gasOverhead: boolean;
}
//otherTokenSymbol = swapOutToken
export const RebalanceAlert = memo(
  ({
    gasOverhead,
    handleNoConvert,
    otherTokenSymbol,
    swapTokenSymbol,
  }: RebalanceAlertProps) => {
    return (
      <AlertCard variant="warning">
        <Box className="flex flex-col items-start gap-2">
          <Typography data-testid="not-enough-tokens">
            <span className="font-semibold">
              {`Not enough ${otherTokenSymbol}. `}
            </span>
            {gasOverhead
              ? `Some of your ${swapTokenSymbol} will be converted to ${otherTokenSymbol} before adding liquidity, but this may cause higher gas fees.`
              : `Some of your ${swapTokenSymbol} will be converted to ${otherTokenSymbol}.`}
          </Typography>

          <Button
            variant="outline"
            color="light"
            size="sm"
            onClick={handleNoConvert}
          >
            <Typography>{'Don’t convert'}</Typography>
          </Button>
        </Box>
      </AlertCard>
    );
  },
);
