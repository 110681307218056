import React, { useState } from 'react';
import ExpertModal from './ExpertModal';
import { SettingsModalView } from '@vaporfi/features';
import {
  useExpertModeManager,
  useUserExpertModeAcknowledgementShow,
  useUserSingleHopOnly,
  useZapModeManager,
} from 'state/user/hooks';
import { GasSettings } from './GasSettings';
import { SlippageTabs } from './SlippageTabs';

const SettingsModal = ({ onDismiss }) => {
  const [showConfirmExpertModal, setShowConfirmExpertModal] = useState(false);
  const [expertMode, toggleExpertMode] = useExpertModeManager();
  const [singleHopOnly, setSingleHopOnly] = useUserSingleHopOnly();
  const [zapMode, toggleZapMode] = useZapModeManager();
  const [showExpertModeAcknowledgement, setShowExpertModeAcknowledgement] =
    useUserExpertModeAcknowledgementShow();
  const handleToggleZapMode = () => toggleZapMode(!zapMode);

  const handleExpertModeToggle = () => {
    if (expertMode) {
      toggleExpertMode();
    } else if (showExpertModeAcknowledgement) {
      setShowConfirmExpertModal(true);
    } else {
      toggleExpertMode();
    }
  };

  if (showConfirmExpertModal) {
    return (
      <ExpertModal
        setShowConfirmExpertModal={setShowConfirmExpertModal}
        onDismiss={onDismiss}
        setShowExpertModeAcknowledgement={setShowExpertModeAcknowledgement}
      />
    );
  }

  return (
    <SettingsModalView
      onDismiss={onDismiss}
      expertMode={expertMode}
      toggleExpertMode={handleExpertModeToggle}
      singleHopOnly={singleHopOnly}
      setSingleHopOnly={setSingleHopOnly}
      zapMode={zapMode}
      toggleZapMode={handleToggleZapMode}
      showConfirmExpertModal={showConfirmExpertModal}
      setShowConfirmExpertModal={setShowConfirmExpertModal}
      setShowExpertModeAcknowledgement={setShowExpertModeAcknowledgement}
      GasSettingsComponent={GasSettings}
      SlippageTabsComponent={SlippageTabs}
    />
  );
};

export default SettingsModal;
