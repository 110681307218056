import { CurrencyAmount, Token } from '@vapordex/sdk';
import { Points } from '../entities/Points';
import { HUNDRED } from '../constants';
import { BIG_INT_10_POWER_18 } from '../constants';
import JSBI from 'jsbi';

export function computeUserShares<Shares extends Points>(
  userPoints: Points,
  totalSeasonPoints: Points,
): Shares {
  //better to not throw error by using invariant, return 0 instead
  if (totalSeasonPoints.toExact() === '0') {
    return <Shares>Points.fromRawAmount(0);
  }
  return <Shares>(
    userPoints
      ?.multiply(HUNDRED)
      .multiply(BIG_INT_10_POWER_18)
      .divide(totalSeasonPoints)
  );
}

export function computeUserEarnedVAPE<Shares extends Points>(
  vapeToken: Token,
  userShare: Shares,
  rewardTokensToDistribute: CurrencyAmount<Token>,
): CurrencyAmount<Token> {
  if (userShare.toExact() === '0') {
    return CurrencyAmount.fromRawAmount(vapeToken, '0');
  }
  return CurrencyAmount.fromRawAmount(
    vapeToken,
    userShare
      .divide(HUNDRED)
      .multiply(rewardTokensToDistribute.quotient)
      .divide(BIG_INT_10_POWER_18).quotient,
  );
}

export function computePointsRequiredForNextShare<Shares extends Points>(
  userShares: Shares,
  userPoints: Points,
) {
  const PRECISION = BIG_INT_10_POWER_18;
  const nextShare = Points.fromRawAmount(
    JSBI.multiply(JSBI.BigInt(parseInt(userShares.toExact()) + 1), PRECISION),
  ) as Shares;
  const totalPointsAtNextShare = userPoints.multiply(
    nextShare.divide(userShares),
  );
  const numPointsRequiredForNextShare =
    totalPointsAtNextShare.subtract(userPoints);
  return {
    numPointsRequiredForNextShare,
    percentOfPointsRequired: nextShare.subtract(userShares),
    nextShare,
    totalPointsAtNextShare,
  };
}
