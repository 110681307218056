import { Svg, SvgProps } from './Svg';

export const OkxWalletIcon: React.FC<React.PropsWithChildren<SvgProps>> = (
  props,
) => {
  return (
    <Svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="30px"
      height="30px"
      viewBox="0 0 120.000000 120.000000"
      fill="#ffffff"
      {...props}
    >
      <g
        transform="translate(0.000000,120.000000) scale(0.100000,-0.100000)"
        stroke="none"
      >
        <path d="M195 1191 c-75 -13 -132 -57 -171 -131 -18 -33 -19 -65 -19 -460 0 -416 0 -426 22 -465 25 -47 64 -85 113 -111 33 -18 65 -19 460 -19 395 0 427 1 460 19 49 26 88 64 113 111 22 39 22 49 22 465 0 395 -1 427 -19 460 -26 49 -64 88 -111 113 -39 21 -51 22 -435 24 -217 0 -413 -2 -435 -6z m283 -353 l3 -118 -121 0 -120 0 0 113 c0 63 3 117 7 121 4 3 57 5 117 4 l111 -3 3 -117z m477 2 l0 -115 -117 -3 -118 -3 0 121 0 121 118 -3 117 -3 0 -115z m-240 -240 l0 -115 -115 0 -115 0 -3 104 c-1 58 0 111 2 118 4 11 31 13 118 11 l113 -3 0 -115z m-235 -240 l0 -121 -117 3 -118 3 -3 104 c-1 58 0 111 2 118 4 10 35 13 121 13 l115 0 0 -120z m478 3 l-3 -118 -115 0 -115 0 -3 118 -3 117 121 0 121 0 -3 -117z" />
      </g>
    </Svg>
  );
};
