'use client';
import { useTooltip, Placement } from '@vapordex/uikit';
import { HelpIcon } from '@vaporfi/uikit';

interface Props {
  className?: string;
  text: string | React.ReactNode;
  placement?: Placement;
  size?: string;
}

export const QuestionHelper: React.FC<React.PropsWithChildren<Props>> = ({
  className,
  placement = 'right-end',
  size = '16px',
  text,
  ...props
}) => {
  const { targetRef, tooltip, tooltipVisible } = useTooltip(text, {
    placement,
  });

  return (
    <div {...props} className={`relative ${className}`}>
      {tooltipVisible && tooltip}
      <div
        ref={targetRef}
        className="cursor-pointer opacity-100 hover:opacity-70 focus:opacity-70"
      >
        <HelpIcon fill="var(--colors-light)" width={size} />
      </div>
    </div>
  );
};
