import { Box, Flex } from '../../../../components/Box';
import { ChevronDownIcon } from '../../../../components/Svg';
import MenuIcon from './MenuIcon';
import { UserMenuItem } from './styles';
import { UserMenuProps, variants, Variant } from './types';
import React, { useEffect, useState } from 'react';
import { usePopper } from 'react-popper';
import styled from 'styled-components';

const variantToColor = (variant?: Variant) => {
  if (variant === variants.DANGER) return 'failure';
  if (variant === variants.WARNING) return 'warning';
  if (variant === variants.PENDING) return 'secondary';

  return 'primary';
};

export const StyledUserMenu = styled(Flex)<{ variant?: Variant }>`
  align-items: center;
  // background-color: ${({ theme }) => theme.colors.tertiary};
  border-radius: 100px;
  border: 2px solid
    ${({ theme, variant }) => theme.colors[variantToColor(variant)]};
  box-shadow: inset 0px -2px 0px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  display: inline-flex;
  height: 38px;
  padding-left: 40px;
  padding-right: 8px;
  position: relative;

  &:hover {
    opacity: 0.65;
  }
`;

export const LabelText = styled.div`
  color: ${({ theme }) => theme.colors.text};
  display: none;
  font-weight: 600;

  ${({ theme }) => theme.mediaQueries.sm} {
    display: block;
    margin-left: 8px;
    margin-right: 4px;
  }
`;

const Menu = styled.div<{ isOpen: boolean }>`
  background-color: rgba(0, 0, 0, 1);
  border: 1px solid #383241;
  border-radius: 10px;
  padding-bottom: 4px;
  padding-top: 4px;
  pointer-events: auto;
  width: 280px;
  visibility: visible;
  z-index: 1001;

  ${({ isOpen }) =>
    !isOpen &&
    `
    pointer-events: none;
    visibility: hidden;
  `}

  ${UserMenuItem}:first-child {
    border-radius: 8px 8px 0 0;
  }

  ${UserMenuItem}:last-child {
    border-radius: 0 0 8px 8px;
  }
`;

const UserMenu: React.FC<React.PropsWithChildren<UserMenuProps>> = ({
  UDID,
  account,
  avatarSrc,
  children,
  text,
  variant = variants.DEFAULT,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [targetRef, setTargetRef] = useState<HTMLDivElement | null>(null);
  const [tooltipRef, setTooltipRef] = useState<HTMLDivElement | null>(null);
  const accountEllipsis = UDID
    ? `${UDID}`
    : account
    ? `${account.slice(0, 4)}...${account.slice(
        Math.max(0, account.length - 4),
      )}`
    : null;

  const { attributes, styles } = usePopper(targetRef, tooltipRef, {
    modifiers: [{ name: 'offset', options: { offset: [0, 0] } }],
    placement: 'bottom-end',
    strategy: 'fixed',
  });

  useEffect(() => {
    const showDropdownMenu = () => {
      setIsOpen(true);
    };

    const hideDropdownMenu = (evt: MouseEvent | TouchEvent) => {
      const target = evt.target as Node;
      if (target && !tooltipRef?.contains(target)) {
        setIsOpen(false);
        evt.stopPropagation();
      }
    };

    targetRef?.addEventListener('mouseenter', showDropdownMenu);
    targetRef?.addEventListener('mouseleave', hideDropdownMenu);

    return () => {
      targetRef?.removeEventListener('mouseenter', showDropdownMenu);
      targetRef?.removeEventListener('mouseleave', hideDropdownMenu);
    };
  }, [targetRef, tooltipRef, setIsOpen]);

  return (
    <Flex alignItems="center" height="100%" ref={setTargetRef} {...props}>
      <StyledUserMenu
        variant={variant}
        onTouchStart={() => {
          setIsOpen((s) => !s);
        }}
      >
        <MenuIcon
          data-testid="user-menu-avatar"
          avatarSrc={avatarSrc}
          variant={variant}
        />
        <LabelText data-testid="user-menu-address" title={text || account}>
          {text || accountEllipsis}
        </LabelText>
        <ChevronDownIcon color="text" width="24px" />
      </StyledUserMenu>
      <Menu
        style={styles.popper}
        ref={setTooltipRef}
        {...attributes.popper}
        isOpen={isOpen}
      >
        <Box onClick={() => setIsOpen(false)}>{children?.({ isOpen })}</Box>
      </Menu>
    </Flex>
  );
};

export default UserMenu;
