'use client';
import {
  CoinbaseWalletIcon,
  CoreWalletIcon,
  GnosisSafeIcon,
  MetamaskIcon,
  OkxWalletIcon,
  TrustWalletIcon,
  WalletConnectIcon,
} from '@vaporfi/uikit';
import { Config, ConnectorNames } from './types';
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet';
import { InjectedConnector } from 'wagmi/connectors/injected';
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect';
import {
  CoreWalletConnector,
  StargazerConnector,
  SafeConnector,
  OkxConnector,
} from '@vaporfi/connectors';

import { Chain, WalletClient } from 'viem';

export const getConnectors = ({
  chains,
  walletConnectProjectId,
}: {
  walletConnectProjectId?: string;
  mockClient?: () => WalletClient;
  chains: Chain[];
}) => {
  const injected = new InjectedConnector({ chains });
  const metamask = new InjectedConnector({ chains });
  const walletConnect = new WalletConnectConnector({
    chains,
    options: { projectId: walletConnectProjectId },
  });
  const coinbase = new CoinbaseWalletConnector({
    chains,
    options: { appName: 'VaporDEX' },
  });

  const okx = new OkxConnector({ chains });
  const stargazer = new StargazerConnector({ chains });
  const core = new CoreWalletConnector({ chains });
  const safe = new SafeConnector({ chains });

  return {
    [ConnectorNames.Coinbase]: coinbase,
    [ConnectorNames.Core]: core,
    [ConnectorNames.GnosisSafe]: safe,
    [ConnectorNames.Injected]: injected,
    [ConnectorNames.MetaMask]: metamask,
    [ConnectorNames.OKX]: okx,
    [ConnectorNames.Stargazer]: stargazer,
    [ConnectorNames.WalletConnect]: walletConnect,
  };
};

export const connectors: Config[] = [
  {
    connectorId: ConnectorNames.Core,
    href: 'https://core.app/',
    icon: CoreWalletIcon,
    priority: 1,
    title: 'Core',
  },
  {
    connectorId: ConnectorNames.MetaMask,
    href: 'https://metamask.io/download/',
    icon: MetamaskIcon,
    priority: 2,
    title: 'Metamask',
  },
  {
    connectorId: ConnectorNames.OKX,
    href: 'https://www.okx.com/download?deeplink=okx%3A%2F%2Fwallet%2Fdapp%2Furl%3FdappUrl%3Dhttps%253A%252F%252Fapp.vapordex.io%252F',
    icon: OkxWalletIcon,
    priority: 3,
    title: 'OKX Wallet',
  },
  {
    connectorId: ConnectorNames.Coinbase,
    icon: CoinbaseWalletIcon,
    priority: 4,
    title: 'Coinbase Wallet',
  },
  {
    connectorId: ConnectorNames.Injected,
    href: 'https://link.trustwallet.com/open_url?coin_id=20000714&url=https://app.vapordex.io/',
    icon: TrustWalletIcon,
    priority: 5,
    title: 'Trust Wallet',
  },

  {
    connectorId: ConnectorNames.GnosisSafe,
    href: 'https://safe.global/',
    icon: GnosisSafeIcon,
    priority: 6,
    title: 'Safe',
  },
  {
    connectorId: ConnectorNames.WalletConnect,
    icon: WalletConnectIcon,
    priority: 7,
    title: 'WalletConnect',
  },
];

export const connectorLocalStorageKey = 'connectorIdv2';
export const walletLocalStorageKey = 'wallet';

export const walletConnectConfig = connectors.find(
  (c) => c.title === 'WalletConnect',
);
