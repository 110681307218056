import { memo, forwardRef, ReactNode } from 'react';
import { tv } from 'tailwind-variants';
import { Input } from '../Input';
import { Button } from '../Button';
import { Typography } from '../Typography';
import { Currency, Pair } from '@vapordex/sdk';
import { Dropdown } from '../Icons/Dropdown';

export type ZapStyle = 'noZap' | 'zap';

export interface CurrencyInputPanelProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onPresentCurrencyModal?: any;
  balance?: string;
  value: string;
  onUserInput?: (value: string) => void;
  onInputBlur?: () => void;
  onHalf?: () => void;
  onMax?: () => void;
  showMaxButton?: boolean;
  label?: string;
  onCurrencySelect?: (currency: Currency) => void;
  currency?: Currency | null;
  disableCurrencySelect?: boolean;
  hideBalance?: boolean;
  pair?: Pair | null;
  otherCurrency?: Currency | null;
  id: string;
  showCommonBases?: boolean;
  zapStyle?: ZapStyle;
  beforeButton?: ReactNode;
  error?: boolean;
  showBUSD?: boolean;
  disabledSelect?: boolean;
  disabledInput?: boolean;
  logo?: JSX.Element;
  account?: string;
  showHalfButton?: boolean;
  balanceOverrides?: { label: string; value: string };
}

const currencyInputPanelStyles = tv({
  base: 'bg-neutral-900 rounded-md flex flex-col gap-2.5 p-2 w-full',
  variants: {
    //new variants can be added here
  },
});

const CurrencyInputPanel = memo(
  forwardRef<HTMLDivElement, CurrencyInputPanelProps>(
    (
      {
        account,
        balance,
        balanceOverrides,
        currency,
        disableCurrencySelect = false,
        disabledInput,
        hideBalance = false,
        id,
        logo,
        onHalf,
        onInputBlur,
        onMax,
        onPresentCurrencyModal,
        onUserInput,
        pair = null,
        showHalfButton,
        showMaxButton,
        value,
      },
      ref,
    ) => {
      return (
        <div className={currencyInputPanelStyles()} ref={ref} id={id}>
          <div className="flex w-full items-center justify-between border-b border-neutral-800 pb-2.5">
            <Button
              variant="tokenSelector"
              disabled={disableCurrencySelect}
              onClick={() => {
                if (!disableCurrencySelect) {
                  onPresentCurrencyModal();
                }
              }}
            >
              {logo}
              {pair ? (
                <Typography id="pair" color="light" size="sm" weight="semibold">
                  {pair?.token0.symbol}:{pair?.token1.symbol}
                </Typography>
              ) : (
                <Typography
                  id="token"
                  color="light"
                  size="sm"
                  weight="semibold"
                >
                  {currency?.symbol
                    ? currency.symbol.length > 20
                      ? `${currency.symbol.slice(
                          0,
                          4,
                        )}...${currency.symbol.slice(-5)}`
                      : currency.symbol
                    : 'Choose'}
                </Typography>
              )}
              {!disableCurrencySelect && <Dropdown />}
            </Button>
            <Input
              disabled={disabledInput}
              value={value}
              title={'Token Amount'}
              onBlur={onInputBlur}
              readOnly={disabledInput}
              onUserInput={(val) => {
                if (!val) {
                  onUserInput?.('');
                  return;
                }
                if (val.startsWith('.') || val.startsWith(',')) {
                  onUserInput?.('0.');
                  return;
                }
                onUserInput?.(val.slice(0, 20));
              }}
            />
          </div>
          <div className="flex w-full items-center justify-between">
            <Typography weight="semibold" color="neutral" size="xs">
              {balanceOverrides ? balanceOverrides.label : 'Balance'}
            </Typography>
            <div className="flex items-center gap-2">
              {account && (
                <Typography
                  data-testid="swap-balance-text"
                  onClick={disabledInput ? undefined : onMax}
                  weight="semibold"
                  size="xs"
                  color="neutral"
                >
                  {!hideBalance && currency
                    ? balanceOverrides
                      ? balanceOverrides.value
                      : balance
                    : '-'}
                </Typography>
              )}
              {showHalfButton && (
                <Button onClick={onHalf} size="xs" color="dark">
                  <Typography>50%</Typography>
                </Button>
              )}
              {showMaxButton && (
                <Button onClick={onMax} size="xs" color="dark">
                  <Typography>max</Typography>
                </Button>
              )}
            </div>
          </div>
        </div>
      );
    },
  ),
);

export { CurrencyInputPanel };
