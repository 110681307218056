import { theme } from '../stitches.config';

export function getAllBorderRadius() {
  const radiis = theme['radii'];

  return Object.keys(radiis).reduce(
    (acc, curr) => {
      acc[curr as keyof typeof radiis] = {
        borderRadius: radiis[curr as keyof typeof radiis].value as string,
      };
      return acc;
    },
    {} as { [k in keyof typeof radiis]: { borderRadius: string } },
  );
}

export function getAllSpaces() {
  const spaces = theme['space'];
  type Keys = keyof typeof spaces;
  return Object.keys(spaces).reduce(
    (acc, curr) => {
      acc[curr as Keys] = {
        gap: spaces[curr as Keys].value,
      };
      return acc;
    },
    {} as { [k in Keys]: { gap: string } },
  );
}

export function getAllPaddings() {
  const spaces = theme['space'];
  type Keys = keyof typeof spaces;
  return Object.keys(spaces).reduce(
    (acc, curr) => {
      acc[curr as Keys] = {
        padding: spaces[curr as Keys].value,
      };
      return acc;
    },
    {} as { [k in Keys]: { padding: string } },
  );
}

export function getAllPaddingTop() {
  const spaces = theme['space'];
  type Keys = keyof typeof spaces;
  return Object.keys(spaces).reduce(
    (acc, curr) => {
      acc[curr as Keys] = {
        paddingTop: spaces[curr as Keys].value,
      };
      return acc;
    },
    {} as { [k in Keys]: { paddingTop: string } },
  );
}

export function escapeRegExp(string: string): string {
  return string.replaceAll(/[$()*+.?[\\\]^{|}]/g, '\\$&'); // $& means the whole matched string
}

//todo: create an utils package and use everywhere
export function formatNumber(number: string | number, location: string) {
  return new Intl.NumberFormat(location ?? 'en-US', {
    maximumFractionDigits: 18,
  }).format(Number(number));
}
