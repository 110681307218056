import { AlertCard, Box, Button, Typography } from '@vaporfi/uikit';
import { memo } from 'react';

export interface ZapAlertProps {
  swapTokenSymbol: string;
  otherTokenSymbol: string;
  showReduceZapTokenButton: boolean;
  convertCallback: () => void;
  priceSeverity: number;
}
export const ZapAlert = memo(
  ({
    convertCallback,
    otherTokenSymbol,
    priceSeverity,
    showReduceZapTokenButton,
    swapTokenSymbol,
  }: ZapAlertProps) => {
    return (
      <AlertCard variant={priceSeverity > 3 ? 'error' : 'warning'}>
        <Box className="flex flex-col items-start gap-2">
          {priceSeverity > 3 ? (
            <Typography>
              <span className="font-semibold">Not enough tokens. </span>

              {`Reduce amount of ${swapTokenSymbol} to maximum limit.`}
            </Typography>
          ) : (
            <Typography>
              <span className="font-semibold">
                {`No ${otherTokenSymbol} input. `}
              </span>
              {`Some of your ${swapTokenSymbol} will be converted to ${otherTokenSymbol}.`}
            </Typography>
          )}
          {showReduceZapTokenButton && (
            <Button
              variant="outline"
              color="light"
              size="sm"
              onClick={convertCallback}
            >
              <Typography>{`Reduce ${swapTokenSymbol}`}</Typography>
            </Button>
          )}
        </Box>
      </AlertCard>
    );
  },
);
