'use client';
/* eslint-disable unicorn/no-useless-undefined */
import { useCallback, useMemo, useState } from 'react';
import { compressAccurately } from 'image-conversion';

export const defaultImageFormats: `image/${string}`[] = [
  'image/png',
  'image/jpg',
  'image/jpeg',
];
type ImageUploadProps = {
  isCompressionRequired: boolean;
  onSuccess?: (error: string) => void;
  onError: (error: string) => void;
  supportedFormats?: string[];
};

export const useImageUpload = ({
  isCompressionRequired,
  onError,
  supportedFormats,
}: ImageUploadProps) => {
  const [selectedFile, selectFile] = useState<File | Blob>();
  const [isUploaded, setIsUploaded] = useState<boolean>();
  const selectedImage = useMemo(() => {
    if (selectedFile) return URL.createObjectURL(selectedFile);
  }, [selectedFile]);

  const onFileSelect = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (file) {
        const mimeType = file.type;
        if (supportedFormats && !supportedFormats.includes(mimeType)) {
          return onError('Invalid file type. Only PNG and JPG are supported.');
        }
        if (isCompressionRequired) {
          compressAccurately(file, 100).then((compressedFile) => {
            selectFile(compressedFile);
          });
        } else {
          selectFile(file);
        }
        setIsUploaded(false);
      }
      return;
    },
    [onError, isCompressionRequired, supportedFormats],
  );

  const toggleIsUploaded = useCallback((state: boolean) => {
    setIsUploaded(state);
  }, []);

  const clearStates = useCallback(() => {
    selectFile(undefined);
    setIsUploaded(undefined);
  }, []);

  return {
    clearStates,
    isUploaded,
    onFileSelect,
    selectedFile,
    selectedImage,
    toggleIsUploaded,
  };
};
