const borderTextOutlineCombo = {
  'border-blue-500': '[&>p]:text-light',
  'border-light': '[&>p]:text-light',
  'border-neutral-800': '[&>p]:text-neutral-400',
  'border-turquoise-500': '[&>p]:text-black',
} as { [x: string]: string };

export const generateOutlineStyles = (...borderColors: string[]) => {
  const sizes = ['xs', 'sm', 'base'];
  return sizes.reduce((acc: { class: string }[], curr) => {
    return [
      ...acc,
      ...borderColors.map((borderColor) => {
        const [, textColor] = borderColor.split('-');
        return {
          class: `${
            curr === 'xs' ? 'border-[1px]' : 'border-2'
          } ${borderColor} ${borderTextOutlineCombo[borderColor]}`,
          color: textColor,
          size: curr,
          variant: 'outline',
        };
      }),
    ];
  }, []);
};
