import Button from './Button';
import { BaseButtonProps } from './types';
import styled from 'styled-components';

const IconButton = styled(Button)<BaseButtonProps>`
  padding: 0;
  width: ${({ scale }) => (scale === 'sm' ? '32px' : '48px')};
`;

export default IconButton;
