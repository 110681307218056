import { NETWORK_ICON } from '@vaporfi/utils';
import { ChainId } from '@vapordex/sdk';

export const chainIconUrls = (chainName: string): string[] => {
  const chainNameToId: { [key: string]: number } = {
    Avalanche: ChainId.AVALANCHE,
    Telos: ChainId.TELOS,
    // Add other chains as needed
  };

  if (chainName === 'Unknown') {
    return Object.keys(chainNameToId).map(
      (key) => NETWORK_ICON[chainNameToId[key]],
    );
  } else {
    const chainId = chainNameToId[chainName];
    return [NETWORK_ICON[chainId] || ''];
  }
};
