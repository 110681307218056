/* eslint-disable no-param-reassign */
import { getChainIdCookie } from '@config/constants/networks';
import {
  combinedTokenMapFromOfficialsUrlsSelector,
  useUnsupportedTokenList,
  useWarningTokenList,
} from '@state/lists/hooks';
import { createSelector } from '@reduxjs/toolkit';

import { TokenAddressMap } from '@state/types';
import { useAllTokens } from '@hooks/useCurrency';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import useActiveWagmi from '@hooks/useActiveWagmi';
import useUserAddedTokens, {
  userAddedTokenSelector,
} from '@state/user/hooks/useUserAddedTokens';
import { Token, Currency } from '@vapordex/sdk';

const mapWithoutUrls = (tokenMap: TokenAddressMap, chainId?: number) => {
  return Object.keys(tokenMap[chainId || getChainIdCookie()] ?? {}).reduce<{
    [address: string]: Token;
  }>((newMap, address) => {
    newMap[address] = tokenMap[chainId || getChainIdCookie()][address].token;
    return newMap;
  }, {});
};

const allOfficialsAndUserAddedTokensSelector = (chainId) =>
  createSelector(
    [combinedTokenMapFromOfficialsUrlsSelector, userAddedTokenSelector],
    (tokenMap, userAddedTokens) => {
      return (
        userAddedTokens
          // reduce into all ALL_TOKENS filtered by the current chain
          .reduce<{ [address: string]: Token }>(
            (tokenMap_, token) => {
              tokenMap_[token.address] = token;
              return tokenMap_;
            },

            // must make a copy because reduce modifies the map, and we do not
            // want to make a copy in every iteration
            mapWithoutUrls(tokenMap, chainId),
          )
      );
    },
  );

/**
 * Returns all tokens that are from officials token list and user added tokens
 */
export function useOfficialsAndUserAddedTokens(chainId): {
  [address: string]: Token;
} {
  return useSelector(allOfficialsAndUserAddedTokensSelector(chainId));
}

export function useUnsupportedTokens(): { [address: string]: Token } {
  const unsupportedTokensMap = useUnsupportedTokenList();
  return useMemo(
    () => mapWithoutUrls(unsupportedTokensMap),
    [unsupportedTokensMap],
  );
}

export function useWarningTokens(): { [address: string]: Token } {
  const warningTokensMap = useWarningTokenList();
  return useMemo(() => mapWithoutUrls(warningTokensMap), [warningTokensMap]);
}

export function useIsTokenActive(token: Token | undefined | null): boolean {
  const { chainId } = useActiveWagmi();
  const activeTokens = useAllTokens(chainId);

  if (!activeTokens || !token) {
    return false;
  }

  return !!activeTokens[token.address];
}

// Check if currency is included in custom list from user storage
export function useIsUserAddedToken(
  currency: Currency | undefined | null,
): boolean {
  const userAddedTokens = useUserAddedTokens();

  if (!currency) {
    return false;
  }

  return !!userAddedTokens.some((token) => currency.equals(token));
}

export { useToken, useAllTokens } from '@hooks/useCurrency';
