import React, { useState } from 'react';
import { WalletModalView } from '@vaporfi/features';
import WalletInfo from './WalletInfo';
import WalletTransactions from './WalletTransactions';
import WalletWrongNetwork from './WalletWrongNetwork';
import { useLowBalance } from 'hooks/useTokenBalance';

export enum WalletView {
  WALLET_INFO,
  TRANSACTIONS,
  WRONG_NETWORK,
}

interface WalletModalProps {
  initialView?: WalletView;
  UDID?: string;
  onDismiss: () => void;
}

const WalletModal: React.FC<WalletModalProps> = ({
  UDID,
  initialView = WalletView.WALLET_INFO,
  onDismiss,
}) => {
  const [view, setView] = useState(initialView);
  const hasLowBalance = useLowBalance();

  return (
    <WalletModalView
      view={view}
      setView={setView}
      renderWalletInfo={() =>
        view === WalletView.WALLET_INFO ? (
          <WalletInfo
            hasLowAVAXBalance={hasLowBalance}
            onDismiss={onDismiss}
            UDID={UDID}
          />
        ) : null
      }
      renderTransactions={() =>
        view === WalletView.TRANSACTIONS ? <WalletTransactions /> : null
      }
      renderWrongNetwork={() =>
        view === WalletView.WRONG_NETWORK ? (
          <WalletWrongNetwork onDismiss={onDismiss} />
        ) : null
      }
      onDismiss={onDismiss}
    />
  );
};

export default WalletModal;
