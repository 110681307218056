'use client';

import React, { useState } from 'react';
import {
  Container,
  Box,
  Typography,
  Button,
  DoubleRingIcon,
  ChevronDownIcon,
  OpenNewIcon,
  Select,
  FunnelIcon,
  Span,
} from '@vaporfi/uikit';
import { chainIconUrls } from './chainIcon';
import { validChains, validTiers } from '@vaporfi/utils';
import Link from 'next/link';

export interface PartnerProfileProps {
  imageUrl: string;
  header: string;
  deadline: string;
  contentLines: string[];
  promoCode: string;
  partnerSiteUrl: string;
  chainName: string;
  levelIndicator: React.ReactNode;
  canRevealPromo: boolean;
}

export const PartnerProfileCard: React.FC<PartnerProfileProps> = ({
  canRevealPromo,
  chainName,
  contentLines,
  deadline,
  header,
  imageUrl,
  levelIndicator,
  partnerSiteUrl,
  promoCode,
}) => {
  const [showPromoCode, setShowPromoCode] = useState(false);
  const iconUrls = chainIconUrls(chainName);

  return (
    <Box className="font-montserrat flex w-full flex-col rounded-lg border-2 border-neutral-800 bg-black p-2 shadow-lg ">
      <img
        src={imageUrl}
        alt="Partner"
        className="mx-auto block h-[185px] rounded-t-lg object-contain"
      />
      <Typography size="lg" weight="bold" className="mt-2">
        {header}
      </Typography>
      <Typography weight="semibold" className="mt-1">
        Deadline: {deadline || '-'}
      </Typography>

      {contentLines.map((line, index) => (
        <Typography key={index} size="sm" className="mt-2">
          {line}
        </Typography>
      ))}
      <Box className="flex items-center">
        {canRevealPromo ? (
          <Button onClick={() => setShowPromoCode(!showPromoCode)}>
            <Typography
              size="sm"
              color="turquoise"
              className="mt-2 cursor-pointer"
            >
              reveal promo code
            </Typography>
          </Button>
        ) : (
          <Typography size="sm" color="turquoise" className="mt-2">
            Stratosphere membership required to reveal promo code.
          </Typography>
        )}
        {showPromoCode && (
          <Typography size="sm" className="text-turquoise-500 ml-2 mt-2">
            {promoCode}
          </Typography>
        )}
      </Box>
      {partnerSiteUrl ? (
        <Link href={partnerSiteUrl} passHref>
          <span
            className="text-turquoise-500 mt-1 flex items-center hover:underline"
            rel="noopener noreferrer"
          >
            <Typography color="turquoise" size="sm">
              view partners page
            </Typography>
            <OpenNewIcon className="ml-1 h-4 w-4" />
          </span>
        </Link>
      ) : (
        <span
          className="text-turquoise-500 mt-1 flex items-center hover:underline"
          rel="noopener noreferrer"
        >
          <Typography color="turquoise" size="sm">
            view partners page (Unavailable)
          </Typography>
        </span>
      )}
      <Box className="mt-1 flex items-center justify-between">
        <Typography weight="semibold" size="base">
          Level
        </Typography>
        {levelIndicator}
      </Box>
      <Box className="mt-2 flex items-center justify-between">
        <Typography weight="semibold" size="base" color="light">
          Chain(s):
        </Typography>
        <div className="flex items-center gap-x-1">
          {iconUrls.map((iconUrl, index) => (
            <img
              key={index}
              src={iconUrl}
              alt={`Chain Icon ${index}`}
              className="h-6 w-6"
            />
          ))}
        </div>
      </Box>
    </Box>
  );
};

interface StratospherePartnerViewProps {
  children: React.ReactNode;
  onPartnerSelect: (name: string) => void;
  onChainSelect: (name: string) => void;
  onTierSelect: (tier: string) => void;
  resetFilters: () => void;
  partnerNames: string[];
  chains: string[];
  tiers: string[];
  resetKey: number;
}

export const StratospherePartnerView: React.FC<
  StratospherePartnerViewProps
> = ({
  children,
  onChainSelect,
  onPartnerSelect,
  onTierSelect,
  partnerNames = [],
  resetFilters,
  resetKey,
}) => {
  const tierOptions = [
    { label: 'All', value: 'Unknown' },
    ...validTiers.map((tier) => ({ label: tier, value: tier })),
  ];

  return (
    <Container
      stack="column"
      className="fullWidth font-montserrat gap-4 p-4 lg:gap-[30px] lg:p-[30px]"
      fullWidth
    >
      <Box
        bg="gradient"
        className="hidden items-center rounded-[32px] p-5 sm:flex lg:p-8"
      >
        <DoubleRingIcon className="mr-4 flex-shrink-0" />
        <Typography className="text-4xl leading-[36px] lg:text-[40px]">
          <Span font="manverse">Stratosphere</Span>
          <Span font="manverseOutline"> Partners</Span>
        </Typography>
      </Box>
      <Box className="mx-auto flex w-full max-w-full flex-col overflow-hidden rounded-lg bg-black p-4 lg:p-8">
        <Box className="mb-16 flex items-center justify-between">
          <Typography size="2xl" weight="semibold">
            Our Partners
          </Typography>
          <Box className="flex items-center gap-2 sm:hidden">
            <Select
              icon={<ChevronDownIcon className="h-4 w-4 scale-150" />}
              options={validTiers.map((tier) => ({ label: tier, value: tier }))}
              onSelect={(value) => onTierSelect(String(value))}
            />
            <Select
              icon={<FunnelIcon />}
              options={validChains.map((chain) => ({
                label: chain,
                value: chain,
              }))}
              onSelect={(value) => onChainSelect(String(value))}
            />
          </Box>

          <Box className="hidden items-center space-x-2 sm:flex">
            <Select
              label="all categories"
              icon={<ChevronDownIcon className="scale-150" />}
              options={tierOptions}
              key={`tier-select-${resetKey}`}
              onSelect={(value) => onTierSelect(String(value))}
              className="w-40"
            />
            <Select
              label="chain"
              icon={<ChevronDownIcon className="scale-150" />}
              options={validChains.map((chain) => ({
                label: chain,
                value: chain,
              }))}
              key={`chain-select-${resetKey}`}
              onSelect={(value) => onChainSelect(String(value))}
            />
            <Select
              label="all partners"
              icon={<ChevronDownIcon className="scale-150" />}
              options={partnerNames.map((name) => ({
                label: name,
                value: name,
              }))}
              key={`partner-select-${resetKey}`}
              onSelect={(value) => onPartnerSelect(String(value))}
              className="w-40"
            />
            <Button
              onClick={resetFilters}
              className="rounded bg-neutral-900 px-4 py-2 text-white"
            >
              reset
            </Button>
          </Box>
        </Box>
        <Box className="grid grid-cols-1 gap-7 sm:grid-cols-2 lg:grid-cols-3">
          {children}
        </Box>
      </Box>
    </Container>
  );
};
