/**
 *
 * @param args Comma separated items
 * @returns {false} if any element is false, else true
 */
export const areAllTruthy = <
  T extends Array<boolean | string | null | undefined | number> = any[],
>(
  ...args: T
) => {
  for (const arg of args) {
    if (!Boolean(arg)) return false;
  }
  return true;
};
