import NetworkModal from './NetworkModal';
import { NETWORK_ICON } from './networks';
import { IconButton } from '@vapordex/uikit';
import { Box } from '@vaporfi/uikit';
import useActiveWagmi from 'hooks/useActiveWagmi';
import Image from 'next/legacy/image';
import { useModal } from '@vaporfi/hooks';
type Props = {
  color?: string;
  mr?: string;
};

const NetworkSelector = ({ mr = '8px' }: Props) => {
  const { account, chainId } = useActiveWagmi();
  const [onPresentSettingsModal] = useModal(<NetworkModal />);

  if (account) {
    return (
      <Box>
        <IconButton
          data-testid="network-selector-btn"
          onClick={onPresentSettingsModal}
          variant="text"
          scale="sm"
          mr={mr}
          id="open-network-dialog-button"
          name="open network dialog button"
        >
          <Image
            data-testid="network-selector-icon"
            src={NETWORK_ICON[chainId]}
            alt="Switch Network"
            width={24}
            height={24}
          />
        </IconButton>
      </Box>
    );
  }
};

export default NetworkSelector;
