import React from 'react';
import { Typography } from '@vaporfi/uikit';
import { formatNumberForDisplay } from '@vaporfi/utils';

interface SeasonProps {
  account: string;
  isLoadingUserSpecific: boolean;
  seasonData: {
    dates: { startTimestamp: number; endTimestamp: number };
    participants: number;
    seasonId: string;
    totalVAPE: string;
    totalVPND: string;
    yourPoints: string | null;
    yourRank: string | null;
    yourVAPE: string | null;
    yourVPND: string | null;
  };
  mobileInfoItem: (
    label: string,
    value: string | JSX.Element,
    additionalStyles?: string,
  ) => JSX.Element;
}

export const Season: React.FC<SeasonProps> = ({
  account,
  isLoadingUserSpecific,
  mobileInfoItem,
  seasonData,
}) => {
  const placeholder = '-';
  const {
    dates,
    participants,
    seasonId,
    totalVAPE,
    totalVPND,
    yourPoints,
    yourRank,
    yourVAPE,
    yourVPND,
  } = seasonData;

  const displayVPNDValue = (): string | JSX.Element => {
    if (!account) return placeholder;
    if (yourVPND === 'Loading...') return 'Loading...';
    if (yourVPND === null) return placeholder;
    if (yourVPND === '0')
      return (
        <Typography size="base" color="light">
          Not Participated
        </Typography>
      );
    return formatNumberForDisplay(BigInt(yourVPND));
  };

  const displayValueOrPlaceholder = (value: string | null): string => {
    if (value === null) return placeholder;
    return value === '0' ? placeholder : formatNumberForDisplay(BigInt(value));
  };

  const displayUserValueOrLoading = (value: string | null): string => {
    if (isLoadingUserSpecific) return 'Loading...';
    if (value === null) return placeholder;
    return value === '0' ? placeholder : formatNumberForDisplay(BigInt(value));
  };

  const gridColumns = account
    ? 'grid-cols-1 sm:grid-cols-4 md:grid-cols-8'
    : 'grid-cols-1 sm:grid-cols-4';

  return (
    <div
      style={{ borderBottom: '2px solid #2D3748' }}
      className={`grid ${gridColumns}`}
    >
      {mobileInfoItem('Season', seasonId, 'font-bold')}
      {mobileInfoItem(
        'Dates',
        dates ? (
          <span className="w-60 whitespace-nowrap text-center">
            {new Date(dates.startTimestamp * 1000).toLocaleDateString(
              undefined,
              { day: '2-digit', month: '2-digit', year: '2-digit' },
            )}{' '}
            -{' '}
            {new Date(dates.endTimestamp * 1000).toLocaleDateString(undefined, {
              day: '2-digit',
              month: '2-digit',
              year: '2-digit',
            })}
          </span>
        ) : (
          'Loading...'
        ),
      )}
      {mobileInfoItem('Participants', participants?.toString() ?? 'Loading...')}
      {account && (
        <>
          {mobileInfoItem('Your VPND', displayVPNDValue())}
          {mobileInfoItem('Your VAPE', displayUserValueOrLoading(yourVAPE))}
          {mobileInfoItem('Your Points', displayUserValueOrLoading(yourPoints))}
          {mobileInfoItem(
            'Your Rank',
            isLoadingUserSpecific ? 'Loading...' : yourRank ?? placeholder,
          )}
        </>
      )}
      {mobileInfoItem(
        'Total VPND Locked',
        displayValueOrPlaceholder(totalVPND),
      )}
      {mobileInfoItem(
        'Total VAPE Distributed',
        displayValueOrPlaceholder(totalVAPE),
      )}

      <div className="hidden py-2 text-center sm:block">
        {dates ? (
          <span className="w-60 whitespace-nowrap text-center">
            <Typography size="base" color="light">
              {new Date(dates.startTimestamp * 1000).toLocaleDateString(
                undefined,
                { day: '2-digit', month: '2-digit', year: '2-digit' },
              )}{' '}
              -{' '}
              {new Date(dates.endTimestamp * 1000).toLocaleDateString(
                undefined,
                {
                  day: '2-digit',
                  month: '2-digit',
                  year: '2-digit',
                },
              )}
            </Typography>
          </span>
        ) : (
          'Loading...'
        )}
      </div>
      <div className="hidden py-2 text-center sm:block">
        <Typography size="lg" color="light">
          {participants?.toString() ?? 'Loading...'}
        </Typography>
      </div>

      {account && (
        <>
          <div className="hidden py-2 text-center sm:block">
            <Typography size="lg" color="light">
              {displayVPNDValue()}
            </Typography>
          </div>
          <div className="hidden py-2 text-center sm:block">
            <Typography size="lg" color="light">
              {displayUserValueOrLoading(yourVAPE)}
            </Typography>
          </div>
          <div className="hidden py-2 text-center sm:block">
            <Typography size="lg" color="light">
              {displayUserValueOrLoading(yourPoints)}
            </Typography>
          </div>
          <div className="hidden py-2 text-center sm:block">
            <Typography size="lg" color="light">
              {isLoadingUserSpecific ? 'Loading...' : yourRank ?? placeholder}
            </Typography>
          </div>
        </>
      )}

      <div className="hidden py-2 text-center sm:block">
        <Typography size="lg" color="light">
          {displayValueOrPlaceholder(totalVPND)}
        </Typography>
      </div>
      <div className="hidden py-2 text-center sm:block">
        <Typography size="lg" color="light">
          {displayValueOrPlaceholder(totalVAPE)}
        </Typography>
      </div>
    </div>
  );
};
