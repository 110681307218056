import { useCallback } from 'react';

export const useCommify = () => {
  'use client';
  return useCallback(
    (
      value: string | number,
      { prefix = '', suffix = '' } = {} as { prefix?: string; suffix?: string },
    ) => {
      if (!Boolean(value) || Number.isNaN(+value)) return '-';
      const commified = commify(value);

      return `${prefix}${commified}${suffix}`;
    },
    [],
  );
};

export function commify(value: string | number): string {
  if (!value) return '';

  const comps = String(value).split('.');

  if (
    comps.length > 2 ||
    !/^-?\d*$/.test(comps[0]) ||
    (comps[1] && !/^\d*$/.test(comps[1])) ||
    value === '.' ||
    value === '-.'
  ) {
    throw new Error(`invalid value', 'value', ${value}`);
  }
  let whole = comps[0];

  let negative = '';
  if (whole.slice(0, 1) === '-') {
    negative = '-';
    whole = whole.slice(1);
  }
  while (whole.slice(0, 1) === '0') {
    whole = whole.slice(1);
  }
  if (whole === '') {
    whole = '0';
  }

  let suffix = '';
  if (comps.length === 2) {
    suffix = '.' + (comps[1] || '0');
  }
  while (suffix.length > 2 && suffix.at(-1) === '0') {
    suffix = suffix.slice(0, Math.max(0, suffix.length - 1));
  }

  const formatted = [];
  while (whole.length > 0) {
    if (whole.length <= 3) {
      formatted.unshift(whole);
      break;
    } else {
      const index = whole.length - 3;
      formatted.unshift(whole.slice(Math.max(0, index)));
      whole = whole.slice(0, Math.max(0, index));
    }
  }
  const commified = negative + formatted.join(',') + suffix;
  return commified === '0.0' ? '0' : commified;
}
