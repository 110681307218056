import { Spaces } from '../../types';
import {
  Accordion as RadixAccordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '../Accordion';

const Accordion = ({
  content,
}: {
  content: { text: string; heading: string }[];
  space?: Spaces;
}) => {
  return (
    <RadixAccordion type="multiple" className="flex flex-col gap-4">
      {content.map(({ heading, text }, index) => {
        const key = `${heading}-${index}`;
        return (
          <AccordionItem key={key} value={index?.toString()}>
            <AccordionTrigger>{heading}</AccordionTrigger>
            <AccordionContent>{text}</AccordionContent>
          </AccordionItem>
        );
      })}
    </RadixAccordion>
  );
};
export { Accordion };
