import { MulticallABI } from '@config/abi/Multicall';
import { getMulticallContract } from '@/utils/contractHelpers';
import {
  AccessList,
  Address,
  BlockTag,
  GetContractReturnType,
  WalletClient,
  decodeFunctionResult,
  encodeFunctionData,
} from 'viem';
import { PublicClient } from 'wagmi';

export interface Call {
  address: string; // Address of the contract
  name: string; // Function name on the contract (example: balanceOf)
  params?: any[]; // Function params
}

export type CallOverrides = {
  blockTag?: BlockTag;
  from?: string | Promise<string>;
  value?: bigint | Promise<bigint>;
  gasLimit?: bigint | Promise<bigint>;
  gasPrice?: bigint | Promise<bigint>;
  maxFeePerGas?: bigint | Promise<bigint>;
  maxPriorityFeePerGas?: bigint | Promise<bigint>;
  nonce?: bigint | Promise<bigint>;
  type?: number;
  accessList?: AccessList;
  customData?: Record<string, any>;
  ccipReadEnabled?: boolean;
};
export interface MulticallOptions extends CallOverrides {
  requireSuccess?: boolean;
}

const multicall = async <T = any>(abi: any[], calls: Call[]): Promise<T> => {
  const multi = getMulticallContract() as unknown as GetContractReturnType<
    typeof MulticallABI,
    PublicClient,
    WalletClient
  >;

  const calldata = calls.map((call) => ({
    callData: encodeFunctionData({
      abi,
      args: call.params,
      functionName: call.name,
    }),
    target: call.address.toLowerCase() as Address,
  }));
  const [, returnData] = await multi.read.aggregate([calldata]);

  const res = returnData.map((call, i) =>
    decodeFunctionResult({ abi, data: call, functionName: calls[i].name }),
  );

  return res as any;
};

/**
 * Multicall V2 uses the new "tryAggregate" function. It is different in 2 ways
 *
 * 1. If "requireSuccess" is false multicall will not bail out if one of the calls fails
 * 2. The return includes a boolean whether the call was successful e.g. [wasSuccessful, callResult]
 */
export const multicallv2 = async <T = any>(
  abi: any[],
  calls: Call[],
  options?: MulticallOptions,
): Promise<T> => {
  const { requireSuccess = true, ...overrides } = options || {};
  const multi = getMulticallContract() as unknown as GetContractReturnType<
    typeof MulticallABI,
    PublicClient,
    WalletClient
  >;

  const calldata = calls.map((call) => ({
    callData: encodeFunctionData({
      abi,
      args: call.params,
      functionName: call.name,
    }),
    target: call.address.toLowerCase() as Address,
  }));

  const returnData = await multi.read.tryAggregate(
    [requireSuccess, calldata],
    overrides,
  );

  const res = returnData.map((call, i) => {
    const { returnData, success } = call;
    return success
      ? decodeFunctionResult({
          abi,
          data: returnData,
          functionName: calls[i].name,
        })
      : null;
  });

  return res as any;
};

export default multicall;
