import {
  DEFAULT_DEADLINE_FROM_NOW,
  INITIAL_ALLOWED_SLIPPAGE,
} from '../../config/constants';
import { updateVersion } from '../global/actions';
import { GAS_PRICE_GWEI } from '../types';
import {
  addSerializedPair,
  addSerializedToken,
  addWatchlistPool,
  addWatchlistToken,
  FarmStakedOnly,
  removeSerializedPair,
  removeSerializedToken,
  SerializedPair,
  muteAudio,
  unmuteAudio,
  updateGasPrice,
  updateUserDeadline,
  updateUserExpertMode,
  updateUserFarmStakedOnly,
  updareUserHideClosedPositions,
  updateUserFarmsViewMode,
  updateUserPoolStakedOnly,
  updateUserPoolsViewMode,
  updateUserSingleHopOnly,
  updateUserSlippageTolerance,
  ViewMode,
  updateUserPredictionAcceptedRisk,
  updateUserPredictionChartDisclaimerShow,
  updateUserPredictionChainlinkChartDisclaimerShow,
  updateUserUsernameVisibility,
  updateUserExpertModeAcknowledgementShow,
  hidePhishingWarningBanner,
  setIsExchangeChartDisplayed,
  setChartViewMode,
  ChartViewMode,
  setSubgraphHealthIndicatorDisplayed,
  updateUserLimitOrderAcceptedWarning,
  setZapDisabled,
  updateUserIsStratosphereMember,
} from './actions';
import { createReducer } from '@reduxjs/toolkit';
import { SerializedToken } from 'config/constants/types';

const currentTimestamp = () => Date.now();

export interface UserState {
  // the timestamp of the last updateVersion action
  lastUpdateVersionTimestamp?: number;

  userExpertMode: boolean;

  // only allow swaps on direct pairs
  userSingleHopOnly: boolean;

  // user defined slippage tolerance in bips, used in all txns
  userSlippageTolerance: number;
  // hides closed (inactive) positions across the app
  userHideClosedPositions: boolean;
  // deadline set by user in minutes, used in all txns
  userDeadline: number;

  tokens: {
    [chainId: number]: {
      [address: string]: SerializedToken;
    };
  };

  pairs: {
    [chainId: number]: {
      // keyed by token0Address:token1Address
      [key: string]: SerializedPair;
    };
  };

  timestamp: number;
  audioPlay: boolean;
  isExchangeChartDisplayed: boolean;
  isSubgraphHealthIndicatorDisplayed: boolean;
  userChartViewMode: ChartViewMode;
  userFarmStakedOnly: FarmStakedOnly;
  userPoolStakedOnly: boolean;
  userPoolsViewMode: ViewMode;
  userFarmsViewMode: ViewMode;
  userPredictionAcceptedRisk: boolean;
  userLimitOrderAcceptedWarning: boolean;
  userPredictionChartDisclaimerShow: boolean;
  userPredictionChainlinkChartDisclaimerShow: boolean;
  userExpertModeAcknowledgementShow: boolean;
  userUsernameVisibility: boolean;
  userZapDisabled: boolean;
  userIsStratosphereMember: boolean;
  gasPrice: string;
  watchlistTokens: string[];
  watchlistPools: string[];
  hideTimestampPhishingWarningBanner: number;
}

function pairKey(token0Address: string, token1Address: string) {
  return `${token0Address};${token1Address}`;
}

export const initialState: UserState = {
  audioPlay: true,
  gasPrice: GAS_PRICE_GWEI.default,
  hideTimestampPhishingWarningBanner: null,
  isExchangeChartDisplayed: true,
  isSubgraphHealthIndicatorDisplayed: false,
  pairs: {},
  timestamp: currentTimestamp(),
  tokens: {},
  userChartViewMode: ChartViewMode.BASIC,
  userDeadline: DEFAULT_DEADLINE_FROM_NOW,
  userExpertMode: false,
  userExpertModeAcknowledgementShow: true,
  userFarmStakedOnly: FarmStakedOnly.ON_FINISHED,
  userFarmsViewMode: ViewMode.TABLE,
  userHideClosedPositions: false,
  userIsStratosphereMember: false,
  userLimitOrderAcceptedWarning: false,
  userPoolStakedOnly: false,
  userPoolsViewMode: ViewMode.TABLE,
  userPredictionAcceptedRisk: false,
  userPredictionChainlinkChartDisclaimerShow: true,
  userPredictionChartDisclaimerShow: true,
  userSingleHopOnly: false,
  userSlippageTolerance: INITIAL_ALLOWED_SLIPPAGE,
  userUsernameVisibility: false,
  userZapDisabled: false,
  watchlistPools: [],
  watchlistTokens: [],
};

export default createReducer(initialState, (builder) =>
  builder
    .addCase(
      updateUserIsStratosphereMember,
      (state, { payload: { isStatophereMember } }) => {
        state.userIsStratosphereMember = isStatophereMember;
      },
    )
    .addCase(updateVersion, (state) => {
      // slippage isnt being tracked in local storage, reset to default
      // noinspection SuspiciousTypeOfGuard
      if (typeof state.userSlippageTolerance !== 'number') {
        state.userSlippageTolerance = INITIAL_ALLOWED_SLIPPAGE;
      }

      // deadline isnt being tracked in local storage, reset to default
      // noinspection SuspiciousTypeOfGuard
      if (typeof state.userDeadline !== 'number') {
        state.userDeadline = DEFAULT_DEADLINE_FROM_NOW;
      }

      state.lastUpdateVersionTimestamp = currentTimestamp();
    })
    .addCase(updareUserHideClosedPositions, (state, action) => {
      state.userHideClosedPositions = action.payload.userHideClosedPositions;
    })
    .addCase(updateUserExpertMode, (state, action) => {
      state.userExpertMode = action.payload.userExpertMode;
      state.timestamp = currentTimestamp();
    })
    .addCase(updateUserSlippageTolerance, (state, action) => {
      state.userSlippageTolerance = action.payload.userSlippageTolerance;
      state.timestamp = currentTimestamp();
    })
    .addCase(updateUserDeadline, (state, action) => {
      state.userDeadline = action.payload.userDeadline;
      state.timestamp = currentTimestamp();
    })
    .addCase(updateUserSingleHopOnly, (state, action) => {
      state.userSingleHopOnly = action.payload.userSingleHopOnly;
    })
    .addCase(addSerializedToken, (state, { payload: { serializedToken } }) => {
      if (!state.tokens) {
        state.tokens = {};
      }
      state.tokens[serializedToken.chainId] =
        state.tokens[serializedToken.chainId] || {};
      state.tokens[serializedToken.chainId][serializedToken.address] =
        serializedToken;
      state.timestamp = currentTimestamp();
    })
    .addCase(
      removeSerializedToken,
      (state, { payload: { address, chainId } }) => {
        if (!state.tokens) {
          state.tokens = {};
        }
        state.tokens[chainId] = state.tokens[chainId] || {};
        delete state.tokens[chainId][address];
        state.timestamp = currentTimestamp();
      },
    )
    .addCase(addSerializedPair, (state, { payload: { serializedPair } }) => {
      if (
        serializedPair.token0.chainId === serializedPair.token1.chainId &&
        serializedPair.token0.address !== serializedPair.token1.address
      ) {
        const { address, chainId } = serializedPair.token0;
        state.pairs[chainId] = state.pairs[chainId] || {};
        state.pairs[chainId][pairKey(address, serializedPair.token1.address)] =
          serializedPair;
      }
      state.timestamp = currentTimestamp();
    })
    .addCase(
      removeSerializedPair,
      (state, { payload: { chainId, tokenAAddress, tokenBAddress } }) => {
        if (state.pairs[chainId]) {
          // just delete both keys if either exists
          delete state.pairs[chainId][pairKey(tokenAAddress, tokenBAddress)];
          delete state.pairs[chainId][pairKey(tokenBAddress, tokenAAddress)];
        }
        state.timestamp = currentTimestamp();
      },
    )
    .addCase(muteAudio, (state) => {
      state.audioPlay = false;
    })
    .addCase(unmuteAudio, (state) => {
      state.audioPlay = true;
    })
    .addCase(
      updateUserFarmStakedOnly,
      (state, { payload: { userFarmStakedOnly } }) => {
        state.userFarmStakedOnly = userFarmStakedOnly;
      },
    )
    .addCase(
      updateUserPoolStakedOnly,
      (state, { payload: { userPoolStakedOnly } }) => {
        state.userPoolStakedOnly = userPoolStakedOnly;
      },
    )
    .addCase(
      updateUserPoolsViewMode,
      (state, { payload: { userPoolsViewMode } }) => {
        state.userPoolsViewMode = userPoolsViewMode;
      },
    )
    .addCase(
      updateUserFarmsViewMode,
      (state, { payload: { userFarmsViewMode } }) => {
        state.userFarmsViewMode = userFarmsViewMode;
      },
    )
    .addCase(
      updateUserPredictionAcceptedRisk,
      (state, { payload: { userAcceptedRisk } }) => {
        state.userPredictionAcceptedRisk = userAcceptedRisk;
      },
    )
    .addCase(
      updateUserLimitOrderAcceptedWarning,
      (state, { payload: { userAcceptedRisk } }) => {
        state.userLimitOrderAcceptedWarning = userAcceptedRisk;
      },
    )
    .addCase(
      updateUserPredictionChartDisclaimerShow,
      (state, { payload: { userShowDisclaimer } }) => {
        state.userPredictionChartDisclaimerShow = userShowDisclaimer;
      },
    )
    .addCase(
      updateUserPredictionChainlinkChartDisclaimerShow,
      (state, { payload: { userShowDisclaimer } }) => {
        state.userPredictionChainlinkChartDisclaimerShow = userShowDisclaimer;
      },
    )
    .addCase(
      updateUserExpertModeAcknowledgementShow,
      (state, { payload: { userExpertModeAcknowledgementShow } }) => {
        state.userExpertModeAcknowledgementShow =
          userExpertModeAcknowledgementShow;
      },
    )
    .addCase(
      updateUserUsernameVisibility,
      (state, { payload: { userUsernameVisibility } }) => {
        state.userUsernameVisibility = userUsernameVisibility;
      },
    )
    .addCase(updateGasPrice, (state, action) => {
      state.gasPrice = action.payload.gasPrice;
    })
    .addCase(addWatchlistToken, (state, { payload: { address } }) => {
      // state.watchlistTokens can be undefined for pre-loaded localstorage user state
      const tokenWatchlist = state.watchlistTokens ?? [];
      if (tokenWatchlist.includes(address)) {
        // Remove token from watchlist
        const newTokens = state.watchlistTokens.filter((x) => x !== address);
        state.watchlistTokens = newTokens;
      } else {
        state.watchlistTokens = [...tokenWatchlist, address];
      }
    })
    .addCase(addWatchlistPool, (state, { payload: { address } }) => {
      // state.watchlistPools can be undefined for pre-loaded localstorage user state
      const poolsWatchlist = state.watchlistPools ?? [];
      if (poolsWatchlist.includes(address)) {
        // Remove pool from watchlist
        const newPools = state.watchlistPools.filter((x) => x !== address);
        state.watchlistPools = newPools;
      } else {
        state.watchlistPools = [...poolsWatchlist, address];
      }
    })
    .addCase(hidePhishingWarningBanner, (state) => {
      state.hideTimestampPhishingWarningBanner = currentTimestamp();
    })
    .addCase(setIsExchangeChartDisplayed, (state, { payload }) => {
      state.isExchangeChartDisplayed = payload;
    })
    .addCase(setChartViewMode, (state, { payload }) => {
      state.userChartViewMode = payload;
    })
    .addCase(setZapDisabled, (state, { payload }) => {
      state.userZapDisabled = payload;
    })
    .addCase(setSubgraphHealthIndicatorDisplayed, (state, { payload }) => {
      state.isSubgraphHealthIndicatorDisplayed = payload;
    }),
);
