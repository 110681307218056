import {
  AlertCard,
  Box,
  Button,
  Container,
  ContainerProps,
  Grid,
  GridProps,
  Modal,
  Typography,
} from '@vaporfi/uikit';
import { VariantProps, tv } from 'tailwind-variants';
import {
  MINING_PASS_COST,
  MINING_PASS_LEVEL,
  MaxDeposit,
} from '@vapordex/liquid-mining-sdk';
import { numberToAbbreviation } from '@vaporfi/utils';
import { forwardRef, useCallback } from 'react';

const miningPassStyles = tv({
  slots: {
    innerContainer: 'gap-4 p-4 lg:py-4 lg:px-8',
    levels:
      'gap-2 text-center bg-no-repeat justify-center h-[120.73px] w-[73.01px]',
    levelsContainer: 'gap-4 w-full bg-no-repeat grid-cols-2 lg:grid-cols-5',
  },
  variants: {
    isCurrentLevel: {
      true: {
        innerContainer: 'lg:border-[1px] lg:border-solid lg:border-blue-500',
      },
    },
  },
});

export interface MiningPassTopSectionProps {
  isLevelGreaterThanZero: boolean;
  currentLevel: MINING_PASS_LEVEL;
}

export const MiningPassTopSection = ({
  currentLevel,
  isLevelGreaterThanZero,
}: MiningPassTopSectionProps) => {
  return (
    <>
      <Container className="flex flex-col gap-7 lg:hidden">
        {[
          'You earn 1 point per day per VPND locked, up to your Mining Pass limit.',
          'Upgrade your Mining Pass to increase your max points per day.',
        ].map((text, i) => (
          <Typography key={`mining-pass-mobile-${i}`}>{text}</Typography>
        ))}
        <AlertCard
          variant="stratosphere"
          showIcon={false}
          stack="column"
          itemsCenter
        >
          <CurrentMiningPassLevel
            {...{
              currentLevel,
              isLevelGreaterThanZero,
            }}
          />
        </AlertCard>
      </Container>
      <Container
        stack="row"
        itemsCenter
        hideInMobile
        fullWidth
        className="h-48 gap-0"
      >
        <CurrentMiningPassLevel {...{ currentLevel, isLevelGreaterThanZero }} />
        <Box
          bg="gradient"
          className="flex h-full gap-8 overflow-hidden p-8 pt-6"
        >
          <Typography
            size="lg"
            weight="semibold"
            className="overflow-hidden text-ellipsis whitespace-normal"
          >
            Your total of points is determined by locking your VPND, earning one
            point per locked VPND per remaining days in the season.
          </Typography>
          <Typography
            size="lg"
            weight="semibold"
            className="overflow-hidden text-ellipsis whitespace-normal"
          >
            Upgrade your mining pass to increase the VPND limit you can lock,
            adding more VPNDs to boost your points. Valid for the current
            season.{' '}
          </Typography>
        </Box>
      </Container>
    </>
  );
};

export const MiningPassModal = ({
  MiningPassLevelsProxy,
  currentLevel,
  mutate,
  onDismiss,
}: {
  currentLevel: number;
  onDismiss?: () => void;
  mutate?: () => void;
  /**
   * @dev Hitesh
   * TODO: Remove the need for this proxy and use MiningPassLevels from this file directly.
   * @major To achieve this we need to migrate viem, wagmi, Redux states, configs, user/tx hooks & states etc.
   */
  MiningPassLevelsProxy: any;
}) => {
  return (
    <Modal
      title="Mining Passes"
      showCloseIcon
      onDismiss={onDismiss}
      className="max-w-[827px]"
      body={
        <Container stack="column" className="gap-7">
          <MiningPassTopSection
            {...{
              currentLevel,
              isLevelGreaterThanZero: currentLevel > MINING_PASS_LEVEL.ZERO,
            }}
          />
          {Boolean(MiningPassLevelsProxy) && (
            <MiningPassLevelsProxy {...{ currentLevel, mutate, onDismiss }} />
          )}
        </Container>
      }
    />
  );
};

export const CurrentMiningPassLevel = ({
  currentLevel,
  isLevelGreaterThanZero,
  ...rest
}: MiningPassTopSectionProps) => {
  return (
    <MiningPassInnerContainer {...rest} isCurrentLevel>
      <MiningPassLevel stack="column" itemsCenter>
        <Typography size="base" weight="semibold" color="blue">
          {isLevelGreaterThanZero ? 'LVL' : 'FREE'}
        </Typography>
        {isLevelGreaterThanZero ? (
          <>
            <Button color="blue" className="h-8 w-8 rounded-[32px]">
              <Typography>{currentLevel}</Typography>
            </Button>
            <Typography
              color="light"
              weight="semibold"
              size="xs"
              className="text-center"
            >
              {MaxDeposit[currentLevel] === ''
                ? 'no'
                : numberToAbbreviation(Number(MaxDeposit[currentLevel]))}
              <br />
              max
            </Typography>
          </>
        ) : (
          <Typography color="light" weight="semibold" size="xs">
            5k
            <br />
            max
          </Typography>
        )}
      </MiningPassLevel>
      <Button disabled size="xs">
        <Typography>Owned</Typography>
      </Button>
    </MiningPassInnerContainer>
  );
};

const MiningPassInnerContainer = forwardRef<
  HTMLDivElement,
  ContainerProps & VariantProps<typeof miningPassStyles>
>(({ className, isCurrentLevel, ...rest }, ref) => (
  <Container
    bg="neutral"
    stack="column"
    className={miningPassStyles().innerContainer({
      class: className,
      isCurrentLevel,
    })}
    itemsCenter
    {...rest}
    ref={ref}
  />
));
const MiningPassLevelsContainer = ({
  className,
  style,
  ...rest
}: GridProps) => (
  <Grid
    isContainer
    style={{ ...style, backgroundImage: 'url(/mining-pass-level.png)' }}
    className={miningPassStyles().levelsContainer({ class: className })}
    {...rest}
  />
);
const MiningPassLevel = forwardRef<HTMLDivElement, ContainerProps>(
  ({ className, style, ...rest }, ref) => (
    <Container
      style={{ ...style, backgroundImage: 'url(/mining-pass-level.png)' }}
      className={miningPassStyles().levels({ class: className })}
      {...rest}
      ref={ref}
    />
  ),
);

export const MiningPassLevels = ({
  AlertCards,
  balance,
  currentLevel,
  discountPercentage = 0,
  isPurchasePending,
  onPurchase,
}: {
  currentLevel: MINING_PASS_LEVEL;
  isPurchasePending?: boolean;
  onPurchase?: (level: MINING_PASS_LEVEL) => void;
  AlertCards?: () => React.JSX.Element;
  balance: number;
  discountPercentage?: number;
}) => {
  const getMiningPassPrice = useCallback(
    (level: MINING_PASS_LEVEL) => {
      const defaultPrice = +MINING_PASS_COST[level];
      if (discountPercentage && discountPercentage > 0) {
        return defaultPrice * (1 - discountPercentage / 100);
      }
      return defaultPrice;
    },
    [discountPercentage],
  );
  return (
    <>
      {AlertCards && <AlertCards />}

      <MiningPassLevelsContainer>
        {[...Array.from({ length: 11 }).keys()].map(
          (level: MINING_PASS_LEVEL) => {
            const isPurchaseDisabled =
              isPurchasePending ||
              currentLevel >= level ||
              +MINING_PASS_COST[level] - +MINING_PASS_COST?.[currentLevel] >
                balance;
            return (
              level > 0 && (
                <MiningPassInnerContainer
                  bg="neutral"
                  stack="column"
                  key={`mining-pass-level-${level}`}
                  itemsCenter
                >
                  <MiningPassLevel
                    stack="column"
                    itemsCenter
                    style={{
                      opacity: currentLevel >= level ? 0.5 : 1,
                    }}
                  >
                    <Typography size="base" weight="semibold" color="blue">
                      LVL
                    </Typography>
                    <Button color="blue" className="h-8 w-8 rounded-[32px]">
                      <Typography>{level}</Typography>
                    </Button>
                    <Typography
                      color="light"
                      weight="semibold"
                      size="xs"
                      className="text-center"
                    >
                      {MaxDeposit[level] === ''
                        ? 'no'
                        : numberToAbbreviation(Number(MaxDeposit[level]))}
                      <br />
                      max
                    </Typography>
                  </MiningPassLevel>
                  <Button
                    color="blue"
                    size="xs"
                    id="buy-mining-pass"
                    onClick={
                      isPurchaseDisabled
                        ? () => null
                        : () => onPurchase?.(level)
                    }
                    disabled={isPurchaseDisabled}
                    style={{ opacity: currentLevel >= level ? 0.5 : 1 }}
                  >
                    <Typography>
                      {getMiningPassPrice(level)}&nbsp;USDC
                    </Typography>
                  </Button>
                </MiningPassInnerContainer>
              )
            );
          },
        )}
      </MiningPassLevelsContainer>
    </>
  );
};
export * from './Alerts';
