import { ToastContainer } from '@components/Toast';
import useToast from 'contexts/ToastsContext/useToast';

const ToastListener = () => {
  const { remove, toasts } = useToast();

  const handleRemove = (id: string) => remove(id);

  return <ToastContainer toasts={toasts} onRemove={handleRemove} />;
};

export default ToastListener;
