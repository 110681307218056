import React, { useState } from 'react';
import { useExpertModeManager } from 'state/user/hooks';
import { ExpertModalView } from '@vaporfi/features';

interface ExpertModalProps {
  setShowConfirmExpertModal: (show: boolean) => void;
  setShowExpertModeAcknowledgement: (show: boolean) => void;
  onDismiss?: () => void;
  onBack?: () => void;
}

const ExpertModal: React.FC<ExpertModalProps> = ({
  onBack,
  onDismiss,
  setShowConfirmExpertModal,
  setShowExpertModeAcknowledgement,
}) => {
  const [, toggleExpertMode] = useExpertModeManager();
  const [isRememberChecked, setIsRememberChecked] = useState(false);

  const handleConfirm = () => {
    const confirmWord = window.prompt(
      'Please type the word "confirm" to enable expert mode.',
    );
    if (confirmWord === 'confirm') {
      toggleExpertMode();
      setShowConfirmExpertModal(false);
      if (isRememberChecked) {
        setShowExpertModeAcknowledgement(false);
      }
    }
  };

  const handleCancel = () => {
    setShowConfirmExpertModal(false);
    if (onDismiss) {
      onDismiss();
    }
  };

  const handleRememberChange = () => {
    setIsRememberChecked(!isRememberChecked);
  };

  const handleBack = () => {
    setShowConfirmExpertModal(false);
    if (onBack) {
      onBack();
    }
  };

  return (
    <ExpertModalView
      isRememberChecked={isRememberChecked}
      onRememberChange={handleRememberChange}
      onConfirm={handleConfirm}
      onCancel={handleCancel}
      onBack={handleBack}
    />
  );
};

export default ExpertModal;
