import Box from './Box';
import { FlexProps } from './types';
import styled from 'styled-components';
import { flexbox } from 'styled-system';

const Flex = styled(Box)<FlexProps>`
  display: flex;
  ${flexbox}
`;

export default Flex;
