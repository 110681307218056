'use client';
import {
  Box,
  Button,
  ButtonProps,
  Container,
  DoubleRingIcon,
  Grid,
  GridProps,
  Typography,
} from '@vaporfi/uikit';
import { cn } from '@vaporfi/utils';
import React, { memo, useMemo, useState } from 'react';
import { tv } from 'tailwind-variants';
import { EventDetails, EventStatus, OneTimeEvent } from '../types';
import { oneTimeEvents } from '../constants';

const styles = tv({
  slots: {
    container: 'grid-cols-2 gap-4 md:grid-cols-3 lg:grid-cols-4 lg:gap-[30px]',
    item: 'flex gap-2 rounded-[5px] p-4 items-center',
  },
  variants: {
    isClaimed: {
      false: { item: 'bg-neutral-900' },
      true: { item: 'bg-blue-500' },
    },
  },
});

interface OneTimeEventsProps extends GridProps {
  eventStatus: Partial<Record<OneTimeEvent, EventStatus>>;
}

const eventKeys = Object.keys(oneTimeEvents) as unknown as OneTimeEvent[];

const OneTimeEvents = ({
  className,
  eventStatus,
  ...rest
}: OneTimeEventsProps) => {
  const { container } = styles();

  const [isShowMore, setShowMore] = useState<boolean>(false);

  const sortedEventKeys = useMemo(() => {
    const sortedKeys = eventKeys?.sort((a, b) => {
      if (eventStatus?.[a]?.isClaimed && !eventStatus?.[b]?.isClaimed) {
        return -1;
      }
      if (!eventStatus?.[a]?.isClaimed && eventStatus[b]?.isClaimed) {
        return 1;
      }

      return (
        (eventStatus?.[b]?.progress ?? 0) - (eventStatus?.[a]?.progress ?? 0)
      );
    });
    return isShowMore ? sortedKeys : sortedKeys?.slice(0, 12);
  }, [eventStatus, isShowMore]);

  return (
    <Container
      stack="column"
      bg="black"
      className="gap-4 rounded-[10px] p-4 lg:gap-[30px] lg:p-[30px]"
    >
      <Typography size="3xl" className="font-manverse" weight="semibold">
        Achievements
      </Typography>
      <Grid {...rest} isContainer className={cn(container(), className)}>
        {sortedEventKeys.map((eventKey) => (
          <Item
            key={eventKey}
            {...oneTimeEvents[eventKey]}
            {...eventStatus?.[eventKey]}
          />
        ))}
      </Grid>
      <ShowMoreButton
        onClick={() => setShowMore((prev) => !prev)}
        isShowMore={isShowMore}
      />
    </Container>
  );
};

export default OneTimeEvents;

interface ItemProps extends Omit<GridProps, 'name'>, EventDetails {}

const Item = memo(
  ({ className, isClaimed, name, points, ...rest }: ItemProps) => {
    const { item } = styles({ isClaimed });
    return (
      <Grid className={item()} {...rest}>
        <DoubleRingIcon className="h-8 w-8" />
        <Box className="flex flex-col gap-1">
          <Typography size="sm">{name}</Typography>
          <Typography size="xs">+{points} stratosphere points</Typography>
        </Box>
      </Grid>
    );
  },
);

const ShowMoreButton = memo(
  ({ isShowMore, onClick }: ButtonProps & { isShowMore: boolean }) => {
    return (
      <Box className="flex justify-center">
        <Button size="sm" variant="outline" color="light" onClick={onClick}>
          <Typography>{isShowMore ? 'Show Less' : 'Show More'}</Typography>
        </Button>
      </Box>
    );
  },
);
