import {
  addMulticallListeners,
  errorFetchingMulticallResults,
  fetchingMulticallResults,
  removeMulticallListeners,
  toCallKey,
  updateMulticallResults,
  resetCallListeners,
} from './actions';
import { createReducer } from '@reduxjs/toolkit';

export interface MulticallState {
  callListeners?: {
    // on a per-chain basis
    [chainId: number]: {
      // stores for each call key the listeners' preferences
      [callKey: string]: {
        // stores how many listeners there are per each blocks per fetch preference
        [blocksPerFetch: number]: number;
      };
    };
  };

  callResults: {
    [chainId: number]: {
      [callKey: string]: {
        data?: string | null;
        blockNumber?: number;
        fetchingBlockNumber?: number;
      };
    };
  };
}

const initialState: MulticallState = {
  callResults: {},
};

export default createReducer(initialState, (builder) =>
  builder
    .addCase(
      addMulticallListeners,
      (
        state,
        { payload: { calls, chainId, options: { blocksPerFetch = 1 } = {} } },
      ) => {
        const listeners: MulticallState['callListeners'] =
          state.callListeners ?? (state.callListeners = {});

        listeners[chainId] = listeners[chainId] ?? {};
        for (const call of calls) {
          const callKey = toCallKey(call);
          listeners[chainId][callKey] = listeners[chainId][callKey] ?? {};
          listeners[chainId][callKey][blocksPerFetch] =
            (listeners[chainId][callKey][blocksPerFetch] ?? 0) + 1;
        }
      },
    )
    .addCase(
      removeMulticallListeners,
      (
        state,
        { payload: { calls, chainId, options: { blocksPerFetch = 1 } = {} } },
      ) => {
        const listeners: MulticallState['callListeners'] =
          state.callListeners ?? (state.callListeners = {});

        if (!listeners[chainId]) return;
        for (const call of calls) {
          const callKey = toCallKey(call);
          if (!listeners[chainId][callKey]) continue;
          if (!listeners[chainId][callKey][blocksPerFetch]) continue;

          if (listeners[chainId][callKey][blocksPerFetch] === 1) {
            delete listeners[chainId][callKey][blocksPerFetch];
          } else {
            listeners[chainId][callKey][blocksPerFetch]--;
          }
        }
      },
    )
    .addCase(
      fetchingMulticallResults,
      (state, { payload: { calls, chainId, fetchingBlockNumber } }) => {
        state.callResults[chainId] = state.callResults[chainId] ?? {};
        for (const call of calls) {
          const callKey = toCallKey(call);
          const current = state.callResults[chainId][callKey];
          if (current) {
            if ((current.fetchingBlockNumber ?? 0) >= fetchingBlockNumber)
              continue;
            state.callResults[chainId][callKey].fetchingBlockNumber =
              fetchingBlockNumber;
          } else {
            state.callResults[chainId][callKey] = {
              fetchingBlockNumber,
            };
          }
        }
      },
    )
    .addCase(
      errorFetchingMulticallResults,
      (state, { payload: { calls, chainId, fetchingBlockNumber } }) => {
        state.callResults[chainId] = state.callResults[chainId] ?? {};
        for (const call of calls) {
          const callKey = toCallKey(call);
          const current = state.callResults[chainId][callKey];
          if (!current) continue; // only should be dispatched if we are already fetching
          if (current.fetchingBlockNumber === fetchingBlockNumber) {
            delete current.fetchingBlockNumber;
            current.data = null;
            current.blockNumber = fetchingBlockNumber;
          }
        }
      },
    )
    .addCase(
      updateMulticallResults,
      (state, { payload: { blockNumber, chainId, results } }) => {
        state.callResults[chainId] = state.callResults[chainId] ?? {};
        for (const callKey of Object.keys(results)) {
          const current = state.callResults[chainId][callKey];
          if ((current?.blockNumber ?? 0) > blockNumber) continue;
          state.callResults[chainId][callKey] = {
            blockNumber,
            data: results[callKey],
          };
        }
      },
    )
    .addCase(resetCallListeners, (state, { payload: { chainId } }) => {
      const listeners: MulticallState['callListeners'] =
        state.callListeners ?? (state.callListeners = {});

      if (!listeners[chainId]) return;

      delete listeners[chainId];
    }),
);
