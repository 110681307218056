import { AppState, useAppDispatch } from '../index';
import { checkedTransaction, finalizeTransaction } from './actions';
import { ToastDescriptionWithTx } from '@components/Toast';
import useActiveWagmi from 'hooks/useActiveWagmi';
import useToast from 'contexts/ToastsContext/useToast';
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useCurrentBlock } from 'state/block/hooks';
import { Hash, waitForTransaction } from '@wagmi/core';
import { TransactionReceipt } from 'viem';

export function shouldCheck(
  currentBlock: number,
  tx: { addedTime: number; receipt?: any; lastCheckedBlockNumber?: number },
): boolean {
  if (tx.receipt) return false;
  if (!tx.lastCheckedBlockNumber) return true;
  const blocksSinceCheck = currentBlock - tx.lastCheckedBlockNumber;
  if (blocksSinceCheck < 1) return false;
  const minutesPending = (Date.now() - tx.addedTime) / 1000 / 60;
  if (minutesPending > 60) {
    // every 10 blocks if pending for longer than an hour
    return blocksSinceCheck > 9;
  }
  if (minutesPending > 5) {
    // every 3 blocks if pending more than 5 minutes
    return blocksSinceCheck > 2;
  }
  // otherwise every block
  return true;
}

export default function Updater(): null {
  const { chainId, library } = useActiveWagmi();

  const currentBlock = useCurrentBlock();

  const dispatch = useAppDispatch();
  const state = useSelector<AppState, AppState['transactions']>(
    (s) => s.transactions,
  );

  const transactions = useMemo(
    () => (chainId ? state[chainId] ?? {} : {}),
    [chainId, state],
  );

  const { toastError, toastSuccess } = useToast();

  useEffect(() => {
    if (!chainId || !library || !currentBlock) return;

    for (const hash of Object.keys(transactions).filter((hash) =>
      shouldCheck(currentBlock, transactions[hash]),
    )) {
      waitForTransaction({ chainId, hash: hash as Hash })
        .then((receipt: TransactionReceipt) => {
          if (receipt) {
            dispatch(
              finalizeTransaction({
                chainId,
                hash,
                receipt: {
                  blockHash: receipt.blockHash,
                  blockNumber: +receipt.blockNumber?.toString(),
                  contractAddress: receipt.contractAddress,
                  from: receipt.from,
                  status: receipt.status === 'success' ? 1 : 0,
                  to: receipt.to,
                  transactionHash: receipt.transactionHash,
                  transactionIndex: receipt.transactionIndex,
                },
              }),
            );

            const toast =
              receipt.status === 'success' ? toastSuccess : toastError;
            toast(
              'Transaction receipt',
              <ToastDescriptionWithTx txHash={receipt.transactionHash} />,
            );
          } else {
            dispatch(
              checkedTransaction({
                blockNumber: currentBlock,
                chainId,
                hash,
              }),
            );
          }
        })
        .catch((error) => {
          console.error(`failed to check transaction hash: ${hash}`, error);
        });
    }
  }, [
    chainId,
    library,
    transactions,
    currentBlock,
    dispatch,
    toastSuccess,
    toastError,
  ]);

  return null;
}
