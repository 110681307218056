import { Link, Text } from '@vapordex/uikit';
import { ExplorerDataType, getExplorerLink, getExplorerName } from 'utils';
import truncateHash from 'utils/truncateHash';
import { useNetwork } from 'wagmi';

interface DescriptionWithTxProps {
  description?: string;
  txHash?: string;
}

const DescriptionWithTx: React.FC<
  React.PropsWithChildren<DescriptionWithTxProps>
> = ({ children, txHash }) => {
  const { chain } = useNetwork();

  return (
    <>
      {typeof children === 'string' ? <Text as="p">{children}</Text> : children}
      {txHash && (
        <Link
          external
          href={getExplorerLink(txHash, ExplorerDataType.TRANSACTION, chain)}
        >
          {`View on ${getExplorerName(chain)}`}: {truncateHash(txHash, 8, 0)}
        </Link>
      )}
    </>
  );
};

export default DescriptionWithTx;
