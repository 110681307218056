import { Svg, SvgProps } from './Svg';

export const Refresh = ({
  height = '14',
  version = '1.1',
  viewBox = '0 0 15 15',
  width = '14',
  xmlns = 'http://www.w3.org/2000/svg',
  ...rest
}: SvgProps) => {
  return (
    <Svg {...rest} {...{ height, version, viewBox, width, xmlns }}>
      <path
        d="M13.9436 0.151001C13.4282 0.151001 13.0103 0.568376 13.0103 1.08433V3.24529C11.8465 1.6245 9.95945 0.617668 7.87695 0.617668C5.1207 0.617668 2.70249 2.37758 1.85637 4.9985C1.69776 5.48879 1.96712 6.01583 2.45749 6.17333C2.95012 6.33375 3.47483 6.06215 3.63291 5.57279C4.2282 3.72392 5.93445 2.48433 7.87695 2.48433C9.34987 2.48433 10.6845 3.20038 11.5053 4.351H10.2103C9.69491 4.351 9.27695 4.76838 9.27695 5.28433C9.27695 5.80029 9.69491 6.21767 10.2103 6.21767H13.9436C14.459 6.21767 14.877 5.80029 14.877 5.28433V1.08433C14.877 0.568376 14.4599 0.151001 13.9436 0.151001ZM13.2961 8.131C12.8026 7.97239 12.2788 8.24218 12.1207 8.73154C11.5257 10.5781 9.81945 11.8177 7.87695 11.8177C6.40404 11.8177 5.06966 11.1016 4.24862 9.951H5.54362C6.05899 9.951 6.47695 9.53363 6.47695 9.01767C6.47695 8.50171 6.05899 8.08433 5.54362 8.08433H1.81029C1.29491 8.08433 0.876953 8.50171 0.876953 9.01767V13.2177C0.876953 13.7336 1.29491 14.151 1.81029 14.151C2.32566 14.151 2.74362 13.7336 2.74362 13.2177V11.0567C3.90737 12.6781 5.79445 13.6843 7.8507 13.6843C10.6055 13.6843 13.0249 11.9244 13.8707 9.3035C14.0574 8.8135 13.7861 8.26225 13.2961 8.131Z"
        fill="var(--colors-white)"
      />
    </Svg>
  );
};
