import { Avatar, Box, BoxProps, Typography } from '@vaporfi/uikit';
import { cn } from '@vaporfi/utils';
import Image from 'next/image';

export default function Item({
  className,
  logo,
  name,
  pricePercentChange,
  symbol,
  ...rest
}: BoxProps & { symbol: string; logo: string; pricePercentChange: string }) {
  const percentChangeValue = Number.parseFloat(pricePercentChange);
  const isPositiveChange = percentChangeValue > 0;
  const changeColor = isPositiveChange ? 'green' : 'red';
  const changeText =
    percentChangeValue === 0
      ? '0.00% (24h)'
      : `${Math.abs(percentChangeValue).toFixed(2)}% ${
          isPositiveChange ? 'UP' : 'DOWN'
        } (24h)`;

  return (
    <Box
      {...rest}
      className={cn(
        'border-purple-1 flex min-w-fit cursor-pointer flex-col gap-3 rounded-[9px] border-[2px] bg-white px-5 py-2',
        className,
      )}
    >
      <Box className="flex w-full items-center gap-4">
        {logo ? (
          <Image
            alt={`token-${symbol}-logo`}
            height={32}
            width={32}
            src={logo}
          />
        ) : (
          <Avatar
            fallbackClassName="bg-blue-500"
            size={32}
            alt={`token-${symbol}-logo`}
          />
        )}
        <Typography
          color="purple1"
          weight="semibold"
          className="min-w-fit overflow-hidden text-ellipsis whitespace-nowrap text-[14px] leading-[19px]"
        >
          {`${name} $(${symbol})`}
        </Typography>
      </Box>
      <Typography color={changeColor} weight="semibold">
        {changeText}
      </Typography>
    </Box>
  );
}

export const Skeleton = () => {
  return <Item name="-" symbol="-" logo="" pricePercentChange="0" />;
};
