import { SupportedChainId } from '@/config/constants/chains';
import { mainnetTokens } from '@/config/constants/tokens';
import addresses from '@config/constants/contracts';
import { getChainIdCookie } from '@config/constants/networks';
import { AddressMap } from '@config/constants/types';
import { VaultKey } from '@state/types';
import { Address } from 'wagmi';

export const hasV2VAPE = (...addresses: string[]) =>
  addresses?.includes(
    mainnetTokens[SupportedChainId.AVALANCHE]?.vapeV2?.address?.toLowerCase(),
  );

export function addressesAreEquivalent(a?: string, b?: string) {
  if (!a || !b) return false;
  return a === b || a.toLowerCase() === b.toLowerCase();
}

export const getAddress = (address: AddressMap, chainId?: number): Address => {
  chainId = chainId ?? +getChainIdCookie();
  const addr = address[chainId];
  return addr as Address;
};

export const getRewardsControllerAddress = (chainId?: number) => {
  return getAddress(addresses.rewardsController, chainId);
};

export const getVaporDexAggregatorAdapterAddress = (chainId?: number) => {
  return getAddress(addresses.vaporDexAggregatorAdapter, chainId);
};

export const getSablierLinearAddress = (chainId?: number) => {
  return getAddress(addresses.sablierLinear, chainId);
};

export const getXVAPEReplenishmentPoolAddress = (chainId?: number) => {
  return getAddress(addresses.xvapeReplenishmentPool, chainId);
};
export const getStableVAPEStakingAddress = (chainId?: number) => {
  return getAddress(addresses.xVAPE, chainId);
};

export const getGenesisExchangeAddress = (chainId?: number) => {
  return getAddress(addresses.genesisExchange, chainId);
};

export const getMulticallAddress = (chainId?: number) => {
  return getAddress(addresses.multiCall, chainId);
};

export const getVaultPoolAddress = (vaultKey: VaultKey, chainId?: number) => {
  if (!vaultKey) {
    return null;
  }
  return getAddress(addresses[vaultKey], chainId);
};

export const getLiquidMiningAddresss = (chainId?: number) => {
  return getAddress(addresses.liquidMining, chainId);
};
export const getTokenFactoryAddress = (chainId?: number) => {
  return getAddress(addresses.tokenFactory, chainId);
};
export const getZapAddress = (chainId?: number) => {
  return getAddress(addresses.zap, chainId);
};

export const getAggregatorRouterAddress = (chainId?: number) => {
  return getAddress(addresses.aggregatorRouter, chainId);
};

export const getAggregatorRouterV2Address = (chainId?: number) => {
  return getAddress(addresses.aggregatorRouterV2, chainId);
};

export const getNodeControllerAddress = (chainId?: number) => {
  return getAddress(addresses.nodeController, chainId);
};

export const getVaporNodesDiamondAddress = (chainId?: number) => {
  return getAddress(addresses.vaporNodesDiamond, chainId);
};

export const getVAPETokenAddress = (chainId?: number) => {
  return getAddress(addresses.vapeToken, chainId);
};

export const getOldVAPEAddress = (chainId?: number) => {
  return getAddress(addresses.oldVAPE, chainId);
};

export const getStratosphereAddress = (chainId?: number) => {
  return getAddress(addresses.stratosphere, chainId);
};

export const getStratosphereAuthenticationControllerAddress = (
  chainId?: number,
) => {
  return getAddress(addresses.stratosphereAuthenticationController, chainId);
};

export const getNFTPositionManagerAddress = (chainId?: number) => {
  return getAddress(addresses.nftPositionMananger, chainId);
};

export const getV2MigratorAddress = (chainId?: number) => {
  return getAddress(addresses.v2MigratorAddress, chainId);
};

export const getV2FactoryAddress = (chainId?: number) => {
  return getAddress(addresses.v2FactoryAddress, chainId);
};

export const getTickLensAddress = (chainId?: number) => {
  return getAddress(addresses.tickLens, chainId);
};

export const getV1FactoryAddress = (chainId?: number) => {
  return getAddress(addresses.v1FactoryAddress, chainId);
};
