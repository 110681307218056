import React from 'react';
import { WalletTransactionsView } from '@vaporfi/features';
import TransactionRow from './TransactionRow';
import useActiveWagmi from 'hooks/useActiveWagmi';
import { useAppDispatch } from 'state';
import { clearAllTransactions } from 'state/transactions/actions';
import {
  isTransactionRecent,
  useAllTransactions,
} from 'state/transactions/hooks';

const WalletTransactions: React.FC = () => {
  const { chainId } = useActiveWagmi();
  const dispatch = useAppDispatch();
  const allTransactions = useAllTransactions();
  const sortedTransactions = Object.values(allTransactions)
    .filter(isTransactionRecent)
    .sort((a, b) => b.addedTime - a.addedTime);

  const hasTransactions = sortedTransactions.length > 0;
  const handleClearAll = () => {
    if (chainId) {
      dispatch(clearAllTransactions({ chainId }));
    }
  };

  return (
    <WalletTransactionsView
      hasTransactions={hasTransactions}
      onClearAll={handleClearAll}
    >
      {sortedTransactions.map((txn) => (
        <TransactionRow key={txn.hash} txn={txn} />
      ))}
    </WalletTransactionsView>
  );
};

export default WalletTransactions;
