import { Box, Container, Typography, useMedia } from '@vaporfi/uikit';
import { memo, useCallback, useMemo } from 'react';
import { FirstRowProps, SecondRowProps, MultirowStatsProps } from './types';
import { batchItems, cn } from '@vaporfi/utils';
export const BlankRow = ({ className }: { className?: string }) => {
  return (
    <Box
      className={cn(
        'h-5 bg-gradient-to-r from-[#E5E5E580] via-[#52525280] to-[#E5E5E580]',
        className,
      )}
    />
  );
};
const FirstRow = memo(
  ({ firstRowItems, isBlank, logo, subtitle, title }: FirstRowProps) => {
    return (
      <Container stack="row" className="w-full justify-between">
        <Container className="flex items-center gap-[10px]">
          {logo()}

          {isBlank ? (
            <Box className="flex flex-col gap-2">
              <BlankRow className="w-[130px]" />
              <BlankRow className="w-[130px]" />
            </Box>
          ) : (
            <Box>
              <Typography color="light" weight="semibold">
                {title}
              </Typography>
              <Typography>{subtitle}</Typography>
            </Box>
          )}
        </Container>
        <Container stack="column">
          {firstRowItems.map(({ label, value }, index) => (
            <Typography
              key={`${label}-${index}`}
              className={index === 1 ? 'ml-auto' : ''}
            >
              <span className="text-turquoise-500 font-semibold">{value}</span>{' '}
              {label}
            </Typography>
          ))}
        </Container>
      </Container>
    );
  },
);

const SecondRow = memo(({ isBlank, secondRowItems }: SecondRowProps) => {
  return (
    <Container
      stack="row"
      className="w-full justify-between bg-neutral-900 px-4 py-2"
    >
      {secondRowItems.map(({ label, value }, index) => (
        <Container key={`${label}-${index}`}>
          <Typography color="neutral" weight="semibold" size="xs">
            {label}
          </Typography>
          {isBlank ? (
            <BlankRow className="h-[26px]" />
          ) : (
            <Typography size="xl">{value}</Typography>
          )}
        </Container>
      ))}
    </Container>
  );
});

export const MultirowStats = memo(
  ({
    firstRowItems,
    isBlank,
    logo,
    remainingRowItems,
    secondRowItems,
    subtitle,
    title,
  }: MultirowStatsProps) => {
    const { isDesktop } = useMedia();

    const rows = useMemo(() => {
      return batchItems(remainingRowItems, isDesktop ? 4 : 3);
    }, [isDesktop, remainingRowItems]);

    const RemainingRows = useCallback(() => {
      return rows.map((row, rowIndex) => (
        <Container
          key={`row-${rowIndex}`}
          stack="row"
          fullWidth
          className="justify-between"
        >
          {row.map(({ label, value }, itemIndex) => (
            <Box
              key={`${label}-${itemIndex}`}
              className={label === '7d Volume' ? 'opacity-0' : ''}
            >
              <Typography color="neutral" weight="semibold" size="xs">
                {label}
              </Typography>
              {isBlank ? (
                <BlankRow />
              ) : (
                <Typography size="sm">{value}</Typography>
              )}
            </Box>
          ))}
        </Container>
      ));
    }, [rows, isBlank]);
    return (
      <Container
        className="w-full gap-5 p-4 lg:gap-[30px] lg:p-[30px]"
        bg="black"
        stack="column"
      >
        <FirstRow {...{ firstRowItems, isBlank, logo, subtitle, title }} />
        <SecondRow secondRowItems={secondRowItems} isBlank={isBlank} />
        <RemainingRows />
      </Container>
    );
  },
);
