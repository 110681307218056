import { BottomNavigation, IconButton, Paper } from '@mui/material';
import { ChartIcon, GlobalSettingsMobileIcon } from '@vapordex/uikit';
import {
  MenuItem,
  earnItems,
  rewardsItems,
  tradeItems,
  servicesItems,
} from '@config/constants/menuItems';
import { useModal } from '@vaporfi/hooks';
import React, { useCallback, useMemo, useState } from 'react';
import {
  faArrowsRotate,
  faSackDollar,
} from '@fortawesome/free-solid-svg-icons';
import { Tools } from '@vaporfi/uikit';
import { Flyout } from '@vaporfi/uikit';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SettingsModal from '@components/Menu/GlobalSettings/SettingsModal';
import { useFeature } from '@vaporfi/feature-flags/useFeature';
import { useRouter } from 'next/router';
import { noop } from 'lodash';

const getHighlightColor = (urls, pathname) =>
  urls.some((url) => pathname.includes(url))
    ? 'rgba(20, 211, 237, 1)'
    : 'rgba(255, 255, 255, 1)';

const SidebarMobile = () => {
  const [isFlyoutVisible, toggleFlyout] = useState(false);
  const [selectedFlyoutTab, selectFlyoutTab] = useState<
    'earn' | 'trade' | 'services' | 'rewards' | undefined
  >();
  const [value, setValue] = useState(0);
  const { pathname } = useRouter();
  const { isEnabled } = useFeature();
  const [onPresentSettingsModal] = useModal(<SettingsModal onDismiss={noop} />);

  const filterEnabledItems = useCallback(
    (items) => {
      return items
        ?.map(({ featureFlagId, ...item }) => {
          const enabled = isEnabled(featureFlagId);
          return enabled ? item : null;
        })
        .filter(Boolean);
    },
    [isEnabled],
  );

  const {
    enabledEarnItems,
    enabledRewardsItems,
    enabledServicesItems,
    enabledTradeItems,
  } = useMemo(
    () => ({
      enabledEarnItems: filterEnabledItems(earnItems),
      enabledRewardsItems: filterEnabledItems(rewardsItems),
      enabledServicesItems: filterEnabledItems(servicesItems),
      enabledTradeItems: filterEnabledItems(tradeItems),
    }),
    [filterEnabledItems],
  );

  const flyoutItems: MenuItem[] = useMemo(() => {
    switch (selectedFlyoutTab) {
      case 'earn': {
        return enabledEarnItems;
      }
      case 'trade': {
        return enabledTradeItems;
      }
      case 'services': {
        return enabledServicesItems;
      }
      case 'rewards': {
        return enabledRewardsItems;
      }
      default: {
        return enabledTradeItems;
      }
    }
  }, [
    selectedFlyoutTab,
    enabledTradeItems,
    enabledEarnItems,
    enabledServicesItems,
    enabledRewardsItems,
  ]);

  const toggleWithSwitchTab = useCallback(
    (tabName?: typeof selectedFlyoutTab, isTabSwitch: boolean = false) => {
      if (isTabSwitch && tabName) {
        selectFlyoutTab(tabName);
      }
      toggleFlyout((prev) => !prev);
    },
    [],
  );

  return (
    <Paper
      sx={{ bottom: 0, left: 0, position: 'fixed', right: 0, zIndex: 10 }}
      elevation={3}
    >
      <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        sx={{ justifyContent: 'space-between', px: 4 }}
      >
        <IconButton onClick={() => toggleWithSwitchTab('earn', true)}>
          <FontAwesomeIcon
            color={getHighlightColor(
              enabledEarnItems.map((item) => item.url),
              pathname,
            )}
            icon={faSackDollar}
            size="sm"
          />
        </IconButton>

        <IconButton onClick={() => toggleWithSwitchTab('trade', true)}>
          <FontAwesomeIcon
            color={getHighlightColor(
              enabledTradeItems.map((item) => item.url),
              pathname,
            )}
            icon={faArrowsRotate}
            size="sm"
          />
        </IconButton>

        {enabledServicesItems?.length > 0 && (
          <IconButton onClick={() => toggleWithSwitchTab('services', true)}>
            <Tools
              color={getHighlightColor(
                enabledServicesItems.map((item) => item.url),
                pathname,
              )}
            />
          </IconButton>
        )}

        <IconButton onClick={() => toggleWithSwitchTab('rewards', true)}>
          <ChartIcon
            color={getHighlightColor(
              enabledRewardsItems.map((item) => item.url),
              pathname,
            )}
          />
        </IconButton>

        <IconButton onClick={onPresentSettingsModal}>
          <GlobalSettingsMobileIcon />
        </IconButton>
      </BottomNavigation>
      {isFlyoutVisible && (
        <Flyout
          items={flyoutItems}
          toggler={() => toggleWithSwitchTab(undefined, false)}
        />
      )}
    </Paper>
  );
};

export default SidebarMobile;
