'use client';
import { ComponentProps } from 'react';
import { Root, Fallback, Image } from '@radix-ui/react-avatar';
import { VariantProps, tv } from 'tailwind-variants';

const avatarStyles = tv({
  slots: {
    fallback:
      'text-light leading-1 flex h-full w-full items-center bg-white justify-center text-sm',
    image: 'h-full w-full rounded-[inherit] object-cover',

    root: 'inline-flex h-[80px] w-[80px] select-none items-center justify-center overflow-hidden rounded-full align-middle',
  },
  variants: {
    size: {
      20: { root: 'h-[20px] w-[20px]' },
      30: { root: 'h-[30px] w-[30px]' },
      32: { root: 'h-[32px] w-[32px]' },
      38: { root: 'h-[38px] w-[38px]' },
      64: { root: 'h-[64px] w-[64px]' },
    },
  },
});
export interface AvatarProps
  extends VariantProps<typeof avatarStyles>,
    ComponentProps<typeof Root> {
  src?: string;
  alt: string;
  fallbackText?: string;
  fallbackClassName?: string;
}
export const Avatar = ({
  alt,
  className,
  fallbackClassName,
  fallbackText,
  size,
  src,
  ...rest
}: AvatarProps) => {
  const { fallback, image, root } = avatarStyles();
  return (
    <Root className={root({ class: className, size })} {...rest}>
      <Image className={image()} src={src} alt={alt} loading="lazy" />
      <Fallback
        className={fallback({ class: fallbackClassName })}
        delayMs={600}
      >
        {fallbackText}
      </Fallback>
    </Root>
  );
};
