/** @jsxImportSource @emotion/react */
import { NETWORK_ICON } from './networks';
import { setNetworkCookie } from '@config/constants/networks';
import { css } from '@emotion/react';
import {
  Text,
  Grid,
  InjectedModalProps,
  Button,
  ModalHeader,
  ModalBody,
  ModalTitle,
  Heading,
  ModalXmarkButton,
  ModalContainer,
} from '@vapordex/uikit';
import Image from 'next/legacy/image';
import { useAppDispatch } from 'state';
import { resetCallListeners } from 'state/multicall/actions';
import styled from 'styled-components';
import { useNetwork, useSwitchNetwork } from 'wagmi';
import useActiveWagmi from '@hooks/useActiveWagmi';
import UserMenu from '../UserMenu';
import { useRouter } from 'next/router';

const NetworkButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #262626;
  border-radius: 5px;
  background: transparent;
  opacity: 1;
  :hover {
    border-color: #14d3ed;
    opacity: 1;
  }
  :disabled {
    border-color: #14d3ed;
    background: transparent;
    opacity: 1;
  }
`;

const StyledModalContainer = styled(ModalContainer)`
  max-width: 480px;
  width: 100%;
  border-radius: 10px;
`;

const StyledModalHeader = styled(ModalHeader)`
  justify-content: space-between;
  align-items: center;
  padding: 12px 14px;
  border-bottom: none;
`;
const StyledModalTitle = styled(ModalTitle)`
  padding: 0 20px;
`;

const StyledHeading = styled(Heading)`
  font-size: 16px;
`;

const StyledModalBody = styled(ModalBody)`
  padding: 0px 30px 30px 30px;
  max-height: calc(100vh - 200px);
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const GridContainer = styled(Grid)`
  grid-auto-flow: row dense;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  overflow-y: auto;
  gap: 1rem;
  margin-left: 0px;
  margin-right: 0px;
  max-height: 650px;
  width: 100%;

  ${({ theme }) => theme.mediaQueries.sm} {
    max-height: none;
  }
  ${({ theme }) => theme.mediaQueries.md} {
    grid-template-columns: repeat(2, minmax(0, 1fr));
`;

const NetworkModal: React.FC<InjectedModalProps> = ({ onDismiss }) => {
  const dispatch = useAppDispatch();
  const { chain } = useNetwork();
  const { chains, switchNetworkAsync } = useSwitchNetwork();
  const { isConnected } = useActiveWagmi();
  const router = useRouter();
  const handleSwitchNetwork = (id: number) => async () => {
    if (switchNetworkAsync) {
      dispatch(resetCallListeners);
      setNetworkCookie(id);
      await switchNetworkAsync(id);
      router.replace(router.asPath);
      onDismiss();
    }
  };

  if (!isConnected) {
    return (
      <StyledModalContainer
        style={{
          background: 'rgba(0,0,0,1)',
          borderRadius: 10,
          display: 'flex',
          flexDirection: 'column',
          maxWidth: '568px',
          overflow: 'hidden',
          width: '100%',
        }}
      >
        <StyledModalHeader>
          <StyledModalTitle>
            <StyledHeading>{'Please connect your wallet first'}</StyledHeading>
          </StyledModalTitle>
          <ModalXmarkButton onDismiss={onDismiss} />
        </StyledModalHeader>
        <StyledModalBody>
          <UserMenu />
        </StyledModalBody>
      </StyledModalContainer>
    );
  }

  return (
    <StyledModalContainer
      style={{
        background: 'rgba(0,0,0,1)',
        borderRadius: 10,
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '568px',
        overflow: 'hidden',
        width: '100%',
      }}
    >
      <StyledModalHeader>
        <StyledModalTitle>
          <StyledHeading>{'Select a network'}</StyledHeading>
        </StyledModalTitle>
        <ModalXmarkButton onDismiss={onDismiss} />
      </StyledModalHeader>
      <StyledModalBody>
        <GridContainer>
          {chains.map(({ id, name, testnet }) => {
            return (
              <NetworkButton
                key={id}
                onClick={handleSwitchNetwork(id)}
                disabled={!switchNetworkAsync || chain?.id === id}
                className={testnet ? '!bg-slate-700' : undefined}
              >
                <Image
                  data-testid="network-modal-network-icon"
                  src={NETWORK_ICON[id]}
                  alt="Switch Network"
                  css={css`
                    border-radius: 100%;
                  `}
                  width={32}
                  height={32}
                />
                <Text>{`${name} ${testnet ? 'testnet' : ''}`}</Text>
              </NetworkButton>
            );
          })}
        </GridContainer>
      </StyledModalBody>
    </StyledModalContainer>
  );
};

export default NetworkModal;
