import React from 'react';
import {
  Container,
  Box,
  Typography,
  DoubleRingIcon,
  Span,
} from '@vaporfi/uikit';
import OneTimeEvents from './components/OneTimeEvents';
import { StratosphereDashboardProps } from './types';
import KPIs from './components/KPIs';
import Progress from './components/Progress';
import ClaimPoints from './components/ClaimPoints';

export const StratosphereDashboard: React.FC<StratosphereDashboardProps> = ({
  availablePoints,
  claimablePoints,
  currentTier,
  expiringPoints,
  onClaimPointsClick,
  oneTimeEventStatus,
  pendingPoints,
  progressPercentage,
  totalPoints,
  weeklyPoints,
}) => {
  return (
    <Container stack="column" className="gap-[30px] p-4 lg:p-[30px]" fullWidth>
      <Box className="flex items-center gap-4">
        <DoubleRingIcon className="flex-shrink-0" />
        <Typography className="text-4xl">
          <Span font="manverse">Stratosphere</Span>
          <Span font="manverseOutline"> Dashboard</Span>
        </Typography>
      </Box>

      <Progress
        {...{
          currentTier,
          pendingPoints,
          progressPercentage,
        }}
      />
      <ClaimPoints
        claimablePoints={claimablePoints}
        onClaimPointsClick={onClaimPointsClick}
      />
      <KPIs
        {...{ availablePoints, expiringPoints, totalPoints, weeklyPoints }}
      />
      <OneTimeEvents eventStatus={oneTimeEventStatus} />
    </Container>
  );
};

export * from './constants';
export * from './types';
