export function batchItems<T extends Array<unknown>>(
  items: T,
  batchSize: number,
): [T, T, ...T[]] {
  const batches = [];

  for (let i = 0; i < items.length; i += batchSize) {
    batches.push(items.slice(i, i + batchSize));
  }

  return batches as [T, T, ...T[]];
}
