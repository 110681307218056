'use client';
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
  InfoIcon,
  Typography,
  TypographyProps,
} from '@vaporfi/uikit';
import { cn } from '@vaporfi/utils';
import { ForwardedRef, forwardRef, memo, useState } from 'react';

export const KPIDetails = memo(({ content }: { content?: string }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  return (
    <HoverCard
      openDelay={0}
      open={isOpen}
      onOpenChange={(val) => setIsOpen(val)}
    >
      <HoverCardTrigger onTouchStart={() => setIsOpen(true)}>
        <InfoIcon className="cursor-pointer text-white" fill="white" />
      </HoverCardTrigger>
      <HoverCardContent className="shadow-modal border-0 bg-neutral-500">
        <Typography>{content}</Typography>
      </HoverCardContent>
    </HoverCard>
  );
});
interface PointsTypographyProps extends TypographyProps {
  isExpiring?: boolean;
}
export const PointsTypography = forwardRef<
  HTMLParagraphElement,
  PointsTypographyProps
>(
  (
    { children, className, isExpiring, ...rest }: PointsTypographyProps,
    ref: ForwardedRef<HTMLParagraphElement>,
  ) => {
    return (
      <Typography
        className={cn('text-[32px] leading-[32px]', className)}
        color={isExpiring ? 'red' : 'turquoise'}
        weight="semibold"
        ref={ref}
        {...rest}
      >
        {children}
      </Typography>
    );
  },
);
