import Svg from '../Svg';
import { SvgProps } from '../types';

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => (
  <Svg viewBox="0 0 14 15" fill="none" {...props}>
    <path
      d="M13.0667 0.0761108C12.5513 0.0761108 12.1333 0.493486 12.1333 1.00944V3.1704C10.9696 1.54961 9.0825 0.542778 7 0.542778C4.24375 0.542778 1.82554 2.30269 0.979417 4.92361C0.820808 5.4139 1.09016 5.94094 1.58054 6.09844C2.07317 6.25886 2.59787 5.98726 2.75596 5.4979C3.35125 3.64903 5.0575 2.40944 7 2.40944C8.47292 2.40944 9.80758 3.12549 10.6283 4.27611H9.33333C8.81796 4.27611 8.4 4.69349 8.4 5.20944C8.4 5.7254 8.81796 6.14278 9.33333 6.14278H13.0667C13.582 6.14278 14 5.7254 14 5.20944V1.00944C14 0.493486 13.5829 0.0761108 13.0667 0.0761108ZM12.4192 8.05611C11.9257 7.8975 11.4018 8.16729 11.2437 8.65665C10.6488 10.5032 8.9425 11.7428 7 11.7428C5.52708 11.7428 4.19271 11.0267 3.37167 9.87611H4.66667C5.18204 9.87611 5.6 9.45874 5.6 8.94278C5.6 8.42682 5.18204 8.00944 4.66667 8.00944H0.933333C0.417958 8.00944 0 8.42682 0 8.94278V13.1428C0 13.6587 0.417958 14.0761 0.933333 14.0761C1.44871 14.0761 1.86667 13.6587 1.86667 13.1428V10.9818C3.03042 12.6032 4.9175 13.6094 6.97375 13.6094C9.72854 13.6094 12.1479 11.8495 12.9937 9.22861C13.1804 8.73861 12.9092 8.18736 12.4192 8.05611Z"
      fill="#D6DADA"
    />
  </Svg>
);

export default Icon;
