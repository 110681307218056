import { InjectedConnector } from '@wagmi/core';
import { Chain, ConnectorNotFoundError } from 'wagmi';
import { getAddress } from 'viem';
import { InjectedConnectorOptions } from '../types';

export type OkxConnectorOptions = Pick<
  InjectedConnectorOptions,
  'shimDisconnect'
>;
type Provider = Window['okxwallet'];
export class OkxConnector extends InjectedConnector {
  readonly id = 'okxwallet';
  readonly ready =
    typeof window != 'undefined' &&
    window.okxwallet &&
    !!this.#findProvider(window.okxwallet);

  #provider?: Provider;

  constructor(config?: { chains?: Chain[]; options?: OkxConnectorOptions }) {
    super({
      ...config,
      options: {
        name: 'okxwallet',
        shimDisconnect: true,
        ...config?.options,
      },
    });
  }

  async getAccount() {
    const provider = await this.getProvider();
    if (!provider) throw new ConnectorNotFoundError();
    const accounts = await provider.req({
      method: 'eth_accounts',
    });

    return getAddress(<string>accounts[0]);
  }

  async getProvider() {
    if (typeof window !== 'undefined') {
      const provider = this.#findProvider(window.okxwallet);

      if (provider) {
        this.#provider = provider;
      }
    }
    return this.#provider;
  }

  #getReady(ethereum?: Provider) {
    const isOkxWallet = !!ethereum?.isOkxWallet || !!ethereum?.isOKExWallet;
    if (!isOkxWallet) return;
    if (ethereum.isBraveWallet && !ethereum._events && !ethereum._state) return;
    if (ethereum.isKuCoinWallet) return;
    if (ethereum.isApexWallet) return;
    if (ethereum.isBitKeep) return;
    if (ethereum.isBlockWallet) return;
    if (ethereum.isMathWallet) return;
    if (ethereum.isAvalanche) return;
    if (ethereum.isOneInchIOSWallet || ethereum.isOneInchAndroidWallet) return;
    if (ethereum.isOpera) return;
    if (ethereum.isPortal) return;
    if (ethereum.isRabby) return;
    if (ethereum.isDefiant) return;
    if (ethereum.isTokenPocket) return;
    if (ethereum.isTokenary) return;
    if (ethereum.isZerion) return;
    return ethereum;
  }

  #findProvider(ethereum?: Provider) {
    if (ethereum?.providers) return ethereum.providers.find(this.#getReady);
    return this.#getReady(ethereum);
  }
}
