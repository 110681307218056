import { tokenListUpdaterUrls } from '@/config/constants/menuItems';
import { useAppDispatch } from '../index';
import { acceptListUpdate } from './actions';
import { useActiveListUrls } from './hooks';
import { getVersionUpgrade, VersionUpgrade } from '@uniswap/token-lists';
import { UNSUPPORTED_LIST_URLS } from '@vapordex/tokenlist';
import useActiveWagmi from 'hooks/useActiveWagmi';
import useFetchListCallback from 'hooks/useFetchListCallback';
import useInterval from 'hooks/useInterval';
import useIsWindowVisible from 'hooks/useIsWindowVisible';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useMemo } from 'react';
import { useAllLists } from 'state/lists/hooks';

export default function Updater(): null {
  const { library } = useActiveWagmi();
  const dispatch = useAppDispatch();
  const isWindowVisible = useIsWindowVisible();
  const router = useRouter();
  const includeListUpdater = useMemo(() => {
    return tokenListUpdaterUrls.some((item) => {
      return router.pathname.startsWith(item);
    });
  }, [router.pathname]);

  // get all loaded lists, and the active urls
  const lists = useAllLists();
  const activeListUrls = useActiveListUrls();

  const fetchList = useFetchListCallback();
  const fetchAllListsCallback = useCallback(() => {
    if (!isWindowVisible) return;
    for (const url of Object.keys(lists)) {
      fetchList(url).catch((error) =>
        console.debug('interval list fetching error', error),
      );
    }
  }, [fetchList, isWindowVisible, lists]);

  // fetch all lists every 10 minutes, but only after we initialize library and page has currency input
  useInterval(
    fetchAllListsCallback,
    library ? 1000 * 60 * 10 : null,
    true,
    includeListUpdater,
  );

  // whenever a list is not loaded and not loading, try again to load it
  useEffect(() => {
    for (const listUrl of Object.keys(lists)) {
      const list = lists[listUrl];
      if (!list.current && !list.loadingRequestId && !list.error) {
        fetchList(listUrl).catch((error) =>
          console.debug('list added fetching error', error),
        );
      }
    }
  }, [dispatch, fetchList, library, lists]);

  // if any lists from unsupported lists are loaded, check them too (in case new updates since last visit)
  useEffect(() => {
    for (const listUrl of Object.keys(UNSUPPORTED_LIST_URLS)) {
      const list = lists[listUrl];
      if (!list || (!list.current && !list.loadingRequestId && !list.error)) {
        fetchList(listUrl).catch((error) =>
          console.debug('list added fetching error', error),
        );
      }
    }
  }, [dispatch, fetchList, library, lists]);

  // automatically update lists if versions are minor/patch
  useEffect(() => {
    for (const listUrl of Object.keys(lists)) {
      const list = lists[listUrl];
      if (list.current && list.pendingUpdate) {
        const bump = getVersionUpgrade(
          list.current.version,
          list.pendingUpdate.version,
        );
        // eslint-disable-next-line default-case
        switch (bump) {
          case VersionUpgrade.NONE: {
            throw new Error('unexpected no version bump');
          }
          // update any active or inactive lists
          case VersionUpgrade.PATCH:
          case VersionUpgrade.MINOR:
          case VersionUpgrade.MAJOR: {
            dispatch(acceptListUpdate(listUrl));
          }
        }
      }
    }
  }, [dispatch, lists, activeListUrls]);

  return null;
}
