import { AppState } from '../../index';
import { deserializeToken } from './helpers';
import { createSelector } from '@reduxjs/toolkit';
import { getChainIdCookie } from 'config/constants/networks';
import { useSelector } from 'react-redux';
import { Token } from '@vapordex/sdk';
import { ChainId } from '@vapordex/sdk';
const selectUserTokens = ({ user: { tokens } }: AppState) => tokens;

export const userAddedTokenSelector = createSelector(
  selectUserTokens,
  (serializedTokensMap) =>
    Object.values(
      serializedTokensMap?.[getChainIdCookie() as unknown as ChainId] ?? {},
    ).map(deserializeToken),
);
export default function useUserAddedTokens(): Token[] {
  return useSelector(userAddedTokenSelector);
}
