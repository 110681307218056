import { forwardRef, ComponentProps } from 'react';
import { tv, VariantProps } from 'tailwind-variants';

type CoinVariants = VariantProps<typeof coinStyles>;
export type CoinProps = {
  alt?: string;
  src?: string;
  className?: string;
} & CoinVariants &
  ComponentProps<'div'>;

const coinStyles = tv({
  base: 'rounded-full h-[101px] w-[101px]',
});

export const Coin = forwardRef<HTMLDivElement, CoinProps>(
  ({ alt, className, src }, ref) => {
    return (
      <div className={coinStyles({ class: className })} ref={ref}>
        {src && (
          // eslint-disable-next-line @next/next/no-img-element
          <img src={src} alt={alt || 'coin image'} />
        )}
      </div>
    );
  },
);
