/* eslint-disable turbo/no-undeclared-env-vars */
import type { NextApiRequest, NextApiResponse } from 'next';
import { getFeatures } from './utils';

export async function handler(req: NextApiRequest, res: NextApiResponse) {
  try {
    const chainId = (req?.query?.chainId as string) ?? null;
    const featureFlags = await getFeatures(chainId);
    const enabledFeatures = Object.keys(featureFlags).filter((key) =>
      Boolean(featureFlags[key]),
    );

    res.status(200).json(enabledFeatures);
  } catch (error) {
    console.log(error);
  }
}

export default async function featureFlagsDirectus(
  req: NextApiRequest,
  res: NextApiResponse,
) {
  try {
    const chainId = req?.query?.chainId;
    const features = await getFeatures(chainId as string);

    const enabledFeatures = Object.keys(features).filter((key) =>
      Boolean(features[key]),
    );
    return res.status(200).json(enabledFeatures);
  } catch (error) {
    console.error('Error fetching data from Directus:', error);
    res.status(500).json({ error: 'Internal Server Error' });
  }
}
