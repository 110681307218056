import { Svg, SvgProps } from './Svg';

export const LeafInHandsIcon = ({
  height = '70pt',
  version = '1.1',
  viewBox = '0 0 700 700',
  width = '70pt',
  xmlns = 'http://www.w3.org/2000/svg',
  ...rest
}: SvgProps) => {
  return (
    <Svg {...rest} {...{ height, version, viewBox, width, xmlns }}>
      <g>
        <path
          fill="var(--colors-blue500)"
          d="m203.44 459.38c-6.125-31.062-25.375-57.75-53.375-73.062l-43.75-26.25h23.625c5.6875 0 11.375-0.875 17.062-2.1875 12.25-3.0625 21-13.125 21.875-24.938v-2.625c0-3.9375-1.75-7.4375-4.375-10.062-2.1875-1.75-4.8125-2.625-7.4375-2.625l-71.312 7.4375c-6.5625 0.875-12.25 5.6875-14.438 13.125l-20.125 71.75c-2.625 8.75-1.75 18.375 2.625 26.25l35.438 71.312c5.25 10.062 6.125 21.875 3.0625 32.812l-19.688 70.875 67.812 18.812 17.062-61.25-0.4375 66.062 63 17.5 18.375-65.188c2.625-9.625 8.3125-18.375 15.75-24.5l28.875-24.5c11.375-9.625 19.25-21.875 23.188-36.312l2.625-8.75-103.69-28.875z"
        />
        <path
          fill="var(--colors-blue500)"
          d="m175.44 307.56c0.4375 0 0.4375 0.4375 0.875 0.875l17.5-62.125c2.625-9.625-2.1875-20.125-10.938-23.188-3.5-1.3125-7.4375-0.875-10.938 0.875-3.9375 2.1875-7 5.6875-8.3125 10.5l-19.25 67.375 10.062-0.875c7.875-0.875 14.875 1.75 21 6.5625z"
        />
        <path
          fill="var(--colors-blue500)"
          d="m647.06 437.5c3.9375-8.3125 4.8125-17.5 2.625-26.25l-20.125-71.75c-2.1875-7.4375-7.875-12.688-14.438-13.125l-71.312-7.4375c-2.625-0.4375-5.25 0.4375-7.4375 2.625-2.625 2.1875-4.375 6.125-4.375 10.062v2.625c0.4375 11.812 9.625 21.875 21.875 24.938 5.6875 1.3125 11.375 2.1875 17.062 2.1875h23.625l-43.75 26.25c-28 15.312-47.25 41.562-53.375 73.062l-0.875 5.25-103.69 28.875 2.625 8.75c3.9375 14 11.812 26.688 23.188 36.312l28.875 24.5c7.4375 6.5625 13.125 14.875 15.75 24.5l18.375 65.188 63-17.5-0.4375-66.062 17.062 61.25 67.812-18.812-19.688-70.875c-3.0625-10.938-2.1875-22.75 3.0625-32.812z"
        />
        <path
          fill="var(--colors-blue500)"
          d="m517.56 223.12c-8.75 3.0625-13.562 13.125-10.938 23.188l17.5 62.125c0.4375 0 0.4375-0.4375 0.875-0.875 5.6875-5.25 13.125-7.4375 21-6.5625l9.625 0.875-18.812-67.375c-1.3125-4.8125-4.375-8.75-8.3125-10.5-3.5-1.75-7.4375-2.1875-10.938-0.875z"
        />
        <path
          fill="var(--colors-blue500)"
          d="m415.19 80.938 0.875-0.4375s-34.562 35-56.875 60.375c2.1875 3.0625 4.375 6.5625 6.125 10.062 7.875 0.875 16.188 0.4375 24.062-0.875 31.5-5.6875 60.375-21.875 81.812-45.5 14.875-17.062 34.125-29.75 55.125-37.625-21-15.75-47.25-22.75-73.062-19.25l-6.5625 0.875c-34.125 4.8125-64.75 22.312-86.188 49.438-6.125 7.875-11.375 16.625-15.312 25.812l3.9375 3.9375c18.375-20.125 41.125-35.875 66.062-46.812z"
        />
        <path
          fill="var(--colors-blue500)"
          d="m228.81 151.38c21.438 23.625 50.312 39.812 81.812 45.5 12.688 2.1875 25.812 1.75 38.5-1.75l-2.625-2.625c-14.438-15.75-47.688-51.188-47.688-51.188 22.75 7.875 43.75 21.438 60.375 38.938l-0.4375-0.875c-4.375-12.688-10.5-24.5-19.25-35-21-27.125-51.625-44.625-85.75-49.438l-6.125-0.875h-0.4375c-25.812-3.5-52.062 3.5-73.062 19.25 21 7.875 39.812 21 54.688 38.062z"
        />
        <path
          fill="var(--colors-blue500)"
          d="m285.69 284.38 5.25-18.812c2.625-9.625-2.1875-20.125-10.938-23.188-3.5-1.3125-7.4375-0.875-10.938 0.875-3.9375 2.1875-7 6.125-8.3125 10.5l-15.75 56.438z"
        />
        <path
          fill="var(--colors-blue500)"
          d="m517.12 349.56-35.875-129.06c-1.3125-4.8125-4.375-8.75-8.3125-10.938-3.5-1.75-7.4375-2.1875-10.938-0.875-8.75 3.0625-13.562 13.125-10.938 23.188l25.375 91.875z"
        />
        <path
          fill="var(--colors-blue500)"
          d="m529.81 378.44c-24.5 17.062-42 42.875-49 72.188l-93.188 26.25-2.1875 0.4375c-7.4375 2.1875-12.688 9.1875-12.688 16.625v97.562c0 11.812-8.75 22.312-20.125 23.188-13.562 1.3125-24.5-9.1875-24.5-22.312v-98.875c0-7.875-5.25-14.875-12.688-16.625l-2.1875-0.4375-93.625-26.25c-7-29.312-24.5-55.125-49-72.188l25.812-16.188 19.688-12.688h0.4375l22.312-14 40.25-25.812 21.875-14 4.375-2.625c6.125-3.9375 12.688-7 19.688-9.1875 5.6875-1.75 11.375-3.0625 17.062-3.5v-65.625c3.9375-0.4375 7.875-1.3125 11.812-2.625l5.6875-1.3125v69.562c5.6875 0.4375 10.938 1.75 16.188 3.5 7 2.1875 13.562 5.25 19.688 9.1875l4.375 2.625 21.875 14 40.25 25.812 21.875 14 0.4375 0.4375 19.688 12.688z"
        />
        <path
          fill="var(--colors-blue500)"
          d="m238 209.12c-3.5-1.3125-7.4375-0.875-10.938 0.875-3.9375 2.1875-7 6.125-8.3125 10.938l-35.875 129.06 40.25-25.812 25.375-91.875c3.0625-10.062-1.75-20.125-10.5-23.188z"
        />
        <path
          fill="var(--colors-blue500)"
          d="m430.94 242.81c-3.5-1.75-7.4375-2.1875-10.938-0.875-8.75 3.0625-13.562 13.125-10.938 23.188l5.25 19.25 40.25 25.812-15.312-56.438c-1.3125-4.8125-4.375-8.75-8.3125-10.938z"
        />
      </g>
    </Svg>
  );
};
