import React from 'react';
import { WalletWrongNetworkView } from '@vaporfi/features';
import { useModal } from '@vaporfi/hooks';
import NetworkModal from '../NetworkSelector/NetworkModal';
import { DOCS_URL } from '@config/constants/domains';

const handleLearnMore = () => {
  window.open(DOCS_URL, '_blank');
};

const WalletWrongNetwork: React.FC<{ onDismiss: () => void }> = ({
  onDismiss,
}) => {
  const [onPresentNetworkModal] = useModal(<NetworkModal />);

  const handleSwitchNetwork = () => {
    onDismiss();
    onPresentNetworkModal();
  };

  return (
    <WalletWrongNetworkView
      onSwitchNetwork={handleSwitchNetwork}
      onLearnMore={handleLearnMore}
    />
  );
};

export default WalletWrongNetwork;
