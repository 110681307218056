import { Flex, UserMenuItem, WarningIcon } from '@vapordex/uikit';
import { useLowBalance } from 'hooks/useTokenBalance';

interface WalletUserMenuItemProps {
  isWrongNetwork: boolean;
  onPresentWalletModal: () => void;
}

const WalletUserMenuItem: React.FC<
  React.PropsWithChildren<WalletUserMenuItemProps>
> = ({ isWrongNetwork, onPresentWalletModal }) => {
  const hasLowBalance = useLowBalance();

  return (
    <UserMenuItem as="button" onClick={onPresentWalletModal}>
      <Flex alignItems="center" justifyContent="space-between" width="100%">
        {'Wallet'}
        {hasLowBalance && !isWrongNetwork && (
          <WarningIcon color="warning" width="24px" />
        )}
        {isWrongNetwork && <WarningIcon color="failure" width="24px" />}
      </Flex>
    </UserMenuItem>
  );
};

export default WalletUserMenuItem;
