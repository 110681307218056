import { ERC20ABI } from '@config/abi/erc20ABI';

import { LOW_AVAX_BALANCE, ZERO } from 'config/constants';
import useActiveWagmi from 'hooks/useActiveWagmi';
import { Address, formatEther } from 'viem';
import { useAccount, useBalance, useContractRead } from 'wagmi';

const useTokenBalance = (tokenAddress: Address) => {
  const { account } = useActiveWagmi();

  const { data, status, ...rest } = useContractRead({
    abi: ERC20ABI,
    address: tokenAddress,
    args: [account],
    functionName: 'balanceOf',
  });

  const balance = data ?? ZERO;
  const balanceFormatted = formatEther(balance);
  const balanceFormattedShortened =
    balance / BigInt(10) ** BigInt(16) / BigInt(100);

  return {
    ...rest,
    balance,
    balanceFormatted,
    balanceFormattedShortened,
    fetchStatus: status,
  };
};

export const useLowBalance = () => {
  const { address: account } = useAccount();
  const { data: balance } = useBalance({ address: account });

  return balance?.value <= LOW_AVAX_BALANCE;
};

export const useGetAVAXBalance = () => {
  const { address: account } = useAccount();
  const { data, ...rest } = useBalance({ address: account });

  return { balance: data?.value ?? ZERO, ...rest };
};

export default useTokenBalance;
