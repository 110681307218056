import { BigintIsh, Currency, Token } from '@vapordex/sdk';
import { IVaporDEXV2PoolStateABI } from '@config/abi/IVaporDEXV2PoolState';
import { FeeAmount, Pool } from '@vapordex/v2-sdk';
import { computePoolAddress } from '@vapordex/v2-sdk';
import JSBI from 'jsbi';
import { useMultipleContractSingleData } from '@state/multicall/hooks';
import { useMemo } from 'react';
import { getV2FactoryAddress } from '@utils/addressHelpers';
import useActiveWagmi from './useActiveWagmi';
import { Address } from 'viem';
import { ZERO } from '@/config/constants';

// Classes are expensive to instantiate, so this caches the recently instantiated pools.
// This avoids re-instantiating pools as the other pools in the same req are loaded.
export class PoolCache {
  // Evict after 128 entries. Empirically, a swap uses 64 entries.
  private static MAX_ENTRIES = 128;

  // These are FIFOs, using unshift/pop. This makes recent entries faster to find.
  private static pools: Pool[] = [];
  private static addresses: { key: string; address: string }[] = [];

  static getPoolAddress(
    factoryAddress: string,
    tokenA: Token,
    tokenB: Token,
    fee: FeeAmount,
  ): Address {
    if (this.addresses.length > this.MAX_ENTRIES) {
      this.addresses = this.addresses.slice(0, this.MAX_ENTRIES / 2);
    }

    const { address: addressA } = tokenA;
    const { address: addressB } = tokenB;
    const key = `${factoryAddress}:${addressA}:${addressB}:${fee.toString()}`;
    const found = this.addresses.find((address) => address.key === key);
    if (found) return found.address as Address;

    const address = {
      address: computePoolAddress({
        factoryAddress,
        fee,
        tokenA,
        tokenB,
      }) as Address,
      key,
    };
    this.addresses.unshift(address);
    return address.address;
  }

  static getPool(
    tokenA: Token,
    tokenB: Token,
    fee: FeeAmount,
    sqrtPriceX96: BigintIsh,
    liquidity: BigintIsh,
    tick: number,
  ): Pool {
    if (this.pools.length > this.MAX_ENTRIES) {
      this.pools = this.pools.slice(0, this.MAX_ENTRIES / 2);
    }

    const found = this.pools.find(
      (pool) =>
        pool.token0 === tokenA &&
        pool.token1 === tokenB &&
        pool.fee === fee &&
        JSBI.EQ(pool.sqrtRatioX96, sqrtPriceX96) &&
        JSBI.EQ(pool.liquidity, liquidity) &&
        pool.tickCurrent === tick,
    );
    if (found) return found;

    const pool = new Pool(tokenA, tokenB, fee, sqrtPriceX96, liquidity, tick);
    this.pools.unshift(pool);
    return pool;
  }
}

export enum PoolState {
  LOADING,
  NOT_EXISTS,
  EXISTS,
  INVALID,
}

export function usePools(
  poolKeys: [
    Currency | undefined,
    Currency | undefined,
    FeeAmount | undefined,
  ][],
): [PoolState, Pool | null][] {
  const { chainId: chainIdFromWagmi } = useActiveWagmi();

  const chainId = useMemo(
    () => chainIdFromWagmi ?? poolKeys?.[0]?.[0]?.chainId,
    [chainIdFromWagmi, poolKeys],
  );

  const poolTokens: ([Token, Token, FeeAmount] | undefined)[] = useMemo(() => {
    if (!chainId && poolKeys[0][0].chainId)
      return Array.from({ length: poolKeys.length });

    return poolKeys.map(([currencyA, currencyB, feeAmount]) => {
      if (currencyA && currencyB && feeAmount) {
        const tokenA = currencyA.wrapped;
        const tokenB = currencyB.wrapped;
        if (tokenA.equals(tokenB)) return;

        return tokenA.sortsBefore(tokenB)
          ? [tokenA, tokenB, feeAmount]
          : [tokenB, tokenA, feeAmount];
      }
      return;
    });
  }, [chainId, poolKeys]);

  const poolAddresses: (Address | undefined)[] = useMemo(() => {
    const v2FactoryAddress = chainId && getV2FactoryAddress(chainId);
    if (!v2FactoryAddress) return Array.from({ length: poolTokens.length });

    return poolTokens.map<Address>((value) =>
      value ? PoolCache.getPoolAddress(v2FactoryAddress, ...value) : undefined,
    );
  }, [chainId, poolTokens]);

  const slot0s = useMultipleContractSingleData(
    poolAddresses,
    IVaporDEXV2PoolStateABI,
    'slot0',
  );
  const liquidities = useMultipleContractSingleData(
    poolAddresses,
    IVaporDEXV2PoolStateABI,
    'liquidity',
  );

  return useMemo(() => {
    return poolKeys.map((_key, index) => {
      const tokens = poolTokens[index];
      if (!tokens) return [PoolState.INVALID, null];
      const [token0, token1, fee] = tokens;

      if (!slot0s[index]) return [PoolState.INVALID, null];
      const {
        loading: slot0Loading,
        result: slot0,
        valid: slot0Valid,
      } = slot0s[index];

      if (!liquidities[index]) return [PoolState.INVALID, null];
      const {
        loading: liquidityLoading,
        result: liquidity,
        valid: liquidityValid,
      } = liquidities[index];

      if (!tokens || !slot0Valid || !liquidityValid)
        return [PoolState.INVALID, null];
      if (slot0Loading || liquidityLoading) return [PoolState.LOADING, null];
      if (!slot0 || liquidity === undefined)
        return [PoolState.NOT_EXISTS, null];
      const [slo0SqrtPriceX96, slot0Tick] = slot0 ?? [ZERO, 0];

      if (!slo0SqrtPriceX96 || slo0SqrtPriceX96 === ZERO)
        return [PoolState.NOT_EXISTS, null];

      try {
        const pool = PoolCache.getPool(
          token0,
          token1,
          fee,
          slo0SqrtPriceX96?.toString(),
          liquidity?.toString(),
          slot0Tick,
        );

        return [PoolState.EXISTS, pool];
      } catch (error) {
        console.error('Error when constructing the pool', error);
        return [PoolState.NOT_EXISTS, null];
      }
    });
  }, [liquidities, poolKeys, slot0s, poolTokens]);
}

export function usePool(
  currencyA: Currency | undefined,
  currencyB: Currency | undefined,
  feeAmount: FeeAmount | undefined,
): [PoolState, Pool | null] {
  const poolKeys: [
    Currency | undefined,
    Currency | undefined,
    FeeAmount | undefined,
  ][] = useMemo(
    () => [[currencyA, currencyB, feeAmount]],
    [currencyA, currencyB, feeAmount],
  );

  return usePools(poolKeys)[0];
}
