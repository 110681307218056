'use client';

import {
  useCallback,
  forwardRef,
  ElementRef,
  ComponentPropsWithoutRef,
} from 'react';
import * as SliderPrimitive from '@radix-ui/react-slider';
import { cn } from '@vaporfi/utils';
import { VariantProps, tv } from 'tailwind-variants';
import { CandyIcon } from '../Icons';

const sliderStyles = tv({
  defaultVariants: { variant: 'vapordex' },
  slots: {
    range: 'absolute',
    root: 'relative flex w-full touch-none select-none items-center',
    thumb: 'cursor-pointer disabled:pointer-events-none disabled:opacity-50',
    track: 'relative w-full grow overflow-hidden',
  },
  variants: {
    variant: {
      candy: {
        range: 'bg-[#C400F9] h-auto top-1 bottom-1',
        track:
          'bg-neutral-100 border-[2px] relative slider-clip border-dark h-[26.38px]',
      },
      vapordex: {
        range: 'bg-gradient-stratosphere-reverse h-full',
        thumb:
          'bg-turquoise-500 focus-visible:ring-ring block h-4 w-4 rounded-full border shadow transition-colors focus-visible:outline-none focus-visible:ring-1 lg:h-[30px] lg:w-[30px]',
        track: 'rounded-full bg-neutral-400 h-1.5',
      },
    },
  },
});
export interface SliderProps
  extends VariantProps<typeof sliderStyles>,
    Omit<ComponentPropsWithoutRef<typeof SliderPrimitive.Root>, 'onChange'> {
  onChange?: (value: number) => void;
}

const Slider = forwardRef<ElementRef<typeof SliderPrimitive.Root>, SliderProps>(
  (
    {
      className,
      max = 100,
      min = 0,
      onChange,
      step = 1,
      value,
      variant = 'vapordex',
      ...props
    },
    ref,
  ) => {
    const { range, root, thumb, track } = sliderStyles({ variant });

    const onValueChange = useCallback(
      (thumb: number[] | number) => {
        if (!onChange) return;
        if (Array.isArray(thumb)) {
          onChange(thumb?.at(-1) as number);
        } else {
          onChange(thumb);
        }
      },
      [onChange],
    );
    return (
      <SliderPrimitive.Root
        ref={ref}
        className={cn(root(), className)}
        value={value}
        onValueChange={onValueChange}
        aria-labelledby="input slider"
        step={step}
        min={min}
        max={max}
        {...props}
      >
        <SliderPrimitive.Track className={track()}>
          <SliderPrimitive.Range className={range()} />
        </SliderPrimitive.Track>
        {variant === 'vapordex' ? (
          <SliderPrimitive.Thumb className={thumb()} />
        ) : (
          <SliderPrimitive.Thumb className={thumb()}>
            <CandyIcon />
          </SliderPrimitive.Thumb>
        )}
      </SliderPrimitive.Root>
    );
  },
);
Slider.displayName = SliderPrimitive.Root.displayName;

export { Slider };
