import { AlertCard, Button, Container, Typography } from '@vaporfi/uikit';
export interface WrapNativeCardProps {
  ethBalance: string;
  handleWrap: () => void;
  isInsufficientBalance: boolean;
  wrapAmount: string;
}
export function WrapNativeCard({
  ethBalance,
  handleWrap,
  isInsufficientBalance,
  wrapAmount,
}: WrapNativeCardProps) {
  return (
    <Container stack="column" className="gap-7">
      <AlertCard
        variant="warning"
        className="flex w-[260px] flex-row justify-between rounded-[10px]"
      >
        <Typography>
          You need to wrap your TLOS tokens to WTLOS to be able to add more
          WTLOS than your current balance.
        </Typography>
        <Container className="pt-4">
          <Typography weight="semibold" size="base">
            TLOS Balance: {ethBalance}
          </Typography>

          <Typography weight="semibold" size="base">
            Wrap amount: {wrapAmount}
          </Typography>
          {!isInsufficientBalance && (
            <Button
              color="turquoise"
              size="sm"
              className="mr-auto mt-2 w-fit"
              onClick={handleWrap}
            >
              <Typography>Wrap</Typography>
            </Button>
          )}
        </Container>
      </AlertCard>
      {isInsufficientBalance && (
        <AlertCard variant="error">
          <Typography size="sm">Not enough TLOS</Typography>
        </AlertCard>
      )}
    </Container>
  );
}
