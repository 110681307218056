import { client } from 'utils/wagmi';
import { FC, PropsWithChildren } from 'react';
import { FeatureProvider as VaporFeatureProvider } from '@vaporfi/feature-flags';
import { fetchStatusMiddleware } from '@/hooks/useSWRContract';
import { dark, MatchBreakpointsProvider } from '@vapordex/uikit';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider as ReduxProvider } from 'react-redux';
import { SWRConfig } from 'swr';
import { ToastsProvider } from 'contexts/ToastsContext';
import { usePollBlockNumber } from '@/state/block/hooks';
import { useStore, persistor } from '@/state';
import { WagmiConfig } from 'wagmi';
import { ThemeProvider as StyledProvider } from 'styled-components';
import darkThemeOptions from '@styles/theme/darkThemeOptions';
import RouterProvider from './RouterProvider';
import Updaters from './Updaters';
import GlobalStyle from '@styles/Global';
import Blocklist from './Blocklist';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { ModalProvider } from '@vaporfi/uikit';
import useActiveWagmi from '@/hooks/useActiveWagmi';

const darkTheme = createTheme(darkThemeOptions);

const FeatureProvider = ({ children }) => {
  const { chainId } = useActiveWagmi();

  return (
    <VaporFeatureProvider chainId={chainId}>{children}</VaporFeatureProvider>
  );
};

function GlobalHooks() {
  usePollBlockNumber();
  // useThemeCookie()
  return null;
}

const RootProvider: FC<PropsWithChildren> = ({ children, ...pageProps }) => {
  // @ts-expect-error: TODO
  const store = useStore(pageProps.initialReduxState);

  return (
    <RouterProvider>
      <ThemeProvider theme={darkTheme}>
        <StyledProvider theme={dark}>
          <GlobalStyle />
          <WagmiConfig config={client}>
            <Blocklist>
              <MatchBreakpointsProvider>
                <ReduxProvider store={store}>
                  <PersistGate loading={null} persistor={persistor}>
                    <ToastsProvider>
                      <GlobalHooks />
                      <Updaters />
                      <SWRConfig
                        value={{
                          use: [fetchStatusMiddleware],
                        }}
                      >
                        <FeatureProvider>
                          <ModalProvider>{children}</ModalProvider>
                        </FeatureProvider>
                      </SWRConfig>
                    </ToastsProvider>
                  </PersistGate>
                </ReduxProvider>
              </MatchBreakpointsProvider>
            </Blocklist>
          </WagmiConfig>
        </StyledProvider>
      </ThemeProvider>
    </RouterProvider>
  );
};

export default RootProvider;
