import { AppState } from '@state';
import { arrayify } from '@ethersproject/bytes';
import { getChainIdCookie } from '@config/constants/networks';
import { combinedTokenMapFromActiveUrlsSelector } from '@state/lists/hooks';
import { createSelector } from '@reduxjs/toolkit';
import { Currency, Token } from '@vapordex/sdk';
import { NEVER_RELOAD, useSingleCallResult } from '@state/multicall/hooks';
import { parseBytes32String } from '@ethersproject/strings';
import { TokenAddressMap } from '@state/types';
import { useMemo } from 'react';
import { userAddedTokenSelector } from '@state/user/hooks/useUserAddedTokens';
import { useSelector } from 'react-redux';
import memoize from 'lodash/memoize';
import useActiveWagmi from '@hooks/useActiveWagmi';
import { Abi, getAddress } from 'viem';
import { ERC20ABI } from '@config/abi/erc20ABI';
import { ERC20_BYTES32_ABI } from '@config/abi/erc20';

export const isAddress = memoize((value: any): any | false => {
  try {
    return getAddress(value);
  } catch {
    return false;
  }
});

const mapWithoutUrls = (tokenMap: TokenAddressMap, chainId?: number) =>
  Object.keys(tokenMap[chainId || getChainIdCookie()]).reduce<{
    [address: string]: Token;
  }>((newMap, address) => {
    newMap[address] = tokenMap[chainId || getChainIdCookie()][address].token;
    return newMap;
  }, {});

// parse a name or symbol from a token res
const BYTES32_REGEX = /^0x[\dA-Fa-f]{64}$/;

const allTokenSelector = createSelector(
  [
    combinedTokenMapFromActiveUrlsSelector,
    userAddedTokenSelector,
    (state, chainId) => chainId,
  ],
  (tokenMap, userAddedTokens, chainId) => {
    return (
      userAddedTokens
        // reduce into all ALL_TOKENS filtered by the current chain
        .reduce<{ [address: string]: Token }>(
          (tokenMap_, token) => {
            tokenMap_[token.address] = token;
            return tokenMap_;
          },

          // must make a copy because reduce modifies the map, and we do not
          // want to make a copy in every iteration
          mapWithoutUrls(tokenMap, chainId),
        )
    );
  },
);

/**
 * Returns all tokens that are from active urls and user added tokens
 */
export function useAllTokens(chainId?: number): { [address: string]: Token } {
  return useSelector((state: AppState) => allTokenSelector(state, chainId));
}

export function parseStringOrBytes32(
  str: string | undefined,
  bytes32: string | undefined,
  defaultValue: string,
): string {
  return str && str.length > 0
    ? str
    : // need to check for proper bytes string and valid terminator
    bytes32 && BYTES32_REGEX.test(bytes32) && arrayify(bytes32)[31] === 0
    ? parseBytes32String(bytes32)
    : defaultValue;
}

// undefined if invalid or does not exist
// null if loading
// otherwise returns the token
export function useToken(tokenAddress?: string): Token | undefined | null {
  const { chainId } = useActiveWagmi();
  const tokens = useAllTokens(chainId);

  const address = isAddress(tokenAddress);

  const token: Token | undefined = address ? tokens[address] : undefined;

  const tokenName = useSingleCallResult(
    address,
    ERC20ABI as Abi,
    'name',
    undefined,
    NEVER_RELOAD,
  );
  const tokenNameBytes32 = useSingleCallResult(
    address,
    ERC20_BYTES32_ABI as Abi,
    'name',
    undefined,
    NEVER_RELOAD,
  );
  const symbol = useSingleCallResult(
    address,
    ERC20ABI as Abi,
    'symbol',
    undefined,
    NEVER_RELOAD,
  );
  const symbolBytes32 = useSingleCallResult(
    address,
    ERC20_BYTES32_ABI as Abi,
    'symbol',
    undefined,
    NEVER_RELOAD,
  );
  const decimals = useSingleCallResult(
    address,
    ERC20ABI as Abi,
    'decimals',
    undefined,
    NEVER_RELOAD,
  );
  return useMemo(() => {
    if (token) return token;
    if (!chainId || !address) return;
    if (decimals.loading || symbol.loading || tokenName.loading) return null;
    if (decimals.result) {
      return new Token(
        chainId,
        address,
        decimals.result,

        parseStringOrBytes32(symbol.result, symbolBytes32.result, 'UNKNOWN'),
        parseStringOrBytes32(
          tokenName.result,
          tokenNameBytes32.result,
          'Unknown Token',
        ),
      );
    }
    return;
  }, [
    address,
    chainId,
    decimals.loading,
    decimals.result,
    symbol.loading,
    symbol.result,
    symbolBytes32.result,
    token,
    tokenName.loading,
    tokenName.result,
    tokenNameBytes32.result,
  ]);
}

export function useCurrency(
  currencyId: string | undefined,
): Currency | Token | null | undefined {
  const { nativeCurrency } = useActiveWagmi();

  const isNative =
    currencyId && currencyId?.toUpperCase() === nativeCurrency?.symbol;
  const token = useToken(isNative ? undefined : currencyId);
  return isNative ? nativeCurrency : token;
}
export default useCurrency;
