import { Svg, SvgProps } from './Svg';

export const StratosphereIcon = ({
  fill = 'none',
  height = '20',
  viewBox = '0 0 20 20',
  width = '20',
  xmlns = 'http://www.w3.org/2000/svg',
  ...rest
}: SvgProps) => {
  return (
    <Svg {...rest} {...{ fill, height, viewBox, width, xmlns }}>
      <g id="icon" clipPath="url(#clip0_14337_7298)">
        <path
          id="Vector"
          d="M10 0C4.47814 0 0 4.47607 0 10C0 15.5239 4.47814 20 10 20C15.5219 20 20 15.5239 20 10C20 4.47607 15.5239 0 10 0ZM3.9604 16.0396C1.47896 13.5582 1.47896 9.53589 3.9604 7.05652C6.44183 4.57508 10.4641 4.57508 12.9435 7.05652C15.4249 9.53795 15.4249 13.5602 12.9435 16.0396C10.462 18.521 6.43977 18.521 3.9604 16.0396ZM15.955 15.6229C15.3981 16.1799 14.7731 16.6502 14.0924 17.0215C15.4682 15.6044 16.316 13.6737 16.316 11.547C16.316 7.21122 12.7888 3.68399 8.45503 3.68399C6.33045 3.68399 4.39975 4.53177 2.98267 5.90759C3.35602 5.2269 3.82426 4.6019 4.37913 4.04703C5.13201 3.29414 6.00866 2.70421 6.98226 2.29167C7.99092 1.86469 9.06147 1.6481 10.1671 1.6481C11.2727 1.6481 12.3453 1.86469 13.354 2.29167C14.3276 2.70421 15.2042 3.29414 15.9571 4.04703C16.7079 4.79786 17.2999 5.67451 17.7125 6.6481C18.1394 7.6547 18.354 8.72731 18.354 9.83292C18.354 10.9385 18.1394 12.0111 17.7125 13.0177C17.2999 13.9934 16.71 14.868 15.9571 15.6209L15.955 15.6229Z"
          fill="var(--colors-turquoise500)"
        />
      </g>
      <defs>
        <clipPath id="clip0_14337_7298">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </Svg>
  );
};
