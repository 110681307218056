import { Svg, SvgProps } from './Svg';

export const Dropdown = ({
  fill = '#EEF2F2',
  height = '6',
  viewBox = '0 0 11 6',
  width = '11',
  xmlns = 'http://www.w3.org/2000/svg',
  ...rest
}: SvgProps) => {
  return (
    <Svg {...rest} {...{ fill, height, viewBox, width, xmlns }}>
      <path d="M0.878906 0.980787C0.878906 0.808563 0.948661 0.636256 1.08817 0.505006C1.36719 0.242085 1.81921 0.242085 2.09823 0.505006L5.87896 4.06853L9.65958 0.505006C9.93861 0.242085 10.3906 0.242085 10.6696 0.505006C10.9487 0.767927 10.9487 1.19386 10.6696 1.45678L6.38388 5.49524C6.10486 5.75816 5.65284 5.75816 5.37382 5.49524L1.08806 1.45678C0.948103 1.32574 0.878906 1.15326 0.878906 0.980787Z" />
    </Svg>
  );
};
