import { VariantProps, tv } from 'tailwind-variants';
import { HTMLProps } from 'react';
export interface TabsProps
  extends VariantProps<typeof tabStyles>,
    HTMLProps<HTMLDivElement> {}

const tabStyles = tv({
  defaultVariants: { hideBorder: false },
  slots: {
    container:
      'flex gap-4 box-border p-0 filter-[drop-shadow($space$0 $space$px $space$0 #525252)]',
    item: 'box-border font-semibold',
  },
  variants: {
    fullWidth: { true: { container: 'w-full' } },
    hideBorder: {
      false: {
        container: 'border-b-[1px] border-solid border-b-neutral-500',
        item: 'pb-[6px]',
      },
      true: { container: 'border-0' },
    },
    isActive: {
      false: { item: 'text-neutral-500 cursor-pointer' },
      true: {
        item: 'text-light border-b-[1px] border-solid border-b-turquoise-500',
      },
    },
  },
});
export const TabsContainer = ({
  children,
  className,
  fullWidth,
  hideBorder = false,
  ...rest
}: TabsProps) => {
  const { container } = tabStyles();
  return (
    <div
      className={container({ class: className, fullWidth, hideBorder })}
      {...rest}
    >
      {children}
    </div>
  );
};
export const TabItem = ({
  children,
  className,
  hideBorder,
  isActive,
  ...rest
}: TabsProps) => {
  const { item } = tabStyles();
  return (
    <div className={item({ class: className, hideBorder, isActive })} {...rest}>
      {children}
    </div>
  );
};
