import { Svg, SvgProps } from './Svg';

export const CloseIcon = (props: SvgProps) => {
  return (
    <Svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M16.0288 13.2702C16.6537 13.8952 16.6537 14.9076 16.0288 15.5325C15.7188 15.845 15.3088 16 14.8989 16C14.4889 16 14.0799 15.8438 13.768 15.5313L8.49938 10.2654L3.23129 15.53C2.91881 15.845 2.50934 16 2.09988 16C1.69041 16 1.28144 15.845 0.968713 15.53C0.343762 14.9051 0.343762 13.8927 0.968713 13.2677L6.2383 7.99813L0.968713 2.73104C0.343762 2.10609 0.343762 1.09366 0.968713 0.468713C1.59366 -0.156238 2.60609 -0.156238 3.23104 0.468713L8.49938 5.7408L13.769 0.471213C14.3939 -0.153738 15.4063 -0.153738 16.0313 0.471213C16.6562 1.09616 16.6562 2.10859 16.0313 2.73354L10.7617 8.00312L16.0288 13.2702Z" />
    </Svg>
  );
};
