/* eslint-disable no-continue */

/* eslint-disable no-await-in-loop */
import uriToHttp from './uriToHttp';
import { TokenList } from '@vapordex/tokenlist';

/**
 * Contains the logic for resolving a list URL to a validated token list
 * @param listUrl list url
 */
export default async function getTokenList(
  listUrl: string,
): Promise<TokenList> {
  const urls: string[] = uriToHttp(listUrl);

  for (let i = 0; i < urls.length; i++) {
    const url = urls[i];
    const isLast = i === urls.length - 1;
    let res;
    try {
      res = await fetch(url);
    } catch (error) {
      console.error('Failed to fetch list', listUrl, error);
      if (isLast) throw new Error(`Failed to download list ${listUrl}`);
      continue;
    }

    if (!res.ok) {
      if (isLast) throw new Error(`Failed to download list ${listUrl}`);
      continue;
    }

    const json = await res.json();

    return json as TokenList;
  }
  throw new Error('Unrecognized list URL protocol.');
}
