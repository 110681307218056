export const numberToAbbreviation = (num: number): string => {
  const roundedNum = Math.floor(Math.abs(num));
  const power = Math.floor(Math.log10(roundedNum) / 3);
  const scaledNum = roundedNum / Math.pow(1000, power);

  const numberWithAbb = `${num < 0 ? -1 : 1 * +scaledNum.toFixed(2)}${
    ['', 'k', 'm', 'b', 't'][power]
  }`;
  return numberWithAbb;
};

export const removeNonNumbers = (value: string, replaceWith: string = '') => {
  if (!Boolean(value)) return value;
  return value.replaceAll(/\D/g, replaceWith);
};

export const parseFormFields = <T>(fields: T) => {
  if (!fields) return {};
  return Object.keys(fields).reduce(
    (acc, curr) => {
      acc[curr] = fields?.[curr]?.[0];
      return acc;
    },
    {} as Record<string, string>,
  );
};
