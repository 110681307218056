import { forwardRef } from 'react';
import { tv, VariantProps } from 'tailwind-variants';
import { HTMLProps } from 'react';

const spanStyles = tv({
  variants: {
    color: {
      black: 'text-black',
      blue: 'text-blue-500',
      light: 'text-light',
      neutral: 'text-neutral-500',
      red: 'text-red',
      turquoise: 'text-turquoise-500',
    },
    font: {
      manverse: 'font-manverse',
      manverseOutline: 'font-manverseOutline',
    },
    weight: {
      bold: 'font-bold',
      normal: 'font-normal',
      semibold: 'font-semibold',
    },
  },
});
export interface SpanProps
  extends VariantProps<typeof spanStyles>,
    Omit<HTMLProps<HTMLSpanElement>, 'color'> {}

export const Span = forwardRef<HTMLSpanElement, SpanProps>(
  ({ className, color, font, weight, ...rest }: SpanProps, ref) => {
    return (
      <span
        {...rest}
        className={spanStyles({ className, color, font, weight })}
        ref={ref}
      />
    );
  },
);
