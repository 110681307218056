import { createReducer } from '@reduxjs/toolkit';

import { selectPercent } from './actions';

interface BurnV2State {
  readonly percent: number;
}

const initialState: BurnV2State = {
  percent: 0,
};

export default createReducer<BurnV2State>(initialState, (builder) =>
  builder.addCase(selectPercent, (state, { payload: { percent } }) => {
    return {
      ...state,
      percent,
    };
  }),
);
