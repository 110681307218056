import React from 'react';
import { Checkbox, Button, Typography, AlertCard, Modal } from '@vaporfi/uikit';

interface ExpertModalViewProps {
  isRememberChecked: boolean;
  onRememberChange: () => void;
  onConfirm: () => void;
  onCancel: () => void;
  onBack: () => void;
}

export const ExpertModalView: React.FC<ExpertModalViewProps> = ({
  isRememberChecked,
  onBack,
  onCancel,
  onConfirm,
  onRememberChange,
}) => {
  return (
    <Modal
      title="Expert Mode"
      titleClassName="text-lg font-semibold"
      onDismiss={onCancel}
      showBackIcon={true}
      className="border-cardBorder max-w-[360px] border border-solid bg-black"
      onBack={onBack}
      body={
        <>
          <AlertCard variant="warning" className="mb-6 mt-14">
            <Typography>
              Expert mode turns off the &apos;Confirm&apos; transaction prompt,
              and allows high slippage trades that often result in bad rates and
              lost funds.
            </Typography>
          </AlertCard>
          <Typography className="mb-6">
            Only use this mode if you know what you&apos;re doing.
          </Typography>
          <div className="mb-6 flex items-center">
            <Checkbox
              data-testid="expert-mode-checkbox"
              name="confirmed"
              type="checkbox"
              checked={isRememberChecked}
              onChange={onRememberChange}
            />
            <Typography className="ml-2.5" style={{ userSelect: 'none' }}>
              Don&apos;t show this again
            </Typography>
          </div>
          <div className="flex flex-col space-y-2">
            <Button
              size="base"
              variant="filled"
              color="blue"
              data-testid="confirm-expert-mode"
              onClick={onConfirm}
            >
              <Typography>Turn On Expert Mode</Typography>
            </Button>
            <Button
              size="base"
              color="light"
              variant="outline"
              data-testid="cancel-expert-mode"
              onClick={onCancel}
            >
              <Typography>Cancel</Typography>
            </Button>
          </div>
        </>
      }
    />
  );
};
