'use client';

import { Container, Grid, GridProps, LoadingSpinner } from '@vaporfi/uikit';
import React, { forwardRef, useEffect, useRef } from 'react';
import dynamic from 'next/dynamic';
import { Address } from 'viem';
import { useIsIntersecting } from '@vaporfi/hooks';
import { cn } from '@vaporfi/utils';
import { MoarCandyToken } from '@vaporfi/db';

const TokenCard = dynamic(() => import('./Card'), {
  loading: () => <div>Loading</div>,
});

export type GetTokensResponse = {
  after: string | undefined;
  data: MoarCandyToken[];
  hasNextPage: boolean;
};

const TokenCardList = forwardRef<
  HTMLDivElement,
  {
    replyCounts: Record<Address, number>;
    fetchNextPage: any;
    result: GetTokensResponse;
  } & GridProps
>(({ fetchNextPage, replyCounts, result, ...rest }, ref) => {
  const spinnerRef = useRef<HTMLDivElement | null>(null);
  const isVisible = useIsIntersecting(spinnerRef);

  useEffect(() => {
    if (isVisible && result?.hasNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, isVisible, result]);

  return (
    <Grid
      {...rest}
      ref={ref}
      isContainer
      className={cn(
        'grid-cols-1 gap-4 max-sm:justify-center md:grid-cols-2 lg:grid-cols-3 lg:gap-6',
      )}
    >
      {result?.data?.map((token) => (
        <TokenCard
          key={token?.id}
          {...token}
          deployed={String(token.deployed)}
          buys={token.buys.toString()}
          sells={token.sells.toString()}
          replies={
            token && token?.id
              ? replyCounts?.[token.id as keyof typeof replyCounts] ?? 0
              : 0
          }
        />
      ))}
      {result?.hasNextPage && (
        <Container ref={spinnerRef} className="flex justify-center" fullWidth>
          <LoadingSpinner />
        </Container>
      )}
    </Grid>
  );
});

export default TokenCardList;
