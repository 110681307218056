import { colors } from './color';
import { ThemeOptions } from '@mui/material/styles';

const darkThemeOptions: ThemeOptions = {
  components: {
    // MuiTextField: {
    //   variants: {
    //     outlined: {
    //       backgroundColor: colors.named.none,
    //     }
    //   }
    MuiAlert: {
      styleOverrides: {
        root: {
          // background: DefaultColors.Color.BackgroundTwo,
        },
      },
    },
    // },
    MuiButton: {
      styleOverrides: {
        contained: {
          '&:hover': {
            backgroundColor: colors.blue['200'],
            borderColor: colors.blue['200'],
          },
          backgroundColor: colors.blue['500'],
          borderColor: colors.blue['500'],
          borderStyle: 'solid',
        },
        outlined: {
          backgroundColor: colors.named.none,
          borderColor: colors.named.light,
          borderStyle: 'solid',
        },
        root: {
          '&:hover': {
            '> svg': {
              color: colors.turquoise['500'],
              transform: 'all 0.3s ease-in-out',
            },
            borderWidth: 2,
            color: 'primary',
          },
          borderRadius: 100,
          borderWidth: 2,
          color: colors.named.light,
          fontWeight: 'bold',
          minWidth: 42,
          textTransform: 'unset',
        },
        sizeSmall: { paddingX: 1, paddingY: 0.5 },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        color: colors.named.light,
        disableRipple: true,
      },
    },
    MuiCard: {
      // This is not working... for some reason
      styleOverrides: {
        root: {
          backgroundImage: 'unset',
          borderRadius: 4,
          boxShadow: 'unset',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          background: colors.named.blackBg,
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        docked: {},
        paperAnchorDockedLeft: {
          margin: [16, 16, 0, 16],
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: 'unset',
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          minHeight: '24px !important',
        },
      },
    },
  },
  palette: {
    background: {
      default: colors.named.blackBg,
      paper: colors.named.black,
    },
    mode: 'dark',
    primary: {
      main: colors.turquoise['500'],
    },
    secondary: {
      main: colors.neutral['300'],
    },
    success: {
      main: colors.green['500'],
    },
  },
  typography: {
    body1: {
      color: colors.named.light,
    },
    body2: {
      color: colors.named.white,
    },
    caption: {
      color: colors.neutral['500'],
      fontSize: 14,
    },
    fontFamily: 'inherit',
  },
};

export default darkThemeOptions;
