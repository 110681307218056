import { Grid } from '@vaporfi/uikit';
import { HeaderItem } from './Header';

export function Skeleton() {
  return (
    <Grid
      isContainer
      className="h-full w-full grid-cols-6 rounded-lg bg-white px-4 py-2"
    >
      <HeaderItem>Loading</HeaderItem>
      <HeaderItem className={'text-green'}>Loading</HeaderItem>
      <HeaderItem>Loading</HeaderItem>
      <HeaderItem>Loading</HeaderItem>
      <HeaderItem>Loading</HeaderItem>
      <HeaderItem>Loading</HeaderItem>
    </Grid>
  );
}
