/* eslint-disable perfectionist/sort-objects */
import JSBI from 'jsbi';
import { toHex } from './utils/calldata';

export enum MINING_PASS_LEVEL {
  ZERO = 0,
  ONE = 1,
  TWO = 2,
  THREE = 3,
  FOUR = 4,
  FIVE = 5,
  SIX = 6,
  SEVEN = 7,
  EIGHT = 8,
  NINE = 9,
  TEN = 10,
}

export const MaxDeposit: { [level in MINING_PASS_LEVEL]: string } = {
  [MINING_PASS_LEVEL.ZERO]: '5000',
  [MINING_PASS_LEVEL.ONE]: '10000',
  [MINING_PASS_LEVEL.TWO]: '25000',
  [MINING_PASS_LEVEL.THREE]: '60000',
  [MINING_PASS_LEVEL.FOUR]: '150000',
  [MINING_PASS_LEVEL.FIVE]: '350000',
  [MINING_PASS_LEVEL.SIX]: '800000',
  [MINING_PASS_LEVEL.SEVEN]: '1800000',
  [MINING_PASS_LEVEL.EIGHT]: '4500000',
  [MINING_PASS_LEVEL.NINE]: '12000000',
  [MINING_PASS_LEVEL.TEN]: '',
};

export const MINING_PASS_COST: { [level in MINING_PASS_LEVEL]: string } = {
  [MINING_PASS_LEVEL.ZERO]: '0',
  [MINING_PASS_LEVEL.ONE]: '0.5',
  [MINING_PASS_LEVEL.TWO]: '1',
  [MINING_PASS_LEVEL.THREE]: '2',
  [MINING_PASS_LEVEL.FOUR]: '4',
  [MINING_PASS_LEVEL.FIVE]: '8',
  [MINING_PASS_LEVEL.SIX]: '15',
  [MINING_PASS_LEVEL.SEVEN]: '30',
  [MINING_PASS_LEVEL.EIGHT]: '50',
  [MINING_PASS_LEVEL.NINE]: '75',
  [MINING_PASS_LEVEL.TEN]: '100',
};

export enum BoostLevel {
  BASE = 0,
  ONE = 1,
  TWO = 2,
  THREE = 3,
}

export const BOOST_COST: { [level in BoostLevel]: number } = {
  [BoostLevel.BASE]: 0, //0 USDC
  [BoostLevel.ONE]: 0.1, // 0.10 USDC
  [BoostLevel.TWO]: 0.15, // 0.15 USDC
  [BoostLevel.THREE]: 0.2, // 0.20 USDC
};

export enum StratosphereTiers {
  BASIC = 0,
  SILVER = 1,
  GOLD = 2,
  PLATINUM = 3,
  DIAMOND = 4,
  OBSIDIAN = 5,
}
export const ADDITIONAL_BOOST = {
  [StratosphereTiers.BASIC]: {
    [BoostLevel.ONE]: '10%', //these are not taking part in calculation, treat as a constant
    [BoostLevel.TWO]: '20%',
    [BoostLevel.THREE]: '40%',
  },
  [StratosphereTiers.SILVER]: {
    [BoostLevel.ONE]: '11%',
    [BoostLevel.TWO]: '21%',
    [BoostLevel.THREE]: '41%',
  },
  [StratosphereTiers.GOLD]: {
    [BoostLevel.ONE]: '12%',
    [BoostLevel.TWO]: '22%',
    [BoostLevel.THREE]: '42%',
  },
  [StratosphereTiers.PLATINUM]: {
    [BoostLevel.ONE]: '13%',
    [BoostLevel.TWO]: '23%',
    [BoostLevel.THREE]: '43%',
  },
  [StratosphereTiers.DIAMOND]: {
    [BoostLevel.ONE]: '14%',
    [BoostLevel.TWO]: '24%',
    [BoostLevel.THREE]: '44%',
  },
  [StratosphereTiers.OBSIDIAN]: {
    [BoostLevel.ONE]: '15%',
    [BoostLevel.TWO]: '25%',
    [BoostLevel.THREE]: '45%',
  },
};

export const BOOST_FOR_NON_STRATOSPHERE_MEMBER = '0.1';

export const BOOST_IMPACT: {
  [tier in StratosphereTiers]: { [level in BoostLevel]: string };
} = {
  [StratosphereTiers.BASIC]: {
    [BoostLevel.BASE]: '0.2',
    [BoostLevel.ONE]: '0.22',
    [BoostLevel.TWO]: '0.24',
    [BoostLevel.THREE]: '0.28',
  },
  [StratosphereTiers.SILVER]: {
    [BoostLevel.BASE]: '0.25',
    [BoostLevel.ONE]: '0.28',
    [BoostLevel.TWO]: '0.30',
    [BoostLevel.THREE]: '0.35',
  },
  [StratosphereTiers.GOLD]: {
    [BoostLevel.BASE]: '0.33',
    [BoostLevel.ONE]: '0.37',
    [BoostLevel.TWO]: '0.40',
    [BoostLevel.THREE]: '0.47',
  },
  [StratosphereTiers.PLATINUM]: {
    [BoostLevel.BASE]: '0.45',
    [BoostLevel.ONE]: '0.51',
    [BoostLevel.TWO]: '0.55',
    [BoostLevel.THREE]: '0.64',
  },
  [StratosphereTiers.DIAMOND]: {
    [BoostLevel.BASE]: '0.65',
    [BoostLevel.ONE]: '0.74',
    [BoostLevel.TWO]: '0.81',
    [BoostLevel.THREE]: '0.94',
  },
  [StratosphereTiers.OBSIDIAN]: {
    [BoostLevel.BASE]: '1',
    [BoostLevel.ONE]: '1.15',
    [BoostLevel.TWO]: '1.25',
    [BoostLevel.THREE]: '1.45',
  },
};

export const BIG_INT_ZERO = JSBI.BigInt(0);
export const ZERO_HEX = toHex(BIG_INT_ZERO);
export const HUNDRED = JSBI.BigInt(100);
export const BIG_INT_10_POWER_18 = JSBI.exponentiate(
  JSBI.BigInt(10),
  JSBI.BigInt(18),
);
