import React from 'react';
import { Button, Typography } from '@vaporfi/uikit';
import { QuestionHelper } from './QuestionHelper';
import { GAS_PRICE_GWEI, GAS_PRICE } from '@vaporfi/utils';

interface GasSettingsViewProps {
  gasPrice: string;
  setGasPrice: (gasPrice: string) => void;
}

export const GasSettingsView: React.FC<GasSettingsViewProps> = ({
  gasPrice,
  setGasPrice,
}) => {
  return (
    <div className="flex flex-col">
      <div className="mb-3 flex items-center">
        <Typography>Default Transaction Speed (GWEI)</Typography>
        <QuestionHelper
          text={`Adjusts the gas price (transaction fee) for your transaction. Higher GWEI = higher speed = higher fees`}
          placement="top-start"
          className="ml-1"
        />
      </div>
      <div className="flex flex-wrap">
        <Button
          size="base"
          variant={gasPrice === GAS_PRICE_GWEI.default ? 'filled' : 'outline'}
          color={gasPrice === GAS_PRICE_GWEI.default ? 'blue' : 'light'}
          data-testid="gas-settings-standard-btn"
          className="mr-1 mt-1"
          onClick={() => setGasPrice(GAS_PRICE_GWEI.default)}
        >
          <Typography>Standard {GAS_PRICE.default}</Typography>
        </Button>
        <Button
          size="base"
          variant={gasPrice === GAS_PRICE_GWEI.fast ? 'filled' : 'outline'}
          color={gasPrice === GAS_PRICE_GWEI.fast ? 'blue' : 'light'}
          data-testid="gas-settings-fast-btn"
          className="mr-1 mt-1"
          onClick={() => setGasPrice(GAS_PRICE_GWEI.fast)}
        >
          <Typography>Fast {GAS_PRICE.fast}</Typography>
        </Button>
        <Button
          size="base"
          variant={gasPrice === GAS_PRICE_GWEI.instant ? 'filled' : 'outline'}
          color={gasPrice === GAS_PRICE_GWEI.instant ? 'blue' : 'light'}
          data-testid="gas-settings-instant-btn"
          className="mr-1 mt-1"
          onClick={() => setGasPrice(GAS_PRICE_GWEI.instant)}
        >
          <Typography>Instant {GAS_PRICE.instant}</Typography>
        </Button>
      </div>
    </div>
  );
};
