import Container from './Container';
import { useMatchBreakpointsContext } from '@vapordex/uikit';
import { DEFAULT_META, getCustomMeta } from 'config/constants/meta';
import Head from 'next/head';
import { useRouter } from 'next/router';
import styled from 'styled-components';

const StyledPage = styled(Container)`
  padding-top: 16px;
  padding-bottom: 16px;
  ${({ theme }) => theme.mediaQueries.sm} {
    padding-top: 24px;
    padding-bottom: 24px;
  }
  ${({ theme }) => theme.mediaQueries.lg} {
    padding-top: 32px;
    padding-bottom: 32px;
  }
`;

export const PageMeta: React.FC<
  React.PropsWithChildren<{ symbol?: string }>
> = ({ symbol }) => {
  const { pathname } = useRouter();

  const pageMeta = getCustomMeta(pathname) || {};
  const { description, image, title } = { ...DEFAULT_META, ...pageMeta };

  let pageTitle = title;
  if (symbol) {
    pageTitle = [symbol, title].join(' - ');
  }

  return (
    <Head>
      <title>{pageTitle}</title>
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
    </Head>
  );
};

interface PageProps extends React.HTMLAttributes<HTMLDivElement> {
  symbol?: string;
}

const Page = ({ children, symbol, ...props }: PageProps) => {
  const { isMobile } = useMatchBreakpointsContext();
  return (
    <>
      <PageMeta symbol={symbol} />
      <StyledPage
        style={{
          minHeight: isMobile ? 'calc(100vh - 164px)' : 'calc(100vh - 64px)',
        }}
        {...props}
      >
        {children}
      </StyledPage>
    </>
  );
};

export default Page;
