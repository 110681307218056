import {
  Box,
  Container,
  ContainerProps,
  Grid,
  typographyStyles,
} from '@vaporfi/uikit';
import Link, { LinkProps } from 'next/link';
import { VariantProps, tv } from 'tailwind-variants';
import { Login } from '../Modals';
import { cn } from '@vaporfi/utils';
import { Address } from 'viem';
import { ReactNode } from 'react';

export const HeaderLink = ({
  className,
  href,
  label,
  ...rest
}: LinkProps & { label: string; className?: string }) => {
  return (
    <Link
      href={href}
      target="_blank"
      className={typographyStyles({
        className: cn('w-fit', className),
        weight: 'bold',
      })}
      {...rest}
    >
      {label}
    </Link>
  );
};

const headerStyles = tv({
  defaultVariants: { color: 'blue550' },
  slots: { wrapper: 'gap-4 lg:gap-7 py-4' },
  variants: { color: { blue550: { wrapper: 'bg-blue-500' } } },
});

export interface HeaderProps
  extends Omit<ContainerProps, 'color'>,
    VariantProps<typeof headerStyles> {
  login?: Login;
  logout?: () => void;
  isConnected: boolean;
  account: Address;
  leftItems: () => ReactNode;
  renderWalletMenu?: () => ReactNode;
}

const Header = ({
  account,
  children,
  className,
  color,
  isConnected,
  leftItems,
  login,
  logout,
  renderWalletMenu,
  ...rest
}: HeaderProps) => {
  const { wrapper } = headerStyles({});
  return (
    <Container stack="column" fullWidth className={cn(wrapper())} {...rest}>
      <Box className="flex items-center justify-between px-4">
        <Grid
          isContainer
          className="auto-cols-fit grid-flow-col items-center gap-4 lg:gap-14"
        >
          {leftItems?.()}
        </Grid>

        {renderWalletMenu?.()}
      </Box>
      {children}
    </Container>
  );
};

export default Header;
