import { AlertCard, Typography } from '@vaporfi/uikit';
import { memo } from 'react';

export const NoLiquidityAlert = memo(() => {
  return (
    <AlertCard variant="warning" showIcon>
      <Typography>
        You are the first liquidity provider.{' '}
        <span className="font-semibold">
          The ratio of tokens you add will set the price of this pool.
        </span>
      </Typography>
    </AlertCard>
  );
});
