import React from 'react';
import { Typography, Button, LinkExternal, HelpIcon } from '@vaporfi/uikit';

interface WalletWrongNetworkViewProps {
  onSwitchNetwork: () => void;
  onLearnMore: () => void;
}

export const WalletWrongNetworkView: React.FC<WalletWrongNetworkViewProps> = ({
  onLearnMore,
  onSwitchNetwork,
}) => {
  return (
    <>
      <Typography className="mb-6">
        You’re connected to the wrong network.
      </Typography>

      <Button
        color="blue"
        size="base"
        className="mb-6 w-full"
        onClick={onSwitchNetwork}
      >
        <Typography color="light" weight="semibold">
          Switch Network
        </Typography>
      </Button>

      <LinkExternal className="w-full" onClick={onLearnMore}>
        <Button variant="outline" color="light" size="base">
          <Typography color="light" weight="semibold">
            Learn How
          </Typography>
          <HelpIcon color="primary" className="ml-1.5" />
        </Button>
      </LinkExternal>
    </>
  );
};
