import Svg from '../Svg';
import { SvgProps } from '../types';

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 16 16" {...props}>
      <path
        d="M2 12.5a.5.5 0 0 0 .5.5H15a.999.999 0 1 1 0 2H2.5A2.5 2.5 0 0 1 0 12.5V2a1 1 0 0 1 2 0v10.5Zm8.706-3.794a.998.998 0 0 1-1.412 0L7.5 6.916l-2.794 2.79a.998.998 0 1 1-1.412-1.412l3.5-3.5a.998.998 0 0 1 1.412 0L10 6.584l3.294-3.29a.998.998 0 1 1 1.413 1.412l-4 4Z"
        fill="currentColor"
      />
    </Svg>
  );
};

export default Icon;
