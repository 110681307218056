import getTokenList from '@/utils/getTokenList';
import { useAppDispatch } from '../state';
import { fetchTokenList } from '../state/lists/actions';
import { nanoid } from '@reduxjs/toolkit';
import { TokenList } from '@vapordex/tokenlist';
import { useCallback } from 'react';

function useFetchListCallback(): (
  listUrl: string,
  sendDispatch?: boolean,
) => Promise<TokenList> {
  const dispatch = useAppDispatch();

  // note: prevent dispatch if using for list search or unsupported list
  return useCallback(
    async (listUrl: string, sendDispatch = true) => {
      const requestId = nanoid();
      if (sendDispatch) {
        dispatch(fetchTokenList.pending({ requestId, url: listUrl }));
      }

      return getTokenList(listUrl)
        .then((tokenList) => {
          if (sendDispatch) {
            dispatch(
              fetchTokenList.fulfilled({ requestId, tokenList, url: listUrl }),
            );
          }
          return tokenList;
        })
        .catch((error) => {
          console.error(`Failed to get list at url ${listUrl}`, error);
          if (sendDispatch) {
            dispatch(
              fetchTokenList.rejected({
                errorMessage: error.message,
                requestId,
                url: listUrl,
              }),
            );
          }
          throw error;
        });
    },
    [dispatch],
  );
}

export default useFetchListCallback;
