import { styled } from '../../stitches.config';
export const Banner = styled('div', {
  alignItems: 'center',
  borderRadius: '5px',
  display: 'flex',
  flexDirection: 'row',
  gap: '16px',
  height: '42px',
  marginBottom: '30px',
  padding: '8px 16px',
  variants: {
    for: {
      stratosphere: {
        background: '$gradient',
      },
    },
  },
  width: '100%',
});

export const BannerText = styled('div', {
  color: '#ffffff',
  display: 'flex',
  flex: 'none',
  flexGrow: 1,
  fontSize: '16px;',
  fontStyle: 'normal',
  fontWeight: 600,
  gap: '5px',
  height: '26px',
  lineHeight: '26px',
  order: 0,
  width: '643px',
});
