import { Svg, SvgProps } from './Svg';

export const ErrorIcon = ({
  fill = 'none',
  height = '20',
  viewBox = '0 0 20 20',
  width = '20',
  xmlns = 'http://www.w3.org/2000/svg',
  ...rest
}: SvgProps) => {
  return (
    <Svg {...rest} {...{ fill, height, viewBox, width, xmlns }}>
      <g id="icon" clipPath="url(#clip0_11870_4862)">
        <g id="icons">
          <path
            id="Vector"
            d="M10 0C4.47742 0 0 4.47742 0 10C0 15.5226 4.47742 20 10 20C15.5226 20 20 15.5226 20 10C20 4.47742 15.5226 0 10 0ZM8.70968 6.12903C8.70968 5.40645 9.29032 4.83871 10 4.83871C10.7097 4.83871 11.2903 5.40645 11.2903 6.12903V9.97419C11.2903 10.6839 10.7097 11.2645 10 11.2645C9.29032 11.2645 8.70968 10.6839 8.70968 9.97419V6.12903ZM10 15.0194C9.23871 15.0194 8.61935 14.4 8.61935 13.6387C8.61935 12.8774 9.23871 12.2452 10 12.2452C10.7613 12.2452 11.3935 12.8645 11.3935 13.6387C11.3935 14.4129 10.7742 15.0194 10 15.0194Z"
            fill="var(--colors-red)"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_11870_4862">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </Svg>
  );
};
