import { Svg, SvgProps } from './Svg';

export const InfoIcon = ({
  fill = 'var(--colors-turquoise500)',
  height = '20',
  viewBox = '0 0 20 20',
  width = '20',
  xmlns = 'http://www.w3.org/2000/svg',
  ...rest
}: SvgProps) => {
  return (
    <Svg
      {...rest}
      fill={fill}
      height={height}
      viewBox={viewBox}
      width={width}
      xmlns={xmlns}
    >
      <path d="M10 20C15.5234 20 20 15.5234 20 10C20 4.47656 15.5234 0 10 0C4.47656 0 0 4.47656 0 10C0 15.5234 4.47656 20 10 20ZM8.4375 13.125H9.375V10.625H8.4375C7.91797 10.625 7.5 10.207 7.5 9.6875C7.5 9.16797 7.91797 8.75 8.4375 8.75H10.3125C10.832 8.75 11.25 9.16797 11.25 9.6875V13.125H11.5625C12.082 13.125 12.5 13.543 12.5 14.0625C12.5 14.582 12.082 15 11.5625 15H8.4375C7.91797 15 7.5 14.582 7.5 14.0625C7.5 13.543 7.91797 13.125 8.4375 13.125ZM10 7.5C9.30859 7.5 8.75 6.94141 8.75 6.25C8.75 5.55859 9.30859 5 10 5C10.6914 5 11.25 5.55859 11.25 6.25C11.25 6.94141 10.6914 7.5 10 7.5Z" />
    </Svg>
  );
};
