import useMatchBreakpoints, {
  type BreakpointChecks,
} from '../../hooks/useMatchBreakpoints';
import { createContext } from 'react';

export const MatchBreakpointsContext = createContext<BreakpointChecks>({
  isDesktop: false,
  isMobile: false,
  isTablet: false,
});

export const MatchBreakpointsProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const state = useMatchBreakpoints();

  return (
    <MatchBreakpointsContext.Provider value={state}>
      {children}
    </MatchBreakpointsContext.Provider>
  );
};
