import {
  AlertCard,
  Box,
  Button,
  Container,
  Slider,
  Typography,
} from '@vaporfi/uikit';
import React, { ReactNode } from 'react';
import { SingleRowStats, SingleRowStatsProps } from '../Stats';

export enum Field {
  LIQUIDITY_PERCENT = 'LIQUIDITY_PERCENT',
  LIQUIDITY = 'LIQUIDITY',
  CURRENCY_A = 'CURRENCY_A',
  CURRENCY_B = 'CURRENCY_B',
}
export interface RemoveLiquidityProps {
  buttons: () => ReactNode;
  onUserInput: (field: Field, value: string) => void;
  currencyInputPanelA: () => JSX.Element;
  currencyInputPanelB: () => JSX.Element;
  liquidityPercentage: number;
  setLiquidityPercentage: (percentage: number) => void;
  showSingleRowStats: boolean;
  singleRowStatsProps: SingleRowStatsProps;
}
export const RemoveLiquidity = ({
  buttons,
  currencyInputPanelA,
  currencyInputPanelB,
  liquidityPercentage,
  onUserInput,
  setLiquidityPercentage,
  showSingleRowStats,
  singleRowStatsProps,
}: RemoveLiquidityProps) => {
  return (
    <Container stack="column" className="gap-5 lg:gap-[30px]">
      {currencyInputPanelA()}
      {currencyInputPanelB()}
      <AlertCard variant="plain" className="w-full">
        <Container stack="column" fullWidth className="gap-7">
          <Slider
            value={[liquidityPercentage]}
            onChange={setLiquidityPercentage}
          />

          <Box className="flex justify-evenly">
            <Button
              data-testid="remove-liquidity-25-percent-btn"
              color="light"
              size="sm"
              variant="outline"
              onClick={() => onUserInput(Field.LIQUIDITY_PERCENT, '25')}
            >
              <Typography>25%</Typography>
            </Button>
            <Button
              data-testid="remove-liquidity-50-percent-btn"
              variant="outline"
              color="light"
              size="sm"
              onClick={() => onUserInput(Field.LIQUIDITY_PERCENT, '50')}
            >
              <Typography>50%</Typography>
            </Button>
            <Button
              data-testid="remove-liquidity-75-percent-btn"
              variant="outline"
              color="light"
              size="sm"
              onClick={() => onUserInput(Field.LIQUIDITY_PERCENT, '75')}
            >
              <Typography>75%</Typography>
            </Button>
            <Button
              data-testid="remove-liquidity-100-percent-btn"
              variant="outline"
              color="light"
              size="sm"
              onClick={() => onUserInput(Field.LIQUIDITY_PERCENT, '100')}
            >
              <Typography>{'Max'}</Typography>
            </Button>
          </Box>
        </Container>
      </AlertCard>
      {showSingleRowStats && <SingleRowStats {...singleRowStatsProps} />}

      {buttons()}
    </Container>
  );
};
