'use client';

import React, { forwardRef, memo, useCallback, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Typography,
  BoxProps,
  Container,
  Modal,
} from '@vaporfi/uikit';
import { CurrencyInput, Input } from '../shared';
import { useAddTokenToWallet, useModal } from '@vaporfi/hooks';
import { cn } from '@vaporfi/utils';
import { SwapComponentProps, SwapField } from './types';
import { useFieldValues } from './hooks';

const ResetButton = ({ onReset }: { onReset: () => void }) => {
  return (
    <Button color="light" size="xs" onClick={() => onReset()}>
      <Typography size="xs" underline>
        RESET
      </Typography>
    </Button>
  );
};

interface Token {
  address: string;
  decimals: number;
  logoURI: string;
  symbol: string;
}

export const BuyTokens = ({
  activeField,
  amounts,
  balances,
  clearAll,
  error,
  onBuy,
  onUserInput,
  otherField,
  signer,
  tokens,
}: Omit<SwapComponentProps, 'onUserInput'> & {
  onUserInput: (amount: string) => void;
  userToken: Token;
  balances: { [key in SwapField]: string };
}) => {
  const { addTokenToWallet } = useAddTokenToWallet(signer);

  const handleWatchAsset = useCallback(
    async (token: Token) => {
      try {
        await addTokenToWallet(token);
      } catch (error) {
        if (error instanceof Error) {
          console.error(`Failed: ${error.message}`);
        } else {
          console.error('An unknown error occurred.');
        }
      }
    },
    [addTokenToWallet],
  );

  const {
    candyToken,
    dependentAmount,
    dependentToken,
    independentAmount,
    independentToken,
  } = useFieldValues({ activeField, amounts, otherField, tokens });

  return (
    <Box className="flex w-full flex-col items-center gap-4">
      <CurrencyInput
        logoURL={independentToken?.logo}
        symbol={independentToken?.symbol}
        value={independentAmount}
        onUserInput={onUserInput}
      />
      {otherField && dependentToken && dependentAmount && (
        <Typography
          color="dark"
          size="sm"
        >{`${dependentAmount} ${dependentToken?.symbol}`}</Typography>
      )}
      {amounts?.[activeField] && error && (
        <Typography color="red" size="sm">
          {error}
        </Typography>
      )}
      <Typography color="dark" size="sm">
        Balance: {balances[activeField]} {independentToken?.symbol}
      </Typography>
      <Box className="mt-4 flex w-full justify-between">
        <ResetButton onReset={clearAll} />
        {[1, 3, 10].map((item) => (
          <Button
            key={item}
            color="light"
            size="xs"
            onClick={() => onUserInput(item?.toString())}
          >
            <Typography size="xs" underline>
              {item} {independentToken?.symbol}
            </Typography>
          </Button>
        ))}
      </Box>

      <Button
        className="w-full"
        color="purple"
        variant="squared"
        onClick={() => onBuy?.().finally(() => {})}
      >
        <Typography color="light" size="lg">
          Place Trade
        </Typography>
      </Button>

      <Typography
        data-testid="buy-add-token-button"
        className="mt-2 cursor-pointer"
        color="purple1"
        size="sm"
        onClick={() => handleWatchAsset(candyToken)}
      >
        {`Add`} {candyToken?.symbol} {`to wallet`}
      </Typography>
    </Box>
  );
};

export const SellTokens = ({
  activeField,
  amounts,
  balances,
  clearAll,
  error,
  onBuy,
  onUserInput,
  otherField,
  signer,
  tokens,
}: Omit<SwapComponentProps, 'onUserInput'> & {
  balances: { [key in SwapField]: string };
  onUserInput: (amount: string) => void;
}) => {
  const { addTokenToWallet } = useAddTokenToWallet(signer);

  const handleWatchAsset = useCallback(
    async (token: Token) => {
      try {
        await addTokenToWallet(token);
      } catch (error) {
        if (error instanceof Error) {
          console.error(`Failed: ${error.message}`);
        } else {
          console.error('An unknown error occurred.');
        }
      }
    },
    [addTokenToWallet],
  );

  const onPercentageInput = useCallback(
    (percentage: number) => {
      const parsedBalance = Number(balances[SwapField.TOKEN]);
      if (!parsedBalance || Number.isNaN(parsedBalance)) return;
      const value = (parsedBalance * percentage) / 100;
      return onUserInput(value?.toString());
    },
    [balances, onUserInput],
  );

  const {
    candyToken,
    dependentAmount,
    dependentToken,
    independentAmount,
    independentToken,
  } = useFieldValues({ activeField, amounts, otherField, tokens });

  return (
    <>
      <Box className="flex w-full flex-col items-center gap-4">
        <CurrencyInput
          logoURL={independentToken?.logo}
          symbol={independentToken?.symbol}
          value={independentAmount}
          onUserInput={onUserInput}
        />

        {otherField && dependentToken && dependentAmount && (
          <Typography
            color="dark"
            size="sm"
          >{`${dependentAmount} ${dependentToken?.symbol}`}</Typography>
        )}
        {amounts?.[activeField] && error && (
          <Typography color="red" size="sm">
            {error}
          </Typography>
        )}
        <Typography color="dark" size="sm">
          Balance: {balances[activeField]} {independentToken?.symbol}
        </Typography>
        <Box className="mt-4 flex w-full justify-between">
          <ResetButton onReset={clearAll} />
          {[25, 50, 75, 100].map((item) => (
            <Button
              key={item}
              color="light"
              size="xs"
              onClick={() => onPercentageInput(item)}
            >
              <Typography size="xs" underline>
                {item}%
              </Typography>
            </Button>
          ))}
        </Box>

        <Button
          className="w-full"
          color="purple"
          variant="squared"
          onClick={() => onBuy?.().finally(() => {})}
        >
          <Typography color="light" size="lg">
            Place Trade
          </Typography>
        </Button>

        <Typography
          data-testid="sell-add-token-button"
          className="mt-2 cursor-pointer"
          color="purple1"
          size="sm"
          onClick={() => handleWatchAsset(candyToken)}
        >
          {`Add`} {candyToken?.symbol} {`to wallet`}
        </Typography>
      </Box>
    </>
  );
};

export const SwapWrapper = forwardRef<HTMLDivElement, BoxProps>(
  ({ className, ...props }, ref) => {
    return (
      <Box
        ref={ref}
        className={cn(
          'border-purple-4 flex w-full min-w-fit flex-col items-center gap-4 rounded-xl border-2 border-solid bg-neutral-100 p-4',
          className,
        )}
        {...props}
      />
    );
  },
);

const DEFAULT_SLIPPAGE = 0.1; // 10%

const validate = (_slippage: number) => {
  if (!_slippage || Number.isNaN(_slippage) || _slippage > 1 || _slippage < 0)
    return false;
  return true;
};

export const useSlippage = (): [number, boolean, (slipage: string) => void] => {
  const [slippage, setSlippage] = useState<number>(DEFAULT_SLIPPAGE);
  const set = useCallback((newSlippage: string) => {
    setSlippage(Number.parseFloat(newSlippage ?? ''));
  }, []);

  const isValid = useMemo(() => {
    return validate(slippage);
  }, [slippage]);
  return useMemo(
    () => [isValid ? slippage : DEFAULT_SLIPPAGE, isValid, set],
    [isValid, set, slippage],
  );
};

const SlippageModal = ({ onDismiss }: { onDismiss?: () => void }) => {
  const [slippage, isValid, onUserInput] = useSlippage();
  return (
    <Modal
      title="Set Slippage"
      color="neutral"
      body={
        <Container>
          <Input
            placeholder="0.0"
            pattern="^[0-9]*[.,]?[0-9]*$"
            inputMode="decimal"
            value={slippage}
            onUserInput={onUserInput}
          />
          {!isValid && (
            <Typography color="red" size="sm">
              Invalid slippage. Must be in fraction (0 and 1).
            </Typography>
          )}
        </Container>
      }
      onDismiss={onDismiss}
    />
  );
};

export const Slippage = memo(
  ({ onSwitch, symbol }: { symbol: string; onSwitch: () => void }) => {
    const [onSlippageModal] = useModal(<SlippageModal />);
    return (
      <Box className="flex w-full flex-col items-center gap-4">
        <Box className="text-purple3 mb-4 flex w-full justify-between gap-4 text-sm">
          <Button color="light" size="xs" onClick={onSwitch}>
            <Typography size="xs" underline>
              Switch to {symbol}
            </Typography>
          </Button>
          <Button color="light" size="xs" onClick={onSlippageModal}>
            <Typography size="xs" underline>
              Set max slippage
            </Typography>
          </Button>
        </Box>
      </Box>
    );
  },
);
