import { HTMLProps, forwardRef } from 'react';
import { VariantProps, tv } from 'tailwind-variants';

const checkboxStyles = tv({
  base: 'h-[14px] w-[14px] rounded-full',
  defaultVariants: {
    variant: 'default',
  },
  variants: {
    variant: {
      candy: 'p-0',
      default: 'bg-neutral-700',
    },
  },
});

export interface CheckboxProps
  extends VariantProps<typeof checkboxStyles>,
    HTMLProps<HTMLInputElement> {
  isChecked?: boolean;
  children?: React.ReactNode;
}

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  (
    { children, className, isChecked, variant, ...rest }: CheckboxProps,
    ref,
  ) => {
    return (
      <label
        className={`flex items-center ${
          variant === 'candy' ? '' : checkboxStyles({ variant })
        }`}
      >
        {children || (
          <input
            id="checkbox"
            type="checkbox"
            ref={ref}
            className={checkboxStyles({ class: className })}
            {...rest}
          />
        )}
      </label>
    );
  },
);
