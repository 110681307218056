import Desktop from './Desktop';
import Mobile from './Mobile';
import { useMatchBreakpointsContext } from '@vapordex/uikit';

const Sidebar = () => {
  const { isMobile, isTablet } = useMatchBreakpointsContext();

  return isMobile || isTablet ? <Mobile /> : <Desktop />;
};

export default Sidebar;
