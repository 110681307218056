import { BoxProps } from './types';
import styled from 'styled-components';
import {
  background,
  border,
  layout,
  position,
  space,
  color,
} from 'styled-system';

const Box = styled.div<BoxProps>`
  ${background}
  ${border}
  ${layout}
  ${position}
  ${space}
  ${color}
`;

export default Box;
