import React from 'react';
import { Button, Typography } from '@vaporfi/uikit';

interface WalletTransactionsViewProps {
  hasTransactions: boolean;
  onClearAll: () => void;
  children: React.ReactNode;
}

export const WalletTransactionsView: React.FC<WalletTransactionsViewProps> = ({
  children,
  hasTransactions,
  onClearAll,
}) => {
  return (
    <div className="min-h-[120px]">
      <div className="mb-6 flex items-center justify-between">
        <Typography color="blue" weight="semibold" size="sm">
          RECENT TRANSACTIONS
        </Typography>
        {hasTransactions && (
          <Button onClick={onClearAll} className="px-0">
            <Typography color="light" weight="semibold">
              Clear all
            </Typography>
          </Button>
        )}
      </div>
      {hasTransactions ? (
        children
      ) : (
        <div className="flex items-center justify-center">
          <Typography color="light">No recent transactions</Typography>
        </div>
      )}
    </div>
  );
};
