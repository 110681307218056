import { Container } from '@vaporfi/uikit';
import React, { ReactNode } from 'react';
import { TradesThreadsTabs } from './components';
import { cn } from '@vaporfi/utils';

const TradesThreads = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) => {
  return (
    <Container
      stack="column"
      className={cn('relative gap-4 overflow-hidden', className)}
    >
      <TradesThreadsTabs />
      {children}
    </Container>
  );
};

export default TradesThreads;
