import { AlertCard, Typography } from '@vaporfi/uikit';
import { LAUNCHING_A_TOKEN_URL } from '@vaporfi/utils';
import Link from 'next/link';
import React from 'react';

export const LaunchingNewTokenAlert = () => {
  return (
    <AlertCard className="w-full" variant="info">
      <Typography>
        Launching a new token on VaporDEX ? Click{' '}
        <Link
          href={LAUNCHING_A_TOKEN_URL}
          target="_blank"
          referrerPolicy="no-referrer"
        >
          <span className="text-turquoise-500">here</span>{' '}
        </Link>
        to make sure you are ready.
      </Typography>
    </AlertCard>
  );
};
