import { clearUserStates } from '../utils/clearUserStates';
import {
  deleteWalletAccountCookie,
  setNetworkCookie,
} from '@config/constants/networks';
import { ConnectorNames } from '@vapordex/uikit';
import { useCallback } from 'react';
import { useAppDispatch } from 'state';
import { connectorsByName } from 'utils/wagmi';
import { useConnect, useDisconnect } from 'wagmi';
import { usePostHog } from 'posthog-js/react';
import useActiveWagmi from './useActiveWagmi';

const useAuth = () => {
  const dispatch = useAppDispatch();
  const { chainId } = useActiveWagmi();
  const { connect } = useConnect({
    onError: () => {
      posthog.capture('wallet connection error', { chainId });
    },
    onSuccess: ({ account }) => {
      setNetworkCookie(chainId);
      posthog.capture('wallet connected', { account, chainId });
      posthog.identify(account);
    },
  });
  const { disconnect } = useDisconnect({
    onError: () => {
      posthog.capture('wallet disconnection error', { chainId });
    },
    onSuccess: ({ account }) => {
      posthog.capture('wallet disconnected', { account, chainId });
      posthog.reset();
    },
  });
  const posthog = usePostHog();

  const login = useCallback(
    (connectId: ConnectorNames) => {
      const connector = connectorsByName[connectId];
      connect({ chainId, connector });
    },
    [connect, chainId],
  );

  const logout = useCallback(() => {
    disconnect();
    clearUserStates(dispatch, chainId, true);
    deleteWalletAccountCookie();
  }, [disconnect, dispatch, chainId]);

  return {
    login,
    logout,
  };
};

export default useAuth;
