'use client';
import {
  Container,
  ContainerProps,
  ModalProps,
  Typography,
  useMedia,
} from '@vaporfi/uikit';
import { Config, ConnectorNames, Login } from './types';
import { useCallback } from 'react';
import {
  connectorLocalStorageKey,
  walletConnectConfig,
  walletLocalStorageKey,
} from './config';
import { tv } from 'tailwind-variants';

//here color refers to the overall modal color
const walletCardStyles = tv({
  slots: { title: '' },
  variants: { color: { neutral: { title: 'text-purple-1' } } },
});

export interface WalletCardProps extends Omit<ContainerProps, 'color'> {
  walletConfig: Config;
  login: Login;
  onDismiss?: () => void;
  color: ModalProps['color'];
}

export const WalletCard = ({
  color,
  login,
  onDismiss,
  walletConfig,
  ...rest
}: WalletCardProps) => {
  const { connectorId, href, icon: Icon, title } = walletConfig;
  const isDesktop = useMedia();

  const onClick = useCallback(() => {
    // TW point to WC on desktop
    if (title === 'Trust Wallet' && walletConnectConfig && isDesktop) {
      login(walletConnectConfig.connectorId);
      localStorage?.setItem(walletLocalStorageKey, walletConnectConfig.title);
      localStorage?.setItem(
        connectorLocalStorageKey,
        walletConnectConfig.connectorId,
      );
      onDismiss?.();
      return;
    }
    if (connectorId === ConnectorNames.OKX && !window.okxwallet && href) {
      window.open(href, '_blank', 'noopener noreferrer');
    }
    if (!window.ethereum && href) {
      window.open(href, '_blank', 'noopener noreferrer');
    } else {
      login(connectorId);
      localStorage?.setItem(walletLocalStorageKey, title);
      localStorage?.setItem(connectorLocalStorageKey, connectorId);
      onDismiss?.();
    }
  }, [connectorId, href, isDesktop, login, onDismiss, title]);
  const { title: titleStyles } = walletCardStyles({ color: color as any });
  return (
    <Container
      onClick={onClick}
      className="mx-auto flex h-auto cursor-pointer flex-col items-center justify-center py-4"
      {...rest}
    >
      <Icon width="40px" />
      <Typography className={titleStyles()}>{title}</Typography>
    </Container>
  );
};
