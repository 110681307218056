import { Svg, SvgProps } from './Svg';

export const FunnelIcon = ({
  fill = '#EEF2F2',
  height = '16',
  viewBox = '0 0 16 16',
  width = '16',
  xmlns = 'http://www.w3.org/2000/svg',
  ...rest
}: SvgProps) => {
  return (
    <Svg {...rest} {...{ fill, height, viewBox, width, xmlns }}>
      <path d="M0.121712 1.71563C0.327962 1.27813 0.765462 1 1.24984 1H14.7498C15.2342 1 15.6717 1.27813 15.878 1.71563C16.0842 2.15313 16.0217 2.66875 15.7155 3.04375L9.99984 10.0281V14C9.99984 14.3781 9.78734 14.725 9.44671 14.8938C9.10609 15.0625 8.70296 15.0281 8.39984 14.8L6.39984 13.3C6.14671 13.1125 5.99984 12.8156 5.99984 12.5V10.0281L0.281087 3.04063C-0.0220383 2.66875 -0.0876633 2.15 0.121712 1.71563Z" />
    </Svg>
  );
};
