'use client';

import { ComponentProps, ReactNode } from 'react';
import { Typography } from '../Typography';
import { Button } from '../Button';
import { Container } from '../Layout';
import { VariantProps, tv } from 'tailwind-variants';
import { ArrowBackIcon, CloseIcon } from '../Icons';

const modalStyles = tv({
  defaultVariants: { color: 'black' },
  extend: Container,
  slots: {
    body: 'max-h-[calc(var(--vh, 1vh) * 90)] pt-4',
    closeButton: '',
    container:
      'p-[30px] z-100 min-w-[var(--space-87AndHalf)] shadow-modal max-lg:max-h-[840px] max-lg:w-[calc(100% - var(--space-8))] overflow-auto rounded-[10px] max-h-[calc(var(--vh, 1vh) * 100)] max-w-[calc(var(--space-150) - var(--space-22))]',
    header: 'flex justify-between bg-transparent',
  },
  variants: {
    color: {
      black: {
        closeButton: '[&>svg]:fill-light',
        container: 'bg-black',
        header: '[&>p]:text-light',
      },
      neutral: {
        closeButton: '[&>svg]:fill-dark',
        container: 'bg-neutral-100 border-2 border-purple-3 border-solid',
        header: '[&>p]:text-dark',
      },
    },
  },
});

export interface ModalProps
  extends Omit<ComponentProps<typeof Container>, 'color'>,
    VariantProps<typeof modalStyles> {
  title: string;
  titleClassName?: string;
  body: ReactNode;
  showCloseIcon?: boolean;
  onDismiss?: () => void;
  showBackIcon?: boolean;
  onBack?: () => void;
}

const Modal = ({
  body,
  className,
  color,
  onBack,
  onDismiss,
  showBackIcon = false,
  showCloseIcon = true,
  title,
  titleClassName,
  ...props
}: ModalProps) => {
  const {
    body: bodyStyles,
    closeButton,
    container,
    header,
  } = modalStyles({ color });

  return (
    <Container
      className={container({ class: className })}
      stack="column"
      {...props}
    >
      <Container itemsCenter className={header()}>
        {showBackIcon && (
          <Button
            aria-label="Go back"
            data-testid="modal-back-btn"
            onClick={onBack}
          >
            <ArrowBackIcon />
          </Button>
        )}
        <Typography className={titleClassName} weight="semibold" size="base">
          {title}
        </Typography>
        {showCloseIcon && (
          <Button
            aria-label="Close the dialog"
            data-testid="modal-close-btn"
            onClick={onDismiss}
            className={closeButton()}
          >
            <CloseIcon />
          </Button>
        )}
      </Container>
      <Container stack="column" fullWidth className={bodyStyles()}>
        {body}
      </Container>
    </Container>
  );
};
export { Modal };
export * from './Context';
export { default as ModalProvider } from './Context';
