'use client';
import React, { forwardRef, memo, useCallback, useMemo } from 'react';
import {
  Box,
  Button,
  ButtonProps,
  InputProps,
  Slider,
  Span,
  TabItem,
  TabsContainer,
  Typography,
  Input as UikitInput,
} from '@vaporfi/uikit';
import { DEFAULT_TAB, Tab, tabKey } from './types';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import Image from 'next/image';
import { cn } from '@vaporfi/utils';

export const TradesThreadsTabs = () => {
  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();

  const activeTab = useMemo(() => {
    if (searchParams && searchParams?.has(tabKey)) {
      return searchParams?.get(tabKey);
    }
    return DEFAULT_TAB;
  }, [searchParams]);

  const onToggle = useCallback(
    (tab: Tab) => {
      const params = new URLSearchParams(searchParams.toString());
      params.set(tabKey, tab);

      return router.push(pathname + '?' + params.toString());
    },
    [pathname, router, searchParams],
  );

  return (
    <TabsContainer hideBorder>
      <TabItem hideBorder>
        <TabButton
          isActive={activeTab === 'trades'}
          label="Trades"
          onClick={() => onToggle('trades')}
        />
      </TabItem>
      <TabItem>
        <TabButton
          isActive={activeTab === 'threads'}
          label="Threads"
          onClick={() => onToggle('threads')}
        />
      </TabItem>
    </TabsContainer>
  );
};

export const TabButton = ({
  className,
  isActive,
  label,
  ...rest
}: ButtonProps & { isActive?: boolean; label: string }) => {
  return (
    <Button
      color={isActive ? 'purple' : 'light'}
      size="base"
      border={2}
      variant="squared"
      className={cn('mb-4 items-center rounded-lg px-8 py-6', className)}
      {...rest}
    >
      <Typography size="lg" className={isActive ? '' : 'text-black'}>
        {label}
      </Typography>
    </Button>
  );
};
export const CurrencyInput = ({
  logoURL,
  onUserInput,
  symbol,
  value,
}: Pick<InputProps, 'value' | 'onUserInput'> & {
  logoURL: string;
  symbol?: string;
}) => {
  return (
    <Box className="border-purple-4 hover:border-purple-3 relative flex w-full items-center rounded-md border-2 border-solid">
      <Input
        type="text"
        value={value}
        onUserInput={onUserInput}
        placeholder="0.0"
        pattern="^[0-9]*[.,]?[0-9]*$"
        inputMode="decimal"
        className="w-full border-none pr-2"
      />

      <Box className="mr-2 flex items-center gap-2">
        <Image src={logoURL} alt="logo" width={20} height={20} />
        <Span className="text-dark font-semibold">{symbol}</Span>
      </Box>
    </Box>
  );
};
export const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ className, ...rest }, ref) => {
    return (
      <UikitInput
        className={cn(
          'border-purple-1 text-dark max-h-10 rounded-lg border-0 bg-white p-2 text-left font-normal placeholder:text-[#818181] hover:border-2 focus:border-2',
          className,
        )}
        placeholder="Moar Candy"
        inputMode="text"
        type="text"
        pattern="(.*?)"
        {...rest}
        ref={ref}
      />
    );
  },
);

export default memo(function TokenCardSlider({
  progress,
}: {
  progress: number;
}) {
  return (
    <Box
      style={{
        backgroundImage: "url('/counter-top.png')",
      }}
      className="h-auto w-full bg-cover bg-no-repeat p-5"
    >
      <Slider value={[progress]} disabled variant="candy" />
    </Box>
  );
});
