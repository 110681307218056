import { UNSTOPPABLE_DOMAINS_ENV } from '../config/constants/apiKeys';
import useSWR from 'swr';
import { Address } from 'wagmi';

type ReverseResolutionResponse = {
  meta: {
    domain: string;
  };
};

const fetchDomain = (url: string) =>
  fetch(url, {
    headers: {
      Authorization: `Bearer ${UNSTOPPABLE_DOMAINS_ENV}`,
    },

    method: 'GET',
  }).then((res) => res.json());

export const useUnstoppableDomain = (
  account: Address,
  isConnected: boolean,
) => {
  const { data } = useSWR<ReverseResolutionResponse>(
    isConnected
      ? `https://resolve.unstoppabledomains.com/reverse/${account}`
      : null,
    fetchDomain,
  );
  return data ? data?.meta?.domain ?? '' : null;
};
