import { InjectedConnector } from '@wagmi/core';
import { Chain, ConnectorNotFoundError } from 'wagmi';
import { getAddress } from 'viem';
import { InjectedConnectorOptions } from '../types';

export type StargazerConnectorOptions = Pick<
  InjectedConnectorOptions,
  'shimDisconnect'
>;

export class StargazerConnector extends InjectedConnector {
  readonly id = 'stargazer';
  readonly ready =
    typeof window != 'undefined' &&
    window.stargazer &&
    !!this.#findProvider(window.stargazer);

  #provider?: Window['stargazer'];

  constructor(config?: {
    chains?: Chain[];
    options?: StargazerConnectorOptions;
  }) {
    super({
      ...config,
      options: {
        name: 'Stargazer',
        shimDisconnect: true,
        ...config?.options,
      },
    });
  }

  async getProvider() {
    if (
      typeof window !== 'undefined' &&
      window.stargazer &&
      'getProvider' in window.stargazer &&
      typeof window.stargazer.getProvider === 'function'
    ) {
      // TODO: Fallback to ethereum#initialized event for async injection
      // https://github.com/MetaMask/detect-provider#synchronous-and-asynchronous-injection=
      this.#provider = this.#findProvider(window.stargazer);
      await this.#provider?.activate('VaporDEX');
      console.log(window.stargazer.providers);
    }
    return this.#provider;
  }

  async getAccount() {
    const provider = await this.getProvider();
    if (!provider) throw new ConnectorNotFoundError();
    const accounts = await provider.req({
      method: 'eth_accounts',
    });
    // return checksum address
    return getAddress(<string>accounts[0]);
  }

  #getReady(stargazer?: any) {
    if (!stargazer) return;

    return stargazer;
  }

  #findProvider(stargazer?: any) {
    if (typeof stargazer?.getProvider === 'function')
      return stargazer.getProvider('ethereum');
    return this.#getReady(stargazer);
  }
}
