import { Button } from '@vaporfi/uikit';
import SettingsModal from './SettingsModal';
import { CogIcon } from '@vapordex/uikit';
import { useModal } from '@vaporfi/hooks';
import { noop } from 'lodash';

type Props = {
  color?: string;
  mr?: string;
  width?: string;
  height?: string;
};

const GlobalSettings = ({
  color,
  height = '16px',
  mr = 'var(--space-2)',
  width = '16px',
}: Props) => {
  const [onPresentSettingsModal] = useModal(<SettingsModal onDismiss={noop} />);

  return (
    <Button
      color="transparent"
      data-testid="open-settings-btn"
      onClick={onPresentSettingsModal}
      size="sm"
      css={{
        marginLeft: 'var(--space-twoAndHalf)',
        marginRight: mr,
        padding: 0,
      }}
      name="open settings dialog button"
    >
      <CogIcon {...{ color: color || 'textSubtle', height, width }} />
    </Button>
  );
};

export default GlobalSettings;
