'use client';

import { Button, TabItem, TabsContainer, Typography } from '@vaporfi/uikit';

type Tab = 'buy' | 'sell';

const TabButton = ({
  isActive,
  label,
  ...rest
}: {
  isActive: boolean;
  label: string;
  onClick: () => void;
}) => {
  return (
    <Button
      color={isActive ? 'purple' : 'light'}
      size="base"
      border={2}
      fullWidth
      className={`items-center rounded-lg px-12 py-6 ${
        isActive ? 'border-purple-1' : 'border-purple-4 border-2 border-solid'
      }`}
      {...rest}
    >
      <Typography className={isActive ? '' : 'text-neutral-750'}>
        {label}
      </Typography>
    </Button>
  );
};

export const BuySellTabs = ({
  activeTab,
  onToggle,
}: {
  activeTab: Tab;
  onToggle: (tab: Tab) => void;
}) => {
  return (
    <TabsContainer hideBorder fullWidth className="justify-center">
      <TabItem hideBorder fullWidth>
        <TabButton
          isActive={activeTab === 'buy'}
          label="Buy"
          onClick={() => onToggle('buy')}
        />
      </TabItem>
      <TabItem fullWidth>
        <TabButton
          isActive={activeTab === 'sell'}
          label="Sell"
          onClick={() => onToggle('sell')}
        />
      </TabItem>
    </TabsContainer>
  );
};
