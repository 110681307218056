'use client';
import { Box, Grid, Typography } from '@vaporfi/uikit';
import React, { memo } from 'react';
import { KPIDetails, PointsTypography } from './shared';
import { useCommify } from '@vaporfi/hooks';

const KPIs = ({
  availablePoints,
  expiringPoints,
  totalPoints,
  weeklyPoints,
}: {
  availablePoints: number;
  expiringPoints: number;
  weeklyPoints: number;
  totalPoints: number;
}) => {
  const commify = useCommify();
  return (
    <Grid className="flex flex-col gap-4 lg:grid lg:grid-cols-2 lg:gap-7">
      {[
        {
          description: 'Points available to use.',
          label: 'Available Points',
          value: availablePoints,
        },
        {
          description: 'Points about to expire.',
          label: 'Expiring this Week',
          value: expiringPoints,
        },
        {
          description: 'Points gained this week.',
          label: 'Points claimed this week',
          value: weeklyPoints,
        },
        {
          description: 'Total points earned till date.',
          label: 'Lifetime Points',
          value: totalPoints,
        },
      ].map(({ description, label, value }, index) => (
        <Box
          className="flex flex-col gap-2 rounded-[10px] bg-black p-4 lg:p-[30px]"
          key={`strat-dashboard-points-${label}-${index}`}
        >
          <Box className="flex items-center gap-2">
            <Typography weight="semibold">{label}</Typography>
            <KPIDetails content={description} />
          </Box>
          <PointsTypography isExpiring={index === 1}>
            {commify(value?.toFixed(3))}
          </PointsTypography>
        </Box>
      ))}
    </Grid>
  );
};

export default memo(KPIs);
