import { BigintIsh, Fraction, Rounding } from '@vapordex/sdk';
import { BIG_INT_ZERO } from '../constants';

/**
 * A class for representing quantities of points.
 * Points are fractional values with a fixed decimal scale of 18.
 * This class extends the `Fraction` class from `sdk-core`.
 */
export class Points extends Fraction {
  /** The decimal scale used by points (18) */
  public readonly decimalScale: number;

  /**
   * Create a new Points instance.
   * @param numerator The numerator of the fractional points amount.
   * @param denominator The denominator of the fractional points amount (optional, defaults to 1).
   */
  constructor(numerator: BigintIsh, denominator?: BigintIsh) {
    super(numerator, denominator);
    this.decimalScale = 18;
  }

  /**
   * Create a new Points instance from a raw points amount.
   * @param rawAmount The raw points amount.
   * @returns A new Points instance.
   */
  public static fromRawAmount(rawAmount: BigintIsh): Points {
    if (!rawAmount) return new Points(BIG_INT_ZERO);
    return new Points(rawAmount);
  }

  /**
   * Create a new Points instance from a fractional points amount.
   * @param numerator The numerator of the fractional points amount.
   * @param denominator The denominator of the fractional points amount.
   * @returns A new Points instance.
   */
  public static fromFractionalAmount(
    numerator: BigintIsh,
    denominator: BigintIsh,
  ): Points {
    return new Points(numerator, denominator);
  }

  /**
   * Add another Points instance to this instance.
   * @param other The Points instance to add.
   * @returns A new Points instance representing the sum.
   */
  public add(other: Points): Points {
    const added = super.add(other);
    return Points.fromFractionalAmount(added.numerator, added.denominator);
  }

  /**
   * Subtract another Points instance from this instance.
   * @param other The Points instance to subtract.
   * @returns A new Points instance representing the difference.
   */
  public subtract(other: Points): Points {
    const subtracted = super.subtract(other);
    return Points.fromFractionalAmount(
      subtracted.numerator,
      subtracted.denominator,
    );
  }

  /**
   * Multiply this instance by another fractional value or BigintIsh.
   * @param other The fractional value or BigintIsh to multiply by.
   * @returns A new Points instance representing the product.
   */
  public multiply(other: Fraction | BigintIsh): Points {
    const multiplied = super.multiply(other);
    return Points.fromFractionalAmount(
      multiplied.numerator,
      multiplied.denominator,
    );
  }

  /**
   * Divide this instance by another fractional value or BigintIsh.
   * @param other The fractional value or BigintIsh to divide by.
   * @returns A new Points instance representing the quotient.
   */
  public divide(other: Fraction | BigintIsh): Points {
    const divided = super.divide(other);
    return Points.fromFractionalAmount(divided.numerator, divided.denominator);
  }

  /**
   * Convert this instance to a significant string representation.
   * @param significantDigits The number of significant digits to include.
   * @param format An optional format object for the string representation.
   * @param rounding The rounding behavior to use.
   * @returns A string representation of this instance with the given number of significant digits.
   */
  public toSignificant(
    significantDigits: number = 6,
    format?: object,
    rounding: Rounding = Rounding.ROUND_DOWN,
  ): string {
    return super
      .divide(this.decimalScale)
      .toSignificant(significantDigits, format, rounding);
  }

  /**
   * Returns a fixed-point representation of the Points instance with the given number of decimal places
   * @param decimalPlaces the number of decimal places to include in the result (default: decimal scale of the Points instance)
   * @returns a string representation of the fixed-point number
   * @throws an error if the requested number of decimal places is greater than the decimal scale of the Points instance
   */
  public toFixed(decimalPlaces: number = this.decimalScale): string {
    return Number(this.toExact()).toFixed(decimalPlaces);
  }

  /**
   * Returns a string representation of the exact decimal value of the Points instance
   * @param format options for formatting the result (e.g. using a separator for thousands) (default: { groupSeparator: '' })
   * @returns a string representation of the exact decimal value of the Points instance
   */
  public toExact(): string {
    return (Number(this.quotient.toString()) / 1e18).toString();
  }
}
