import { SvgProps } from '@vaporfi/uikit';
import { FC } from 'react';

export enum ConnectorNames {
  Injected = 'injected',
  WalletConnect = 'walletconnect',
  WalletLink = 'walletlink',
  MetaMask = 'metamask',
  Core = 'core',
  Coinbase = 'coinbase',
  Stargazer = 'stargazer',
  GnosisSafe = 'safe',
  Mock = 'mock',
  Ledger = 'ledger',
  OKX = 'okxwallet',
}

export type Login = (connectorId: ConnectorNames) => void;

export interface Config {
  title: string;
  icon: FC<SvgProps>;
  connectorId: ConnectorNames;
  priority: number | (() => number);
  href?: string;
}
