import { cn } from '@vaporfi/utils';
import { Svg, SvgProps } from './Svg';
import { forwardRef } from 'react';

export const LoadingSpinner = forwardRef<SVGSVGElement, SvgProps>(
  ({ className, ...rest }, ref) => {
    return (
      <Svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className={cn('animate-spin', className)}
        {...rest}
        ref={ref}
      >
        <path d="M21 12a9 9 0 1 1-6.219-8.56" />
      </Svg>
    );
  },
);
