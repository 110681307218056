import {
  Grid,
  GridProps,
  Typography,
  Button,
  Input,
  PencilIcon,
  Avatar,
} from '@vaporfi/uikit';

import { useMemo } from 'react';
import { areAllTruthy } from '@vaporfi/utils';
import { VariantProps, tv } from 'tailwind-variants';

const profileStyles = tv({
  slots: {
    avatarInputContainer: 'flex gap-7',
    fileContainer: 'p-1 gap-2 flex flex-col content-center',
    fileSelector: 'hidden',
    iconButton:
      'bg-light item-center absolute flex h-8 w-8 translate-x-12 justify-center rounded-full p-1',
    label: 'flex relative',
    pencilIcon: 'z-1 h-5 w-5 cursor-pointer',
    uploadButton: 'self-end w-fit',
    wrapper: 'bg-black rounded-[10px] p-4',
  },
});
export interface ProfileProps
  extends VariantProps<typeof profileStyles>,
    GridProps {
  isAvatarManagementEnabled?: boolean;
  isUploaded?: boolean;
  onFileSelect?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onUpload?: () => void;
  selectedFile?: any;
  tokenId?: number | string;
  validAvatar?: string;
}
export const Profile = ({
  isAvatarManagementEnabled,
  isUploaded,
  onFileSelect,
  onUpload,
  selectedFile,
  tokenId,
  validAvatar,
}: ProfileProps) => {
  const {
    avatarInputContainer,
    fileContainer,
    fileSelector,
    iconButton,
    label,
    pencilIcon,
    uploadButton,
    wrapper,
  } = profileStyles();
  const isValidUpload = useMemo(
    () => areAllTruthy(isAvatarManagementEnabled, !isUploaded, selectedFile),
    [isAvatarManagementEnabled, isUploaded, selectedFile],
  );

  return (
    <Grid className={wrapper()}>
      <Typography size="base" weight="semibold">
        Your Profile
      </Typography>

      <div className={avatarInputContainer()}>
        <div className={fileContainer()}>
          <label htmlFor="avatar-upload" className={label()}>
            <Avatar
              key={`${validAvatar}-${selectedFile?.name}`}
              src={validAvatar || ''}
              alt={`Discount swap fees`}
            />
            {isAvatarManagementEnabled && (
              <div className={iconButton()}>
                <PencilIcon fill="black" className={pencilIcon()} />
              </div>
            )}
          </label>
          {isAvatarManagementEnabled && (
            <Input
              id="avatar-upload"
              type="file"
              accept="image/*"
              onChange={isAvatarManagementEnabled ? onFileSelect : () => null}
              className={fileSelector()}
            />
          )}
          {isValidUpload && (
            <Button
              variant="outline"
              color="light"
              size="sm"
              onClick={isAvatarManagementEnabled ? onUpload : () => null}
              className={uploadButton()}
            >
              <Typography>Confirm</Typography>
            </Button>
          )}
        </div>

        <div>
          <Typography
            size="xs"
            color="neutral"
            weight="semibold"
            className="mb-2"
          >{`Public Alias (Token ID)`}</Typography>
          <Input
            disabled
            value={tokenId}
            style={{
              background: '#171717',
              textAlign: 'start',
              width: '100%',
            }}
          />
        </div>
      </div>
    </Grid>
  );
};
