import { formatNumber } from './formatBalance';

export const formatNumberForDisplay = (number: bigint): string => {
  const divisor = BigInt(1e18);
  const scaledNumber = Number(number) / Number(divisor);
  const formattedNumber = scaledNumber.toPrecision(2);
  return formatNumber(Number.parseFloat(formattedNumber), 0, 2);
};

// Function to convert rank to a sortable value
export const getSortableRankValue = (
  rank: string | number,
  placeholder: string | number,
): number => {
  if (rank === placeholder || !rank) {
    return Number.POSITIVE_INFINITY;
  }
  return Number(rank);
};
