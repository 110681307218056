export const TelosTestnet = [
  {
    address: '0xAdC76917E1c4a610Cfe409Dcde572EA5436d5bBE',
    chainId: 41,
    decimals: 18,
    logoURI: '',
    name: 'VT_TEST',
    symbol: 'VTEST',
  },
  {
    address: '0xCadd77c41217d8c3f89D05e04aca4a9EaAe1b40F',
    chainId: 41,
    decimals: 18,
    logoURI: '',
    name: 'V2T_TEST',
    symbol: 'V2TEST',
  },
  {
    address: '0xaE85Bf723A9e74d6c663dd226996AC1b8d075AA9',
    chainId: 41,
    decimals: 18,
    logoURI:
      'https://raw.githubusercontent.com/telosnetwork/images/master/logos_2021/Symbol%202.svg',
    name: 'Wrapped Telos',
    symbol: 'WTLOS',
  },
  {
    address: '0xB38239b39f141CB9CdBf25e86f5eD37a6915d2DD',
    chainId: 41,
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/telosnetwork/images/master/logos_2021/Symbol%202.svg',
    name: 'TEST USD',
    symbol: 'TUSD',
  },
  {
    address: '0x00d2ebBd02EbC51dE3fb9aaed5F9eFD7502b0284',
    chainId: 41,
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/telosnetwork/images/master/logos_2021/Symbol%202.svg',
    name: 'TEST GBP',
    symbol: 'TGBP',
  },
  {
    address: '0xCb50b82De7572597eed70F9c095979C2bDDF72D2',
    chainId: 41,
    decimals: 18,
    logoURI:
      'https://raw.githubusercontent.com/telosnetwork/images/master/logos_2021/Symbol%202.svg',
    name: 'Mooncoin',
    symbol: 'MOON',
  },
  {
    address: '0x0d55bC35eD96BFBf8C25530C97C9805B0914deB2',
    chainId: 41,
    decimals: 18,
    logoURI:
      'https://raw.githubusercontent.com/telosnetwork/images/master/logos_2021/Symbol%202.svg',
    name: 'LamboCoin',
    symbol: 'LAMBO',
  },
  {
    address: '0x7A2155237A67D640Db19ed63d2d10828bC88db8F',
    chainId: 41,
    decimals: 18,
    logoURI:
      'https://raw.githubusercontent.com/telosnetwork/images/master/logos_2021/Symbol%202.svg',
    name: 'PUMPCoin',
    symbol: 'PUMP',
  },
  {
    address: '0xB23be76e0b54eEd09E426B541F97Ae87849a6FB5',
    chainId: 41,
    decimals: 18,
    logoURI:
      'https://raw.githubusercontent.com/telosnetwork/images/master/logos_2021/Symbol%202.svg',
    name: 'DUMPCoin',
    symbol: 'DUMP',
  },
  {
    address: '0xa9991E4daA44922D00a78B6D986cDf628d46C4DD',
    chainId: 41,
    decimals: 18,
    logoURI:
      'https://raw.githubusercontent.com/swapsicledex/swapsicle-token-list/master/logos/telos/0xB4B01216a5Bc8F1C8A33CD990A1239030E60C905/logo.png',
    name: 'Staked TLOS',
    symbol: 'STLOS',
  },
] as const;
