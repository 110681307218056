import { Svg, SvgProps } from './Svg';

export const BankIcon = ({
  height = '70pt',
  version = '1.1',
  viewBox = '0 0 700 700',
  width = '70pt',
  xmlns = 'http://www.w3.org/2000/svg',
  ...rest
}: SvgProps) => {
  return (
    <Svg {...rest} {...{ height, version, viewBox, width, xmlns }}>
      <path
        d="m564.42 146.26-207.66-110.72c-3.9023-2.0781-8.2188-2.0781-12.117 0l-207.68 110.72zm-518.4 519.77h607.96c7.0938 0 12.879-5.7891 12.879-12.879v-40.797c0-7.0938-5.7891-12.879-12.879-12.879l-607.96 0.003906c-7.0938 0-12.879 5.7812-12.879 12.879v40.797c0 7.0859 5.7812 12.875 12.879 12.875zm51.141-120.23v27.914h505.68v-27.914c0-7.0938-5.7891-12.879-12.879-12.879h-9.5234l-0.042969-0.33594c-6.5781-50.414-9.2812-101.79-9.2812-152.61 0-38.645 1.5742-77.531 5.207-116.08h-88.984c3.625 38.559 5.207 77.438 5.207 116.08 0 50.824-2.707 102.19-9.2812 152.61l-0.042968 0.33594h-83.91l-0.042969-0.33594c-6.5781-50.414-9.2812-101.79-9.2812-152.61 0-38.645 1.5742-77.531 5.207-116.08h-88.988c3.625 38.559 5.207 77.438 5.207 116.08 0 50.824-2.707 102.19-9.2812 152.61l-0.042969 0.33594h-83.906l-0.042969-0.33594c-6.5781-50.414-9.2812-101.79-9.2812-152.61 0-38.645 1.5742-77.531 5.207-116.08h-88.992c3.625 38.559 5.207 77.438 5.207 116.08 0 50.824-2.707 102.19-9.2812 152.61l-0.042969 0.33594h-10.902c-7.1016-0.007813-12.887 5.7812-12.887 12.879zm552.96-373.78h-600.23c-9.2227 0-16.742 7.5234-16.742 16.742v32.617c0 9.2227 7.5234 16.742 16.742 16.742h600.23c9.2227 0 16.742-7.5234 16.742-16.742v-32.617c0.007813-9.2188-7.5156-16.742-16.734-16.742z"
        fillRule="evenodd"
        fill="var(--colors-blue500)"
      />
    </Svg>
  );
};
