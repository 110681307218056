import { Svg, SvgProps } from './Svg';

export const CurrencySwitcherIcon = ({
  fill = 'none',
  height = '12',
  viewBox = '0 0 12 12',
  width = '12',
  xmlns = 'http://www.w3.org/2000/svg',
}: SvgProps) => {
  return (
    <Svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill={fill}
      xmlns={xmlns}
    >
      <path
        d="M7.54622 1.33346L7.54622 9.05846L6.35081 7.86304C6.09039 7.60263 5.66852 7.60263 5.4081 7.86304C5.27747 7.99179 5.21289 8.16263 5.21289 8.33346C5.21289 8.50429 5.27799 8.67471 5.4082 8.80471L7.74154 11.138C8.00195 11.3985 8.42383 11.3985 8.68424 11.138L11.0176 8.80471C11.278 8.54429 11.278 8.12242 11.0176 7.862C10.7572 7.60158 10.3353 7.60158 10.0749 7.862L8.87956 9.05846L8.87956 1.33346C8.87956 0.964919 8.58143 0.666794 8.21289 0.666794C7.84435 0.666794 7.54622 0.964919 7.54622 1.33346ZM4.21289 10.6668L4.21289 2.94388L5.40831 4.13929C5.66872 4.39971 6.0906 4.39971 6.35102 4.13929C6.61143 3.87888 6.61143 3.457 6.35102 3.19659L4.01768 0.863252C3.75727 0.602835 3.33539 0.602835 3.07498 0.863252L0.741641 3.19658C0.610808 3.32513 0.546224 3.49596 0.546224 3.66679C0.546224 3.83763 0.611329 4.00804 0.741537 4.13804C1.00195 4.39846 1.42383 4.39846 1.68424 4.13804L2.87956 2.94388L2.87956 10.6668C2.87956 11.0353 3.17768 11.3335 3.54622 11.3335C3.91477 11.3335 4.21289 11.0355 4.21289 10.6668Z"
        fill="#D6DADA"
      />
    </Svg>
  );
};
