import { STRATOSPHERE_URL } from '@vaporfi/utils';
import { Button } from '@mui/material';
import { Typography } from '@vaporfi/uikit';
import Image from 'next/legacy/image';
import Link from 'next/link';
import React from 'react';

const StratosphereIndicator = () => {
  return (
    <Link href={STRATOSPHERE_URL} legacyBehavior>
      <Button
        data-testid="strat-indicator-btn"
        sx={{
          ':hover': { backgroundColor: '#D6DADA' },
          backgroundColor: '#D6DADA',
          borderRadius: '18px',
          display: 'flex',
          gap: 1,
          justifyContent: 'flex-start',
        }}
      >
        <Image
          data-testid="strat-indicator-logo"
          src="/images/stratosphere/Basic-rounded.png"
          height={24}
          width={24}
          alt="Stratosphere tier logo"
        />
        <Typography
          data-testid="strat-indicator-tier"
          color="black"
          weight="normal"
          size="sm"
        >
          Basic
        </Typography>
      </Button>
    </Link>
  );
};

export default StratosphereIndicator;
