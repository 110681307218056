import MenuItems from './DesktopMenuItems';
import { ROOT_URL } from '@config/constants/url';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Drawer from '@mui/material/Drawer';
import Image from 'next/legacy/image';
import Link from 'next/link';
import { Copyright } from '@vaporfi/uikit';
import {
  DiscordIcon,
  IconButton,
  RedditIcon,
  TwitterIcon,
} from '@vapordex/uikit';
import { REDDIT_URL } from '@config/constants/domains';
import { DISCORD_URL, SOCIAL_TWITTER_URL } from '@vaporfi/utils';

const drawerWidth = '234px';

const SidebarDesktop = () => {
  return (
    <Drawer
      sx={{
        '& .MuiDrawer-paper': {
          animationDelay: '0s',
          animationDuration: '500ms',
          animationName: 'fadeInLeft',
          // TODO: colors
          background: 'rgba(0, 0, 0, 0.5)',
          border: 'none',
          boxSizing: 'border-box',
          overflowX: 'hidden',
          width: drawerWidth,
          zIndex: '1',
        },
        flexShrink: 0,
        padding: 0,
        width: drawerWidth,
      }}
      variant="permanent"
      anchor="left"
      open
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          paddingX: 0.5,
        }}
      >
        <Container
          sx={{
            display: 'flex',
            paddingTop: 4,
          }}
        >
          <Link href={ROOT_URL} passHref legacyBehavior>
            <Box
              sx={{
                cursor: 'pointer',
                marginBottom: 5,
                marginLeft: -1,
                maxWidth: 200,
              }}
            >
              <Image
                src="/logo.svg"
                alt="logo"
                height={19}
                width={182}
                priority
              />
            </Box>
          </Link>
        </Container>
        <MenuItems />
      </Box>
      <Box
        sx={{
          alignItems: 'center',
          // TODO: colors
          background: '#171717',
          color: '#D6DADA',
          display: 'flex',
          flexDirection: 'column',
          fontSize: 13,
          justifyContent: 'center',
          padding: 2,
        }}
      >
        <Box display="flex" justifyContent="center" marginTop={-2} width="50%">
          <IconButton
            onClick={() => window.open(SOCIAL_TWITTER_URL)}
            style={{
              backgroundColor: 'transparent',
            }}
            name="twitter button"
          >
            <TwitterIcon width="14px" />
          </IconButton>
          <IconButton
            onClick={() => window.open(DISCORD_URL)}
            style={{ backgroundColor: 'transparent' }}
            name="discord button"
          >
            <DiscordIcon width="14px" />
          </IconButton>
          <IconButton
            onClick={() => window.open(REDDIT_URL)}
            style={{ backgroundColor: 'transparent' }}
            name="reddit button"
          >
            <RedditIcon width="14px" />
          </IconButton>
        </Box>
        <Box justifyItems="center">
          <Copyright />
        </Box>
      </Box>
    </Drawer>
  );
};

export default SidebarDesktop;
